import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select, TextField
} from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { request } from "../../../../../../api/request";
import l2 from "../../../../../../assets/icon/l2.svg";
import l3 from "../../../../../../assets/icon/l3.svg";
import pdfDoc from "../../../../../../assets/icon/pdf_doc.svg";
import scrip from "../../../../../../assets/icon/scripka.svg";
import { Contextvalue } from "../../../../../../context/context";
import "./singlebuy.scss";
import Singlemodal from "./singlemodal/singlemodal";


function Singlebuy() {
  const [down, setDown] = useState([]);
  const [raq, setRaq] = useState();
  const [fir, setFir] = useState();
  const { t } = useTranslation();
  const [ish, setIsh] = useState();
  const [xar, setXar] = useState();
  const [san, setSan] = useState();
  const [umu, setUmu] = useState(0);
  const [aja, setAja] = useState(0);
  const [firm, setFirm] = useState();
  const [asra, setAsra] = useState();
  const [qra, setQra] = useState();
  const [qoshim, setQoshim] = useState();
  const { nom, bir, miq, narx, name, tur, arr } = useContext(Contextvalue);
  const navigate = useNavigate();
  function addFile(e) {
    setDown([
      ...down,
      {
        filename: e.target.value,
        fil: e.target.files[0],
      },
    ]);
  }
  function delFile(index) {
    let sss = [];
    sss.push(...down);
    sss.splice(index, 1);
    setDown(sss);
  }
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const Send = (e) => {
    e.preventDefault();
   if (down.length > 0) {
	const formData = new FormData();
    formData.append("shartnoma_raqami", raq);
    formData.append("yetgazib_beruvchi_firma_nomi", fir);
    formData.append("ishlab_chiqaruchi_firma_nomi", ish);
    formData.append("xarid_qilish_usuli", xar);
    formData.append("shartnoma_qilingan_sana", san);
    formData.append("shartnomaning_umumiy_pul_miqdori", umu);
    formData.append("ajratilgan_pul_miqdori", aja);
    formData.append("ism_familya_firma_egasi", firm);
    formData.append("asosiy_raqam", asra);
    formData.append("qoshimcha_raqam", qra);
    formData.append("qoshimcha_malumot", qoshim);
    down[0] && formData.append("file", down[0].fil);
    request
      .post("/omborxona/buyurtma/yaratish", formData, config)
      .then(() => {
        toast.success("Buyurtma Yaratildi!");
        navigate(-1);
      })
      .catch(() => toast.error("Buyurtma Yaratilmadi!"));
    setTimeout(() => {
      const formDatas = new FormData();
      formDatas.append("shartnoma_raqami", raq);
      formDatas.append("vosita_nomi", nom.id);
      formDatas.append("vosita_turi", tur.id);
      formDatas.append("olchov_birligi", bir);
      formDatas.append("vosita_miqdori", miq);
      formDatas.append("olchov_birligi_narxi", narx);
      formDatas.append("omborxona_nomi", name);
      arr[0] && formDatas.append("file", arr[0].fil);
      request.post("/omborxona/buyurtma/vositalari", formDatas, config);
    }, 1000);
    setDown([]);
   }
   else {
		toast.warning("Iltimos Fayl Briktiring!")
   }
  };
  return (
		<div style={{ paddingTop: "28px", paddingLeft: "20px", paddingRight: "20px",maxWidth:"1800px",margin:"0 auto" }}>
			<Button
				onClick={() => navigate(-1)}
				style={{
					backgroundColor: "#DDEBFB",
					color: "#1464C0",
					borderRadius: "12px",
				}}
				variant='contained'
				startIcon={<ArrowBackIcon />}
			>
				{t("bildirishnoma.single.ortga")}
      </Button>
      
			<form  onSubmit={Send}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<section>
						<div className='card-block'>
							<h2 className='card-block__head'>{t("input.mal1")}</h2>
							<TextField
								style={{
									width: "100%",
									marginBottom: "20px",
								}}
								onChange={e => setRaq(e.target.value)}
								id='outlined-basic'
								label={t("input.shart")}
								variant='outlined'
								required
								type={"number"}
							/>
							<TextField
								style={{
									width: "100%",
									marginBottom: "20px",
								}}
								onChange={e => setFir(e.target.value)}
								id='outlined-basic'
								label={t("input.yt")}
								variant='outlined'
								required
							/>
							<TextField
								style={{
									width: "100%",
									marginBottom: "20px",
								}}
								onChange={e => setIsh(e.target.value)}
								id='outlined-basic'
								label={t("jihoz.ishlab")}
								variant='outlined'
								required
							/>
							<div style={{ display: "flex", alignItems: "center" }}>
								<FormControl fullWidth>
									<InputLabel id='demo-simple-select-label'>{t("input.xar")}</InputLabel>
									<Select
										label={t("input.xar")}
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										onChange={e => setXar(e.target.value)}
										required
									>
										<MenuItem value={"Tender"}>Tender</MenuItem>
										<MenuItem value={"Boshlangʼich narxni pasaytirish boʼyicha auktsion"}>
											Boshlangʼich narxni pasaytirish boʼyicha auktsion
										</MenuItem>
										<MenuItem value={"Elektron doʼkon orqali xarid"}>Elektron doʼkon orqali xarid</MenuItem>
										<MenuItem value={"Tanlov"}>Tanlov</MenuItem>
										<MenuItem value={"Yagona yetkazib beruvchi orqali xarid"}>Yagona yetkazib beruvchi orqali xarid</MenuItem>
									</Select>
								</FormControl>
								<TextField
									className='card-date'
									style={{
										width: "100%",
										marginBottom: "21px",
										marginLeft: "20px",
									}}
									data-content={t("input.shart1")}
									onChange={e => setSan(e.target.value)}
									id='outlined-basic'
									variant='outlined'
									type='date'
									required
								/>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<TextField
									style={{
										width: "100%",
									}}
									id='outlined-basic'
									label={t("input.pulmiq")}
									variant='outlined'
									onChange={e => setUmu(e.target.value)}
									type='number'
									required
								/>
								<TextField
									style={{
										width: "100%",
										marginLeft: "20px",
									}}
									id='outlined-basic'
									label={t("input.sum")}
									onChange={e => setAja(e.target.value)}
									variant='outlined'
									type='number'
									required
								/>
							</div>
							<div className={aja - umu > 0 ? "card-uzs card-uzs--green" : aja - umu < 0 ? "card-uzs card-uzs--red" : "card-uzs"}>
								{aja - umu} uzs
							</div>
						</div>
						<div className='card-block'>
							<h2 className='card-block__head'>{t("input.komp")}</h2>
							<TextField
								style={{
									width: "100%",
									marginBottom: "20px",
								}}
								id='outlined-basic'
								label={t("input.direktor1")}
								onChange={e => setFirm(e.target.value)}
								variant='outlined'
								required
							/>
							<div style={{ display: "flex", alignItems: "center" }}>
								<TextField
									style={{
										width: "100%",
									}}
									id='outlined-basic'
									label={t("input.asosiy")}
									variant='outlined'
									onChange={e => setAsra(e.target.value)}
									type={"number"}
									required
								/>
								<TextField
									style={{
										width: "100%",
										marginLeft: "20px",
									}}
									id='outlined-basic'
									label={t("input.qosh1")}
									variant='outlined'
									onChange={e => setQra(e.target.value)}
									type={"number"}
									required
								/>
							</div>
						</div>

						<div className='card-block'>
							<h2 className='card-block__head'>{t("Qo’shimcha ma’lumot")}</h2>
							<textarea onChange={e => setQoshim(e.target.value)} className='card-text' placeholder={t("input.z")}></textarea>
						</div>
					</section>
					<section style={{ width: "40%" }}>
						<div className='card-block'>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<h2 className='card-block__head'>{t("input.zakaz")}</h2>
								<Singlemodal raq={raq} setArr={setDown} arr={down}/>
							</div>
							<div className='site-list__div'>
								<ul style={{ listStyle: "none" }}>
									<li>#1</li>
									<li style={{ marginTop: "10px", marginBottom: "14px" }}>
										{/* <Eyemodal/> */}
										<button type={"button"} style={{ border: "none", backgroundColor: "transparent" }}>
											<img src={l3} alt='...' />
										</button>
										<button type={"button"} style={{ border: "none", backgroundColor: "transparent" }}>
											<img src={l2} alt='...' />
										</button>
									</li>
									<li className='site-list__item'>
										{t("vosita.vositaturi")}: {tur && tur.nomi}
									</li>
									<li className='site-list__item'>
										{t("bildirishnoma.single.nomi")}: {nom && nom.nomi}
									</li>
									<li className='site-list__item'>
										{t("sbola.olchov")}: {bir}
									</li>
									<li className='site-list__item'>
										{t("bildirishnoma.single.miqdori")}: {miq}
									</li>
									{/* <li className="site-list__item">
                    {t("bildirishnoma.yet")}: 
                    </li> */}
								</ul>
							</div>
							{/* <p className="card-page">
                            Bu yerga, buyurtma qilingan va shartnomaga kiritilgan vositalarni qo'shishingiz mumkin, uning uchun "Vosita qo'shish" tugmachasini bosing
                        </p> */}
						</div>
						<div className='card-block'>
							<div className='sarflov_block_comment_inner'>
								<h4 className='sarflov_block_title'>{t("bildirishnoma.new.fail")}</h4>
								<input onChange={down.length < 1 && addFile} type='file' id='files' className='input_download' disabled={down.length > 0} />
								<label htmlFor='files' className='all_download'>
									<img className='scrip_file' src={scrip} alt='' />
									{t("vosita.qosh")}
								</label>
							</div>
							<div className='sarflov_block_inner_div'>
								{down.map((item, index) =>
									down.length > 0 ? ( 
										<div key={index} className='sarflov_block_download_file'>
											<label className='input_tyle_download'>
												<img src={pdfDoc} alt='' className='label_img' />
												{item.filename}
												<div className='close_file'>
													<Button onClick={e => delFile(index)} startIcon={<CloseIcon />}></Button>
												</div>
											</label>
										</div>
									) : null
								)}
							</div>
						</div>
					</section>
				</div>
				<footer style={{ marginTop: "21px" }} className='site-footer'>
					<div style={{ textAlign: "center" }}>
						<Button
							style={{
								borderRadius: "12px",
								backgrounColor: "#1464C0",
								width: "448px",
							}}
							type='submit'
							variant='contained'
							color='primary'
							size='large'
							startIcon={<SendIcon />}
							disabled={!tur}
						>
							{t("input.otp")}
						</Button>
					</div>
				</footer>
			</form>
		</div>
	);
}
export default Singlebuy;
