import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import l1 from "assets/icon/l1.svg";
import dayjs from "dayjs";

const RecievedNotificationTable = memo(
  ({ data = [], handleStatusChange = () => {}, path = "" }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              <TableCell> {t("bildirishnoma.soni")}</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>{t("Kimdan")}</TableCell>
              <TableCell>{t("Topshirish muddati")}</TableCell>
              <TableCell>{t("Kimga")}</TableCell>
              <TableCell>{t("Sana")}</TableCell>
              <TableCell>{t("Status")}</TableCell>
              <TableCell>{t("bildirishnoma.harakat")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((el, index) => {
              return (
								<TableRow>
									<TableCell align='left'>
										{index + 1}
										<div className='ariza_bgc'></div>
									</TableCell>
									<TableCell align='left'>{el.id}</TableCell>
									<TableCell align='left'>{el.kimdan}</TableCell>
									<TableCell align='left'>{el.muddati}</TableCell>
									<TableCell align='left'>{el.kimga}</TableCell>
									<TableCell align='left'>{dayjs(el.sana).format("DD.MM.YYYY")}</TableCell>
									<TableCell>
										<button className={el.status === "Yuborildi" ? "status_btn" : "status_btn--not"}>
											{el.status !== "O'qildi" ? t("bildirishnoma.arstatus.yangi") : t("vosita.oqil")}
										</button>
									</TableCell>
									<TableCell align='center'>
										<Button
											onClick={() => {
												navigate(`/${path}/${el.id}/${el.Yuboruvchi}`);
												handleStatusChange(el.id, el.kimdan);
											}}
										>
											<img src={l1} alt='batafsil' />
										</Button>
									</TableCell>
								</TableRow>
							);
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

export default RecievedNotificationTable;
