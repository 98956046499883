import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { request } from "../../api/request";
import BasicTabsBildirishnoma from "./bildirishnoma/muiTab";
import "./doctor.scss";
import BasicTable from "./jonatilganArizaTable";
import BasicTabs from "./omborxona/omborxonaTab";

export default function Lpu() {
  const token = localStorage.getItem("token");
  const [vosita, setVosita] = useState([]);
  const { t } = useTranslation();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const formData = new FormData();
  formData.append("token", token);

  useEffect(() => {
    request
      .get(`omborxona/lpu/malumotlar`, config)
      .then(function (res) {
        setVosita(res?.data);
      })
      .catch(function (err) {});
  }, []);

  return (
    <>
      <div className="main-page">
        <div className="box">
          <h1>{t("bildirishnoma.jariz")}</h1>
          <div className="box-item">
            <BasicTable />
          </div>
        </div>

        <div className="box">
          <div className="dori_vitamin">
            <div className="dorilar">
              <h1>{t("bildirishnoma.single.vosi")}</h1>
              <div className="header_kirim_chiqim">
                <div className="span">{t("bildirishnoma.kirim")}</div>
                <div className="span">{t("bildirishnoma.chiqim")}</div>
              </div>
              <div className="button_kir">
                <div className="kirim">
                  {vosita?.hozirgi_oy_kirim
                    ?.map((el) => el.kirim)
                    .reduce((acc, cur) => acc + cur, 0)}
                </div>
                <div className="chiqim">
                  {vosita?.hozirgi_oy_chiqim
                    ?.map((el) => el.chiqim)
                    .reduce((acc, cur) => acc + cur, 0)}
                </div>
              </div>
            </div>
          </div>
          <div className="box-item">
            <BasicTabs />
          </div>
        </div>
        <div className="box">
          <h1>{t("bildirishnoma.bil")}</h1>
          <div className="box-item">
            <BasicTabsBildirishnoma />
          </div>
        </div>
      </div>
    </>
  );
}
