import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import { get } from "lodash";
import moment from "moment";
import qs from "qs";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { request } from "../../../../../api/request";
import l1 from "../../../../../assets/icon/l1.svg";
import "./buyurtma.scss";
import { TabContext } from "@mui/lab";
function Buyurtma() {
  const [data, setData] = useState([]);
  const [value, setValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const locationValues = qs.parse(location.search.slice(1));
  const uzb = localStorage.getItem("uzb");
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${location.pathname}?${qs.stringify({ value: newValue })}`);
  };
  useEffect(() => {
    if (locationValues.value) setValue(+locationValues.value);
  }, []);
  useEffect(() => {
    request.get("/omborxona/buyurtma/yaratish", config).then((data) => {
      +value === 1 &&
        setData(
          data.data.filter(
            (el) =>
              Math.round(
                (el.partiyadan_kelgan_vosita_miqdori / el.vosita_miqdori) * 100
              ) < 100
          )
        );
      +value === 2 &&
        setData(
          data.data.filter(
            (el) =>
              Math.round(
                (el.partiyadan_kelgan_vosita_miqdori / el.vosita_miqdori) * 100
              ) === 100
          )
        );
    });
  }, [value]);

  return (
    <div style={{ padding: "20px", paddingTop: "24px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2>
            {t("input.bs1")}: {data && data.filter((item) =>
                item.buyurtma.yetgazib_beruvchi_firma_nomi
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) || item.buyurtma.shartnoma_raqami.toLowerCase()
                  .includes(searchValue.toLowerCase())).length}
          </h2>
          <TextField
            className="search-ariza"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t("input.qidir")}
            style={{ marginLeft: "40px" }}
            id="standard-basic"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ position: "absolute", right: "18px" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="green_block">
          {uzb && (
            <Link to={"/uzsinglebuy"}>
              <Button startIcon={<AddIcon />} variant="contained">
                {t("input.bq1")}
              </Button>
            </Link>
          )}
          <CSVLink
            data={data.map((item) => ({
              ...item,
              buyurtma: {
                ...item.buyurtma,
                created_at: moment(get(item, "buyurtma.created_at")).format(
                  "YYYY-MM-DD"
                ),
                shartnoma_qilingan_sana: moment(
                  get(item, "buyurtma.shartnoma_qilingan_sana")
                ).format("YYYY-MM-DD"),
              },
              qancha_bajarilgan:
                Math.round(
                  (item.partiyadan_kelgan_vosita_miqdori /
                    item.vosita_miqdori) *
                    100
                ) + "%",
            }))}
            headers={[
              { label: "ID", key: "id" },
              {
                label: "Yetkazib beruvchi kompaniya	",
                key: "buyurtma.yetgazib_beruvchi_firma_nomi",
              },
              { label: "Shartnoma Raqami", key: "buyurtma.shartnoma_raqami" },
              { label: "Qo'shilgan sana	", key: "buyurtma.created_at" },
              {
                label: "Shartnomaning umumiy pul miqdori	",
                key: "buyurtma.shartnomaning_umumiy_pul_miqdori",
              },
              {
                label: "Shartnoma qilingan sanasi	",
                key: "buyurtma.shartnoma_qilingan_sana",
              },
              { label: "Qanchaga bajarilgan	", key: "qancha_bajarilgan" },
            ]}
            filename="Buyurtmalar"
            className="excel_download"
            separator=";"
          >
            <Button
              style={{ backgroundColor: "#18CF6C", marginLeft: "20px" }}
              variant="contained"
              // color="primary"
              size="large"
              startIcon={<DescriptionIcon />}
            >
              {t("bola.excel")}
            </Button>
          </CSVLink>
        </div>
      </div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            aria-label="basic tabs example"
            onChange={handleChange}
          >
            <Tab label={t("bildirishnoma.a")} value={1} />
            <Tab label={t("bildirishnoma.ba")} value={2} />
          </Tabs>
        </Box>
      </TabContext>
      <TableContainer style={{ borderRadius: "12px" }} component={Paper}>
        <Table
          className="table-container"
          style={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{t("bildirishnoma.single.soni")}</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>{t("input.yt")}</TableCell>
              <TableCell>{t("input.shart")}</TableCell>
              <TableCell>{t("input.qs")}</TableCell>
              <TableCell>{t("input.pulmiq")}</TableCell>
              <TableCell>{t("input.data")}</TableCell>
              <TableCell>{t("input.qb")}</TableCell>
              <TableCell>{t("bildirishnoma.harakat")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((item) =>
                item.buyurtma.yetgazib_beruvchi_firma_nomi
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) || item.buyurtma.shartnoma_raqami.toLowerCase()
                  .includes(searchValue.toLowerCase())
              )
              .map((el, index) => {
                return (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{el.id}</TableCell>
                    <TableCell>
                      {el.buyurtma && el.buyurtma.yetgazib_beruvchi_firma_nomi}
                    </TableCell>
                    <TableCell>
                      {el.buyurtma && el.buyurtma.shartnoma_raqami}
                    </TableCell>
                    <TableCell>
                      {el.buyurtma &&
                        new Date(el.buyurtma.created_at).getFullYear()}
                      -
                      {el.buyurtma &&
                        new Date(el.buyurtma.created_at).getMonth() + 1}
                      -
                      {el.buyurtma &&
                        new Date(el.buyurtma.created_at).getDate()}
                    </TableCell>
                    <TableCell>
                      {el.buyurtma &&
                        el.buyurtma.shartnomaning_umumiy_pul_miqdori}
                    </TableCell>
                    <TableCell>
                      {el.buyurtma &&
                        el.buyurtma.shartnoma_qilingan_sana.split("T")[0]}
                    </TableCell>
                    <TableCell>
                      {Math.round(
                        (el.partiyadan_kelgan_vosita_miqdori /
                          el.vosita_miqdori) *
                          100
                      ) === 0 && <div className="table-load"></div>}
                      {Math.round(
                        (el.partiyadan_kelgan_vosita_miqdori /
                          el.vosita_miqdori) *
                          100
                      ) > 60 && (
                        <div
                          style={{
                            width: `${
                              Math.round(
                                (el.partiyadan_kelgan_vosita_miqdori /
                                  el.vosita_miqdori) *
                                  100
                              ) + 50
                            }px`,
                          }}
                          className="table-width"
                        ></div>
                      )}
                      {Math.round(
                        (el.partiyadan_kelgan_vosita_miqdori /
                          el.vosita_miqdori) *
                          100
                      ) < 60 && (
                        <div
                          style={{
                            width: `${Math.round(
                              (el.partiyadan_kelgan_vosita_miqdori /
                                el.vosita_miqdori) *
                                100
                            )}px`,
                          }}
                          className="table-width--2"
                        ></div>
                      )}
                      {Math.round(
                        (el.partiyadan_kelgan_vosita_miqdori /
                          el.vosita_miqdori) *
                          100
                      )}
                      %
                    </TableCell>

                    <TableCell>
                      <Link to={`/uzbuyin/${el.buyurtma && el.buyurtma.id}`}>
                        <img src={l1} alt="..." />
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default Buyurtma;
