import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { request } from "../../../api/request";
import { useEffect } from "react";
import { useState } from "react";
import time from "../../../assets/icon/time.svg";
import l1 from "../../../assets/icon/l1.svg";
import { Link, useNavigate } from "react-router-dom";
import ptichka from "../../../assets/icon/ptichka.svg";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import dayjs from "dayjs";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const rows = [
  createData("Frozen yoghurt", ""),
  createData("Ice cream sandwich", ""),
  createData("Ice cream sandwich", ""),
  createData("Ice cream sandwich", ""),
  createData("Ice cream sandwich", ""),
];

export default function Bildirishnomalar() {
  const [bildirishnoma, setBildirishnoma] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    request
      .get(`/bildirishnoma/tumanga/`, config)
      .then(function (res) {
        console.log(res, "bildirishnoma");
        setBildirishnoma(res?.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);
  const { t } = useTranslation();
  const StatusChangerBolalar = (e) => {
    const formData = new FormData();
    formData.append("id", e);
    formData.append("status", "O'qildi");
    request.put("/bildirishnoma/tumanga/", formData, config);
  };
  return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: "33%" }} aria-label='simple table'>
				<TableHead style={{ "background-color": "#ffff !important" }}>
					<TableRow style={{ "background-color": "#ffff !important" }}>
						<TableCell style={{ width: "120px" }}>{t("bildirishnoma.single.kimdan")}</TableCell>
						<TableCell align='center' style={{ width: "112px" }}>
							{t("bildirishnoma.single.data")}
						</TableCell>
						<TableCell align='center' style={{ width: "112px" }}>
							{t("bildirishnoma.status")}
						</TableCell>
						<TableCell align='center' style={{ width: "120px" }}>
							{t("bildirishnoma.harakat")}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{bildirishnoma?.map(item => (
						<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={item.name}>
							<StyledTableCell component='th' scope='row'>
								{item.kimdan}
							</StyledTableCell>
							<StyledTableCell align='center'>{dayjs(item.sana).format("DD.MM.YYYY")}</StyledTableCell>
							<StyledTableCell align='center'>
								{item.status === "Yuborildi" ? (
									<div className='status'>
										<img src={time} alt='' />
									</div>
								) : (
									<div className='status_green'>
										<img src={ptichka} alt='' />
									</div>
								)}
							</StyledTableCell>
							<StyledTableCell align='center'>
								<Button
									onClick={() => {
										navigate(`/singlettb/${item.id}/${item.Yuboruvchi}`);
										StatusChangerBolalar(item.id);
									}}
									className='single_info'
								>
									<img className='delete_icon' src={l1} alt='batafsil' />
								</Button>
							</StyledTableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
