import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import l1 from "assets/icon/l1.svg";
import dayjs from "dayjs";

const SendedNotificationTable = ({ data = [], path = "" }) => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow style={{ backgroundColor: "white" }}>
            <TableCell> {t("bildirishnoma.soni")}</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>{t("Kimdan")}</TableCell>
            <TableCell>{t("Topshirish muddati")}</TableCell>
            <TableCell>{t("Kimga")}</TableCell>
            <TableCell>{t("Sana")}</TableCell>
            <TableCell>{t("Status")}</TableCell>
            <TableCell>{t("bildirishnoma.harakat")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((el, index) => {
            return (
							<TableRow>
								<TableCell align='left'>
									{index + 1}
									<div className='ariza_bgc'></div>
								</TableCell>
								<TableCell align='left'>{el.id}</TableCell>
								<TableCell align='left'>{el.kimdan}</TableCell>
								<TableCell align='left'>{el.muddati}</TableCell>
								<TableCell align='left' style={{ maxWidth: "300px" }}>
									{el.kimga}
								</TableCell>
								<TableCell align='left'>{dayjs(el.sana).format("DD.MM.YYYY")}</TableCell>
								<TableCell>
									<button className={el.status === "Yuborildi" ? "status_btn" : "status_btn--not"}>
										{el.status !== "O'qildi" ? t("bildirishnoma.arstatus.yangi") : t("vosita.oqil")}
									</button>
								</TableCell>
								<TableCell align='center'>
									<Link to={`/${path}/${el.id}`}>
										<img src={l1} alt='batafsil' />
									</Link>
								</TableCell>
							</TableRow>
						);
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SendedNotificationTable;
