import { Button, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import CryptoJS from "crypto-js";
import sha256 from "crypto-js/sha256";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { request } from "../../../api/request";
import eye from "../../../assets/img/eye.svg";
import { Contextvalue } from "../../../context/context";
import { getRedirectUrl } from "../authorize";
import { onCallback } from "../get-token";
import "./auth.scss";
const base64url = require("base64url")

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Auth = ({ path }) => {
   
  // const verifier = CryptoJS.AES.encrypt("", "").ciphertext
  //       .toString()
  //       .replace(/\+/g, "-")
  //       .replace(/\//g, "_")
  //       .replace(/=/g, "");



// const redirect_uri = "https://nefro.ssv.uz/auth/callback";
const redirect_uri = "http://localhost:3004/auth/callback"
const token_endpoint = "https://test-sso.ssv.uz/oauth/authorize"


const  pkceChallengeFromVerifier = (v) => {
   const hashed = sha256(v);

   return CryptoJS.enc.Base64.stringify(hashed)
     .replace(/\+/g, "-")
     .replace(/\//g, "_")
     .replace(/=/g, "");
 }

const SSO = () => {
  console.log('before verifier')
    const verifier = CryptoJS.lib.WordArray.random(32);
    // const verifier = '2LyFp0DXPpfcYulWkM9IpfkmDMI8OBlfd_MRthaOqvE'

    const challenge = pkceChallengeFromVerifier(verifier);

  window.localStorage.setItem("verifier", challenge);
  localStorage.setItem("verifier_debug",challenge)
  const params = new URLSearchParams({
    client_id: '985170c4-46fd-4e0e-8fca-31ad8bbb89db', // almashtiring boyagisiga almawdi
    redirect_uri,
    response_type: 'code',
    code_challenge_method: 'S256',
    code_challenge: '6xKQp8FRej8K4bRrFOBe9YpfYoLXkMIpLWxYJTtOZj0',
  });
const url = `${token_endpoint}?${params.toString()}`;
console.log('after verifier')

window.location =url // verifier qayoqqa ketti??


console.log('after window.open')
// boshqa internet ishlatib ko'ringchi, blockka tushdi shekilli, bunaqasini endi ko'rishim
//mobile internet ishlatib ko'rinchi, korparativ netda white list qo'yilgan shekilli hozir 1 daqiqa boldi autosaveni o'chirish kerak, qotib yootibdi
}







  const navigate = useNavigate();
  const [loader, setLoeder] = useState(false);

  const [login, setLogin] = useState({});
  const { setAuthid } = useContext(Contextvalue);
  const [open, setOpen] = React.useState(false);
  const [warnig, setWarnig] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [eyes, setEyes] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setWarnig(false);
    setError(false);
  };
  function Create(e) {
    e.preventDefault();
    const formData = new FormData();
    for (let [key, value] of Object.entries(login)) {
      formData.append(key, value);
    }
    request
      .post(`/user/login/`, formData)
      .then(function (res) {
        window.localStorage.setItem("token", res.data.token);
        if (res.data.shifokor_id)  window.localStorage.setItem("id", res.data.shifokor_id.muassasa_id);
        if (res.data.ttb_id) window.localStorage.setItem("tid",res.data.ttb_id.tuman_id);
        if(res.data.uzmedimpeks_user) window.localStorage.setItem("uzb",res.data.uzmedimpeks_user.user_id);
        if(res.data.vssb_id) window.localStorage.setItem("vsb",res.data.vssb_id.user_id);
       else  if(res.data.user_type && !res.data.vssb_id && !res.data.ttb_id && !res.data.shifokor_id && !res.data.uzmedimpeks_user) window.localStorage.setItem(res.data.token,res.data.user_type);
        if (res.data.token && res.data.shifokor_id) {
            setOpen(true);
            navigate(`/`); 
            window.location.reload();
        }  if (res.data.token && res.data.ttb_id) {
            setOpen(true);
            navigate(`/`);
            window.location.reload();
        }
        if (res.data.token && res.data.uzmedimpeks_user) {
          setOpen(true);
          navigate(`/`);
          window.location.reload();
      }
      if (res.data.token && res.data.vssb_id) {
        setOpen(true);
        navigate(`/`);
        window.location.reload();
    }
       else if (res.data.token && res.data.user_type && !res.data.ttb_id && !res.data.uzmedimpeks_user && !res.data.shifokor_id && !res.data.vssb_id ) {
          setOpen(true);
          navigate(`/`);
          window.location.reload();
      } 
      })
      .catch(function (err) {
        console.log(err);
        if (err.message === "Request failed with status code 401")
          setWarnig(true);
        if (err.message === "Request failed with status code 403")
          setError(true);
      });
    setLoeder(true);
  }

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      setLogin({ ...login, [e.target.name]: String(e.target.checked) });
    } else {
      setLogin({ ...login, [e.target.name]: e.target.value });
    }
  };

   const redirectToSSO = async () => {
     const url = await getRedirectUrl();
     window.location = url;
   }

   React.useEffect(() => {

     const params = new URLSearchParams(window.location.search);
     const code = params.get("code");

     if (code) {
       onCallback();
     }

   }, []);
   const { t } = useTranslation();
  return (
    <div className="auth">
      <div className="auth_left">
        <Stack spacing={2}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Muffaqiyatli Kirish!
            </Alert>
          </Snackbar>
          <Snackbar open={warnig} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="warning"
              sx={{ width: "100%" }}
            >
              Parol yoki Foyadalanuvhi nomi xato!
            </Alert>
          </Snackbar>
          <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              Xatolik!
            </Alert>
          </Snackbar>
        </Stack>
        <div className="img_logo-ssv">
          <p className="ssv_text">
            O'zbekiston respublikasi sog'liqni saqlash vazirligi
          </p>
        </div>
        {!path && (
          <form action="" className="form_auth" onSubmit={Create}>
            <div className="input_auth_block">
              <TextField
                id="outlined-basic"
                label="Foydalanuvchi nomi"
                variant="outlined"
                name="username"
                onChange={onChange}
              />
            </div>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="input_auth_block"
            >
              <TextField
                id="outlined-basic"
                label="Parol"
                variant="outlined"
                onChange={onChange}
                name="password"
                type={eyes ? "text" : "password"}
              />
              <img
                className={eyes ? "visabel-eye--close" : "visabel-eye"}
                src={eye}
                alt="..."
                onClick={() => setEyes(!eyes)}
              />
            </div>
            {/* href="https://sso.ssv.uzoauth/aouthorize" */}
            <a  onClick = {
              () => redirectToSSO()
            }
            className = "sso-link" >
              SSO Orqali Kirish?
            </a>
            <div className="btn_auth">
              <Button
                type="submit"
                style={{
                  display: "block",
                  width: "100%",
                  backgroundColor: "#1464C0",
                }}
                variant="contained"
                // onClick={Create}

              >
                {t("shifokor.tasdiq")}
              </Button>
            </div>
          </form> 
        )}
        {path && (
          <div
            className="btn_auth"
            style={{
              marginTop: "70px",
            }}
          >
            <p style={{ textAlign: "center" }}>
              SSO Orqali Kirishni Tasdiqlaysizmi?
            </p>
            <Button
              style={{
                display: "block",
                width: "100%",
                backgroundColor: "#1464C0",
              }}
              variant="contained"
            >
              {t("shifokor.tasdiq")}
            </Button>
          </div>
        )}
      </div>
      <div className="auth_right"></div>
    </div>
  );
};

export default Auth;
