import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, SvgIcon, Tab, Tabs, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { request } from "../../../../../api/request";
import pdfDoc from "../../../../../assets/icon/pdf_doc.svg";
import scrip from "../../../../../assets/icon/scripka.svg";
import "./chiqim.scss";
import { toast } from "react-toastify";
;
function Chiqimmodal({ setOpens, setOpens2 }) {
	const initialState = {
		partiya_raqam: "",
		ttb: {},
		vssb: {},
		comment: "",
		vositalar: "",
		miq: "",
	};

	const initialoutgoingsState = {
		nom: {},
		tur: {},
		vosita_miqdori: "",
		vosita_seryasi: "",
	};

	const [formObject, setFormObject] = useState(initialState);
	const [formObjectOutgoings, setFormObjectOutgoings] = useState(initialoutgoingsState);

	const [open, setOpen] = useState(false);
	const [data, setData] = useState([]);
	const { t } = useTranslation();
	const [tuman, setTuman] = useState([]);

	const [vos, setVos] = useState();

	const [popone, setPopone] = useState(false);
	const [poptwo, setPoptwo] = useState(false);
	const [value, setValue] = useState(0);

	const [files, setFiles] = useState([]);
	const [images, setImages] = useState([]);
	const [outgoingsList, setOutgoingsList] = useState([]);
	const [outgoings, setOutgoings] = useState([]);


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
const resetAll = () => {
	setFormObject(initialState);
	setFormObjectOutgoings(initialoutgoingsState);
	setFiles([]);
	setImages([]);
	setOutgoingsList([]);
	setOutgoings([]);
}
	const handleClose = () => {
		setOpen(true);
		
		setPopone(false);
	};
	const token = window.localStorage.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	function uploadFile(e, setFiles) {
		setFiles(prev => [...prev, { file: e.target.files[0], filname: e.target.value }]);
	}
	const getInputValue = e => {
		setFormObject(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};
	const getInputValuesOfOutgoings = e => {
		setFormObjectOutgoings(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};
	function delFile(index, setFiles) {
		let sss = [];
		// setFiles(prev => {
		// 	return [...prev.splice(index, 1)];
		// });
		setFiles([]);
	}
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
		borderColor: 4,
	};

	function byurt(e) {
		e.preventDefault();
		if (!Object.entries(formObjectOutgoings).every(([, value]) => value)) {
			toast.error(t("Ma'lumotlarni to'liq kiriting"));
			return;
		}
		setOutgoingsList(prev => [...prev, formObjectOutgoings]);
		setPoptwo(false);
		setFormObjectOutgoings(initialoutgoingsState);
	}
	const countune = e => {
		e.preventDefault();
		setOpen(false);
		setPopone(true);
	};
	useEffect(() => {
		request.get("/user/respublika/viloyatlar/", config).then(data => setData(data.data.muassasalar));
		request.get("/ariza/vositalar/", config).then(data => setVos(data.data.data));
		request.get("/user/respublika/tumanlar/", config).then(data => setTuman(data.data.muassasalar));
	}, []);
	useEffect(() => {
		setOutgoings(
			outgoingsList.map(el => {
				return {
					...el,
					nomi: el.nom.nomi || "",
					turi: el.tur.nomi || "",
					vosita_nomi: +el.nom.id,
					vosita_turi: +el.tur.id,
					vosita_miqdori: +el.vosita_miqdori,
					vosita_seryasi: +el.vosita_seryasi,
				};
			})
		);
	}, [outgoingsList.length]);

	const Save = () => {
		if (formObject.ttb?.id || formObject.vssb?.id) {
			const formData = new FormData();
			formData.append("partiya_raqam", +formObject.partiya_raqam);
			files[0] && formData.append("file", files[0].file);
			images[0] && formData.append("image", images[0].file);
			formData.append("comment", formObject.comment);
			formData.append("vositalar", JSON.stringify(outgoings));

			if (formObject.ttb?.id) {
				formData.append("ttb_id", +formObject.ttb?.id);
				request
					.post("/omborxona/kirim/qilish/UzMedImpeks/dan/TTB/ga", formData, config)
					.then(() => {
						setOpens2(true)
						toast.success("Chiqim qilindi")
						setFormObject(initialState);
						setOutgoings([]);
						setOutgoingsList([]);
					})
					.catch(err => {
						toast.error(`Chiqim qilinmadi! Ma'lumot yuklashda xatolik bor`);
					});
			}
			if (formObject.vssb?.id) {
				formData.append("vssb_id", +formObject.vssb?.id);
				request
					.post("/omborxona/kirim/qilish/UzMedImpeks/dan/VSSB/ga", formData, config)
					.then(() => {
						toast.success("Chiqim qilindi")
						resetAll()
					})
					.catch(err => {
						toast.error(`Chiqim qilinmadi! Ma'lumot kiritishda xatolik mavjud`);
					});
				setPopone(false);
			}
		}
	};

	return (
		<>
			<Button
				onClick={() => setOpen(true)}
				style={{ marginRight: "20px", backgroundColor: "#F69641" }}
				startIcon={<ArrowOutwardIcon />}
				variant='contained'
			>
				{t("input.chiq")}
			</Button>
			<Modal
				keepMounted
				open={open}
				onClose={() => {
					setOpen(false)
					resetAll()
				}}
				aria-labelledby='keep-mounted-modal-title'
				aria-describedby='keep-mounted-modal-description'
			>
				<Box className='modal-one' sx={{ ...style, width: 500 }}>
					<div>
						<Button
							style={{
								marginBottom: "14px",
								marginLeft: "-25px",
							}}
							variant='text'
							onClick={() => {setOpen(false); resetAll()}}
						>
							<SvgIcon component={ArrowBackIcon} inheritViewBox />
						</Button>
						<h2 className='kirm-head'>{t("bildirishnoma.chiqim")}</h2>
					</div>
					<form onSubmit={countune}>
						<TextField
							style={{
								width: "437px",
								marginBottom: "20px",
							}}
							id='outlined-basic'
							name='partiya_raqam'
							value={formObject.partiya_raqam}
							onChange={getInputValue}
							label={t("vosita.partiys")}
							variant='outlined'
							required
							type={"number"}
						/>
						<FormControl
							fullWidth
							style={{
								marginBottom: "20px",
							}}
						>
							<InputLabel id='demo-simple-select-label'>{t("bildirishnoma.vlssv")}</InputLabel>
							<Select
								labelId='demo-simple-select-label'
								id='demo-simple-select'
								value={formObject.vssb}
								name='vssb'
								onChange={getInputValue}
								label={t("bildirishnoma.vlssv")}
							>
								{data.map(el => {
									return <MenuItem value={el}>{el?.nomi}</MenuItem>;
								})}
							</Select>
						</FormControl>
						<FormControl
							fullWidth
							style={{
								marginBottom: "20px",
							}}
						>
							<InputLabel id='demo-simple-select-label'>{t("pdf.rmo")}</InputLabel>
							<Select
								labelId='demo-simple-select-label'
								id='demo-simple-select'
								onChange={getInputValue}
								name={"ttb"}
								value={formObject.ttb}
								label='Nomi'
								disabled={!formObject.vssb?.id || !tuman.filter(el => el.viloyat_id == formObject.vssb?.id).length}
							>
								{tuman
									.filter(el => el.viloyat_id == formObject.vssb?.id)
									.map(el => {
										return <MenuItem value={el}>{el.nomi}</MenuItem>;
									})}
							</Select>
						</FormControl>

						<TextField
							style={{
								width: "437px",
								marginBottom: "20px",
							}}
							id='outlined-basic'
							name='comment'
							value={formObject.comment}
							onChange={getInputValue}
							label={t("input.yuk")}
							variant='outlined'
							required
						/>
						{/* <section>
							<div
								style={
									images[0] && {
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}
								}
							>
								<label className={images[0] ? "fix-btn" : "input_tyle_download fix-file"} id='f1'>
									<input onChange={(e)=>uploadFile(e, setImages)} className='visually-hidden' id='f1' type='file' />
									<img
										className={images[0] && "visually-hidden"}
										style={{
											display: "block",
											margin: "0 auto",
											marginBottom: "14px",
										}}
										src={scrip}
										alt='...'
									/>
									{!images[0] ? t("bildirishnoma.new.failinf") : t("vosita.qosh")}
								</label>
							</div>
							{arr2[0] && (
								<div className='sarflov_block_inner_div'>
									{images.map((item, index) =>
										images.length > 0 ? (
											<div key={index} className='sarflov_block_download_file'>
												<label className='input_tyle_download'>
													<img src={pdfDoc} alt='' className='label_img' />
													<div style={{ paddingRight: "50px" }}>{item.filename}</div>
													<div className='close_file' style={{ top: "0pxa!important" }}>
														<IconButton onClick={e => delFile2(index)}>
															<CloseIcon />
														</IconButton>
													</div>
												</label>
											</div>
										) : null
									)}
								</div>
							)}
						</section> */}

						<Button
							style={{
								width: "100%",
								borderRadius: "12px",
							}}
							type='submit'
							variant='contained'
						>
							{t("vosita.davom")}
						</Button>
					</form>
				</Box>
			</Modal>
			<Modal
				keepMounted
				open={popone}
				onClose={handleClose}
				aria-labelledby='keep-mounted-modal-title'
				aria-describedby='keep-mounted-modal-description'
			>
				<Box className='modal-one' sx={{ ...style, width: 500 }}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<div>
							<Button
								style={{
									marginBottom: "14px",
									marginLeft: "-25px",
								}}
								variant='text'
								onClick={handleClose}
							>
								<SvgIcon component={ArrowBackIcon} inheritViewBox />
							</Button>
							<h2 className='kirm-head'>{t("bildirishnoma.chiqim")}</h2>
						</div>
						<Button
							style={{
								marginBottom: "14px",
								marginLeft: "-25px",
							}}
							variant='text'
							onClick={() => setPopone(false)}
						>
							<SvgIcon component={ClearIcon} inheritViewBox />
						</Button>
					</div>
					<p className='chiqim-page'>
						{t("bola.tan")} {t("bildirishnoma.vlssv")}: {formObject.vssb?.nomi}{" "}
					</p>
					{!!formObject.ttb?.nomi && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
							className='chiqim-page'
						>
							{t("bola.tan")} {t("pdf.rmo")} : {formObject.ttb.nomi}{" "}
							<Link className='chiqim-link' to={"/uzchiqim"}>
								{t("pdf.kor")}
							</Link>{" "}
						</div>
					)}

					<Box
						sx={{
							borderBottom: 1,
							borderColor: "divider",
							marginBottom: "28px",
						}}
					>
						<Tabs style={{ backgroundColor: "#fff" }} value={value} onChange={handleChange} aria-label='basic tabs example'>
							<Tab label={t("bildirishnoma.single.vosi")} />
							<Tab label={t("input.fayl")} />
						</Tabs>
					</Box>
					{value === 0 && (
						<ul style={{ listStyle: "none" }} className={"site-list--create"}>
							{!!outgoings.length &&
								outgoings.map((el, index) => {
									return (
										<div className='site-list__div'>
											<li style={{ marginTop: "1rem" }}>#{index + 1}
												
											</li>

											<li className='site-list__item'>Nomi: {el?.nomi}</li>
											<li className='site-list__item'>
												Turi:
												{el.turi}
											</li>
											<li className='site-list__item'>Vosita Seriyasi: {el.vosita_seryasi}</li>
											<li className='site-list__item'>
												{t("bildirishnoma.single.miqdori")}: {el.vosita_miqdori}
											</li>
										</div>
									);
								})}
						</ul>
					)}
					{value === 1 && (
						<section className={value === 0 && "visually-hidden"}>
							<div
								style={
									files[0] && {
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}
								}
							>
								<h4 className='sarflov_block_title'>{t("bildirishnoma.new.fail")}</h4>
								<label className={files[0] ? "fix-btn" : "input_tyle_download fix-file"} id='f1'>
									<input onChange={e => uploadFile(e, setFiles)} className='visually-hidden' id='f1' type='file' />
									<img
										className={files[0] && "visually-hidden"}
										style={{
											display: "block",
											margin: "0 auto",
											marginBottom: "14px",
										}}
										src={scrip}
									/>
									{!files[0] ? t("bildirishnoma.new.failinf") : t("vosita.qosh")}
								</label>
							</div>
							{files[0] && (
								<div className='sarflov_block_inner_div'>
									{files.map((item, index) =>
										files.length > 0 ? (
											<div key={index} className='sarflov_block_download_file'>
												<label className='input_tyle_download'>
													<img src={pdfDoc} alt='' className='label_img' />
													{item.filename}
													<div className='close_file'>
														<Button onClick={e => delFile(index, setFiles)} startIcon={<CloseIcon />}></Button>
													</div>
												</label>
											</div>
										) : null
									)}
								</div>
							)}
						</section>
					)}

					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-around",
						}}
					>
						{/* <Button 
                // onClick={() => setPoptwo(true)}
                startIcon={<AddIcon />}
                style={{
                    border:"none",
                }}
                variant="outlined">Dori qo’shish</Button>
                  <Button 
                startIcon={<AddIcon />}
                style={{
                    border:"none",
                }}
                variant="outlined">Vitamin qo’shish</Button> */}
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-around",
							marginTop: "36px",
							borderTop: "1px solid #E7EBF2",
						}}
					>
						<Button
							onClick={() => setPoptwo(true)}
							startIcon={<AddIcon />}
							style={{
								border: "none",
							}}
							variant='outlined'
						>
							{t("vosita.vositaqosh")}
						</Button>
						{/* <Button 
                startIcon={<AddIcon />}
                style={{
                    border:"none",
                }}
                variant="outlined">Vitamin qo’shish</Button> */}
					</div>
					<Button
						onClick={Save}
						style={{
							width: "100%",
							marginTop: "20px",
							borderRadius: "12px",
						}}
						variant='contained'
					>
						{t("input.chiq")}
					</Button>
				</Box>
			</Modal>
			<Modal
				keepMounted
				open={poptwo}
				onClose={() => setPoptwo(false)}
				aria-labelledby='keep-mounted-modal-title'
				aria-describedby='keep-mounted-modal-description'
			>
				<Box sx={{ ...style, width: 500 }}>
					<Button
						style={{
							marginBottom: "14px",
							marginLeft: "-25px",
						}}
						variant='text'
						onClick={() => setPoptwo(false)}
					>
						<SvgIcon component={ArrowBackIcon} inheritViewBox />
					</Button>
					<h2 className='kirm-head'>{t("vosita.vositaqosh")}</h2>
					<form onSubmit={byurt}>
						<FormControl fullWidth>
							<InputLabel id='demo-simple-select-label'>{t("vosita.vositaturi")}</InputLabel>
							<Select
								style={{ marginBottom: "20px" }}
								labelId='demo-simple-select-label'
								id='demo-simple-select'
								label='Nomi'
								name='tur'
								value={formObjectOutgoings.tur}
								onChange={getInputValuesOfOutgoings}
								required
							>
								{vos &&
									vos.map(el => {
										return <MenuItem value={el}>{el.nomi}</MenuItem>;
									})}
							</Select>
						</FormControl>
						<FormControl fullWidth>
							<InputLabel id='demo-simple-select-label'>{t("bildirishnoma.single.nomi")}</InputLabel>
							<Select
								style={{ marginBottom: "20px" }}
								labelId='demo-simple-select-label'
								name={"nom"}
								value={formObjectOutgoings.nom}
								onChange={getInputValuesOfOutgoings}
								id='demo-simple-select'
								label={t("bildirishnoma.single.nomiinput")}
								required
							>
								{formObjectOutgoings.tur?.vosita_nomlari &&
									formObjectOutgoings.tur.vosita_nomlari?.map(el => {
										return <MenuItem value={el}>{el.nomi}</MenuItem>;
									})}
							</Select>
						</FormControl>
						<TextField
							style={{
								width: "437px",
								marginBottom: "20px",
							}}
							onChange={getInputValuesOfOutgoings}
							id='outlined-basic'
							label='Vosita Seriyasi'
							name={"vosita_seryasi"}
							value={formObjectOutgoings.vosita_seryasi}
							variant='outlined'
							required
						/>
						<TextField
							style={{
								width: "437px",
								marginBottom: "20px",
							}}
							name={"vosita_miqdori"}
							value={formObjectOutgoings.vosita_miqdori}
							onChange={getInputValuesOfOutgoings}
							id='outlined-basic'
							label={t("bildirishnoma.single.miqdori")}
							variant='outlined'
							required
							type={"number"}
						/>
						<Button
							style={{
								display: "block",
							}}
							type='submit'
							variant='contained'
						>
							{t("bildirishnoma.jonat")}
						</Button>
					</form>
				</Box>
			</Modal>
		</>
	);
}
export default Chiqimmodal;
