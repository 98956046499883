import { useEffect, useState } from "react";
import { request } from "api/request";
import TabsBildirishnoma from "../bildirishnoma/muiTab";
import "./datatable.scss";
import { useTranslation } from "react-i18next";
import BasicTable from "./jonatilganArizaTable";
const DataTable = () => {
	const [vosita, setVosita] = useState([]);
	const { t } = useTranslation();
	const token = localStorage.getItem("token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	useEffect(() => {
		request.get(`/omborxona/buyurtma/yaratish`, config).then(data => {
			setVosita(data?.data);
		});
	}, []);

	const Data = vosita.filter(el => (el.partiyadan_kelgan_vosita_miqdori / el.vosita_miqdori) * 100 === 100);
	const aktiv = vosita.filter(el => (el.partiyadan_kelgan_vosita_miqdori / el.vosita_miqdori) * 100 < 100);

	const kirim = {
		div: {
			backgroundColor: "#DDEBFB",
			border: "1px solid #9AC4F4",
			color: "#1258A8",
		},
	};
	return (
		<div>
			<div className='asosiy_menu' style={{ margin: 0, display: "flex" }}>
				<div className='div_1' style={{ padding: "1rem", flex: "1!important" }}>
					<h1>{t("bildirishnoma.qariz")}</h1>
					<BasicTable />
				</div>
				<div className='div_2' style={{ flex: "0.6" }}>
					<div className='dori_vitamin'>
						<div className='dorilar'>
							<h1>{t("bildirishnoma.b")}</h1>
							<div className='header_kirim_chiqim'>
								<div className='span'>{t("bildirishnoma.a")}</div>
						
								<div className='span'>{t("bildirishnoma.ba")}</div>
								
							</div>
							<div className='button_kir'>
								<div className='kirim' style={kirim.div}>
									{aktiv?.length}
								</div>
								<div className='chiqim_d'>{Data.length}</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ overflow: "inherit", flex: "1!important", width: "100%" }} className='div_3'>
					<h1>{t("qabxar")}</h1>
					<TabsBildirishnoma />
				</div>
			</div>
		</div>
	);
};

export default DataTable;
