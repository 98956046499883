import "./aziza.scss";
import BasicTabs from "./tabOshp";

const allAziza = () => {
  return (
    <div className="monitoring">
      <BasicTabs />
    </div>
  );
};

export default allAziza;
