import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { request } from "../../../../api/request";
import pdfDoc from "../../../../assets/icon/pdf_doc.svg";
import scrip from "../../../../assets/icon/scripka.svg";
import "./omborhona.scss";
import { get } from "lodash";
function Chiqimvsb() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [tuman, setTuman] = useState([]);
  const [vil, setVil] = useState([]);
  const [ttb, setTtb] = useState([]);
  const [vos, setVos] = useState();
  const [tur, setTur] = useState();
  const [comment, setComment] = useState();
  const [nom, setNom] = useState();
  const [ser, setSer] = useState();
  const [miq, setMiq] = useState();
  const [dot, setDot] = useState([
    {
      vosita_nomi: "",
      vosita_turi: "",
      vosita_miqdori: "",
      vosita_seryasi: "",
    },
  ]);
  const [par, setPar] = useState([]);
  const [popone, setPopone] = useState(false);
  const [poptwo, setPoptwo] = useState(false);
  const [value, setValue] = useState(0);
  const [arr, setArr] = useState([]);
  const [arr2, setArr2] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(true);
    setPopone(false);
  };
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  function File(e) {
    setArr([
      {
        filename: e.target.value,
        fil: e.target.files[0],
      },
    ]);
  }
  function File2(e) {
    setArr2([
      {
        filename: e.target.value,
        fil: e.target.files[0],
      },
    ]);
  }
  function delFile(index) {
    let sss = [];
    sss.push(...arr);
    sss.splice(index, 1);
    setArr(sss);
  }
  function delFile2(index) {
    let sss = [];
    sss.push(...arr2);
    sss.splice(index, 1);
    setArr2(sss);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  function byurt(e) {
    e.preventDefault();
    // setOpen(false);
    // setPopone(true);
    const obj = {
      nomi: nom && nom.nomi,
      turi: tur && tur.nomi,
      vosita_miqdori: Number(miq),
      vosita_seryasi: ser,
      vosita_nomi: nom && nom.id,
      vosita_turi: tur && tur.id,
    };
    dot[dot.length - 1] = { ...obj };
    setDot([
      ...dot,
      {
        vosita_nomi: "",
        vosita_turi: "",
        vosita_miqdori: "",
        vosita_seryasi: "",
      },
    ]);
    setPoptwo(false);
  }
  const countune = (e) => {
    e.preventDefault();
    setOpen(false);
    setPopone(true);
  };
  useEffect(() => {
    request
      .get("/user/viloyat/muassasalar/", config)
      .then((data) => setData(data.data.muassasalar));
  }, []);
  useEffect(() => {
    request
      .get("/user/viloyat/tumanlar/", config)
      .then((data) => setTuman(data.data.muassasalar));
  }, []);
  useEffect(() => {
    request
      .get("/ariza/vositalar/", config)
      .then((data) => setVos(data.data.data));
  }, []);

  const fil = tur && vos.filter((el) => el.id === tur.id);
  const creat = dot.filter((el) => el.vosita_nomi !== "");

  const Save = () => {
    if (ttb.id) {
      const formData = new FormData();
      formData.append("partiya_raqam", par);
      formData.append("ttb_id", ttb.id);
      formData.append("comment", comment);
      formData.append("vssb_id", localStorage.getItem("vsb"));
      arr[0] && formData.append("file", arr[0].fil);
      arr2[0] && formData.append("image", arr2[0].fil);
      formData.append("vositalar", JSON.stringify(creat));
      request
        .post("/omborxona/kirim/qilish/VSSB/dan/TTB/ga", formData, config)
        .then(() => {
          toast.success("TTB ga yuborildi!");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          if (get(error, "response.data.data")) {
            toast.error(get(error, "response.data.message"));
          }
          toast.error("TTB ga yuborib bo'lmadi yana urunib ko'ring!");
        });
    }
    if (vil.id) {
      setTimeout(() => {
        const formDatas = new FormData();
        formDatas.append("partiya_raqam", par);
        formDatas.append("lpu_id", vil.id);
        formDatas.append("comment", comment);
        formDatas.append("vssb_id", localStorage.getItem("vsb"));
        arr[0] && formDatas.append("file", arr[0].fil);
        arr2[0] && formDatas.append("image", arr2[0].fil);
        formDatas.append("vositalar", JSON.stringify(creat));
        request
          .post("/omborxona/kirim/qilish/VSSB/dan/LPU/ga", formDatas, config)
          .then(() => {
            toast.success("Lpu ga yuborildi!");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            if (get(error, "response.data.data")) {
              toast.error(get(error, "response.data.message"));
            }
            toast.error("Lpu ga yuborib bo'lmadi yana urunib ko'ring!");
          });
      }, 1500);
    }
    setPopone(false);
  };
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style={{ marginRight: "20px", backgroundColor: "#F69641" }}
        startIcon={<ArrowOutwardIcon />}
        variant="contained"
      >
        {t("input.chiq")}
      </Button>
      <Modal
        style={{
          overflow: "scroll",
        }}
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="h-[100vh] overflow-auto" sx={{ ...style, width: 500 }}>
          <div>
            <Button
              style={{
                marginBottom: "14px",
                marginLeft: "-25px",
                marginTop: "30px",
              }}
              variant="text"
              onClick={() => setOpen(false)}
            >
              <SvgIcon component={ArrowBackIcon} inheritViewBox />
            </Button>
            <h2 className="kirm-head">{t("bildirishnoma.chiqim")}</h2>
          </div>
          <form onSubmit={countune}>
            <TextField
              style={{
                width: "437px",
                marginBottom: "20px",
              }}
              id="outlined-basic"
              onChange={(e) => setPar(e.target.value)}
              label={t("vosita.partiys")}
              variant="outlined"
              required
              type={"number"}
            />

            <FormControl style={{ marginBottom: "18px" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("pdf.rmo")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => setTtb(e.target.value)}
                label={t("bildirishnoma.single.nomiinput")}
              >
                {tuman.map((el) => {
                  return <MenuItem value={el}>{el.nomi}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl style={{ marginBottom: "18px" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("pdf.oshp")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => setVil(e.target.value)}
              >
                {data.map((el) => {
                  return <MenuItem value={el}>{el.nomi}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <TextField
              style={{
                width: "437px",
                marginBottom: "20px",
              }}
              id="outlined-basic"
              onChange={(e) => setComment(e.target.value)}
              label={t("input.yuk")}
              variant="outlined"
            />
            <section>
              <div
                style={
                  arr2[0] && {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }
                }
              >
                <h4 className="sarflov_block_title">Rasm biriktirish</h4>
                <label
                  className={
                    arr2[0] ? "fix-btn" : "input_tyle_download fix-file"
                  }
                  id="f1"
                >
                  <input
                    onChange={File2}
                    className="visually-hidden"
                    id="f1"
                    type="file"
                    accept="image/*"
                  />
                  <img
                    className={arr2[0] && "visually-hidden"}
                    style={{
                      display: "block",
                      margin: "0 auto",
                      marginBottom: "14px",
                    }}
                    src={scrip}
                    alt="..."
                  />
                  {!arr2[0]
                    ? t("Rasmni bu yerga tashlang yoki biriktiring")
                    : t("vosita.qosh")}
                </label>
              </div>
              {arr2[0] && (
                <div className="sarflov_block_inner_div">
                  {arr2.map((item, index) =>
                    arr2.length > 0 ? (
                      <div key={index} className="sarflov_block_download_file">
                        <div
                          className="input_tyle_download"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div>
                            <img src={pdfDoc} alt="" className="label_img" />
                            <span className="file-name"> {item.filename}</span>
                          </div>
                          <div className=" ">
                            <CloseIcon onClick={(e) => delFile2(index)} />
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              )}
            </section>

            <Button
              style={{
                width: "100%",
                borderRadius: "12px",
              }}
              type="submit"
              variant="contained"
            >
              {t("vosita.davom")}
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={popone}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="modal-one" sx={{ ...style, width: 500 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Button
                style={{
                  marginBottom: "14px",
                  marginLeft: "-25px",
                }}
                variant="text"
                onClick={handleClose}
              >
                <SvgIcon component={ArrowBackIcon} inheritViewBox />
              </Button>
              <h2 className="kirm-head">{t("bildirishnoma.chiqim")}</h2>
            </div>
            <Button
              style={{
                marginBottom: "14px",
                marginLeft: "-25px",
              }}
              variant="text"
              onClick={() => setPopone(false)}
            >
              <SvgIcon component={ClearIcon} inheritViewBox />
            </Button>
          </div>
          <p className="chiqim-page">
            {t("bola.tan")} {t("input.lpu")}: {vil.nomi}{" "}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="chiqim-page"
          >
            {t("bola.tan")} {t("pdf.rmo")} : {ttb.nomi}{" "}
          </div>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "28px",
            }}
          >
            <Tabs
              style={{ backgroundColor: "#fff" }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("bildirishnoma.single.vosi")} />
              <Tab label={t("input.fayl")} />
            </Tabs>
          </Box>
          {value === 0 && (
            <ul style={{ listStyle: "none" }} className={"site-list--create"}>
              {dot &&
                creat.map((el, index) => {
                  return (
                    <div className="site-list__div">
                      <li>#{index + 1}</li>
                      <li style={{ marginTop: "10px", marginBottom: "14px" }}>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          {/* <img  src={l3} id={el.id}/> */}
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          {/* <img src={l2} id={el.id}/> */}
                        </button>
                      </li>
                      <li className="site-list__item">
                        {t("bildirishnoma.single.nomiinput")}: {el.nomi}
                      </li>
                      <li className="site-list__item">
                        {t("input.turi")}:{el.turi}
                      </li>
                      <li className="site-list__item">
                        {t("bildirishnoma.single.seriyasi")}:{" "}
                        {el.vosita_seryasi}
                      </li>
                      <li className="site-list__item">
                        {t("bildirishnoma.single.miqdori")}: {el.vosita_miqdori}
                      </li>
                    </div>
                  );
                })}
            </ul>
          )}
          {value === 1 && (
            <section className={value === 0 && "visually-hidden"}>
              <div
                style={
                  arr[0] && {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }
                }
              >
                <h4 className="sarflov_block_title">
                  {t("bildirishnoma.new.fail")}
                </h4>
                <label
                  className={
                    arr[0] ? "fix-btn" : "input_tyle_download fix-file"
                  }
                  id="f1"
                >
                  <input
                    onChange={File}
                    className="visually-hidden"
                    id="f1"
                    type="file"
                  />
                  <img
                    className={arr[0] && "visually-hidden"}
                    style={{
                      display: "block",
                      margin: "0 auto",
                      marginBottom: "14px",
                    }}
                    src={scrip}
                    alt=".."
                  />
                  {!arr[0] ? t("bildirishnoma.new.failinf") : t("vosita.qosh")}
                </label>
              </div>
              {arr[0] && (
                <div className="sarflov_block_inner_div">
                  {arr.map((item, index) =>
                    arr.length > 0 ? (
                      <div key={index} className="sarflov_block_download_file">
                        <label
                          className="input_tyle_download"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img src={pdfDoc} alt="" className="label_img" />
                          <p className="file-name" style={{ margin: 0 }}>
                            {item.filename}
                          </p>
                          <div
                            onClick={(e) => delFile(index)}
                            className=""
                            style={{ right: "10px", top: "10px" }}
                          >
                            <CloseIcon />
                          </div>
                        </label>
                      </div>
                    ) : null
                  )}
                </div>
              )}
            </section>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "36px",
              borderTop: "1px solid #E7EBF2",
            }}
          >
            {value === 0 && (
              <Button
                onClick={() => setPoptwo(true)}
                startIcon={<AddIcon />}
                style={{
                  border: "none",
                }}
                variant="outlined"
              >
                {t("vosita.vositaqosh")}
              </Button>
            )}
          </div>
          <Button
            onClick={Save}
            style={{
              width: "100%",
              marginTop: "20px",
              borderRadius: "12px",
            }}
            variant="contained"
          >
            {t("input.chiq")}
          </Button>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={poptwo}
        onClose={() => setPoptwo(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <Button
            style={{
              marginBottom: "14px",
              marginLeft: "-25px",
            }}
            variant="text"
            onClick={() => setPoptwo(false)}
          >
            <SvgIcon component={ArrowBackIcon} inheritViewBox />
          </Button>
          <h2 className="kirm-head">{t("vosita.vositaqosh")}</h2>
          <form onSubmit={byurt}>
            <FormControl style={{ marginBottom: "20px" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("vosita.vositaturi")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t("bildirishnoma.single.nomiinput")}
                onChange={(e) => setTur(e.target.value)}
                required
              >
                {vos &&
                  vos.map((el) => {
                    return <MenuItem value={el}>{el.nomi}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            <FormControl style={{ marginBottom: "20px" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("bildirishnoma.single.nomi")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                onChange={(e) => setNom(e.target.value)}
                id="demo-simple-select"
                label={t("bildirishnoma.single.nomiinput")}
                required
              >
                {fil &&
                  fil[0].vosita_nomlari.map((el) => {
                    return <MenuItem value={el}>{el.nomi}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            <TextField
              style={{
                width: "437px",
                marginBottom: "20px",
              }}
              onChange={(e) => setSer(e.target.value)}
              id="outlined-basic"
              label={t("bildirishnoma.single.seriyasi")}
              variant="outlined"
              required
            />
            <TextField
              style={{
                width: "437px",
                marginBottom: "20px",
              }}
              onChange={(e) => setMiq(e.target.value)}
              id="outlined-basic"
              label={t("bildirishnoma.single.miqdori")}
              variant="outlined"
              required
              type={"number"}
            />
            <Button
              style={{
                display: "block",
              }}
              type="submit"
              variant="contained"
            >
              {t("bildirishnoma.jonat")}
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
}
export default Chiqimvsb;
