import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  Fade,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import { request } from "api/request";
import kirims from "assets/icon/kirim2.svg";
import l1 from "assets/icon/l1.svg";
import l4 from "assets/icon/l4.svg";
import Koriklar from "components/component/seanslar/koriklar";
import dayjs from "dayjs";
import useGet from "hooks/useGet";
import { get } from "lodash";
import "moment/locale/uz";
import qs from "qs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Uzmoni = () => {
  const { t } = useTranslation();
  const [send, setSend] = useState([]);
  const [vilid, setVilid] = useState();
  const [kirim, setKirim] = useState();
  const [chiqim, setChiqim] = useState();
  const [ttb, setTtb] = useState();
  const [seans, setSeans] = useState(false);
  const [loader, setLoeder] = useState(true);
  const [targetid, setTargetid] = useState();
  const [bemid, setBemid] = useState();
  const [close, setClose] = useState(false);
  const [sea, setSea] = useState({
    isFetched: false,
    data: {},
    error: null,
  });
  const [value, setValue] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const locationValues = qs.parse(location.search.slice(1));
  const classes = {
    table: {
      minWidth: 700,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      padding: "50px",
      width: "80%",
      margin: "30px auto 0 auto",
    },
    formControl: {
      margin: "1px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    button: {
      padding: "8px",
      borderRadius: "12px",
    },
    button2: {
      backgroundColor: "#F69641",
    },
  };
  const handleSeansClose = () => {
    setSeans(false);
  };
  function Seansbemor(e) {
    setBemid(e);
    setSeans(true);

    request
      .get(`/korik/bola/malumotlari/${e}`, config)
      .then(function (res) {
        setSea({
          isFetched: true,
          data: res.data.koriklar_hammasi,
          error: false,
        });

        setLoeder(false);
        console.log(res.data.koriklar_hammasi);
      })
      .catch(function (err) {
        setSea({
          isFetched: false,
          data: [],
          error: err,
        });
        setLoeder(false);
      });
  }
  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const handleClick = () => {
    request
      .get(
        `/omborxona/uzmedimpeks/vositalar/manitoringi?viloyat=${vilid}&${
          ttb ? `tuman=${ttb}` : ""
        }&${targetid ? `muassasa=${targetid}` : ""}`,
        config
      )
      .then((data) => setSend(data.data));
  };
  const Den = (e) => {
    setKirim(send.find((el) => el.id == e));
    setClose(true);
  };
  const More = (e) => {
    setChiqim(send.find((el) => el.id == e));
    setClose(true);
  };

  useEffect(() => {
    setVilid(locationValues.vilid);
    setTtb(locationValues.ttb);
    setTargetid(locationValues.targetid);
    if (targetid) setSend([])
  }, [locationValues.targetid, locationValues.ttb, locationValues.vilid, targetid]);

  const {
    data: { muassasalar: regions = [] },
  } = useGet({ url: "/user/respublika/viloyatlar/" });
  const {
    data: { muassasalar: districts = [] },
  } = useGet({
    url: `/user/respublika/tumanlar/${vilid ? `?viloyat_id=${vilid}` : ""}`,
    enabled: vilid ? true : false,
  });
  const {
    data: { muassasalar: points = [] },
  } = useGet({
    url: `/user/respublika/muassasalar/${ttb ? `?tuman_id=${ttb}` : ""}`,
    enabled: ttb,
  });
console.log(send);
  return (
    <div className="prihod">
      <div className="prihod_top">
        <div className="prihod_top_inner">
          <h4 className="monitoring_top_inner_title">{t("sidebar.monitor")}</h4>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            margin: "28px 0 0 10px",
            alignItems: "center",
          }}
        >
          <div
            className=""
            style={{
              width: "100%",
              display: "grid",
              alignItems: "center",
              gridTemplateColumns: "repeat(3,1fr)",
              gap: "10px",
            }}
          >
            <div className="">
              <h5 className="sarflov_block_inner_div_title">
                {t("bildirishnoma.vlssv")}
              </h5>
              <Autocomplete
                options={regions}
                value={vilid ? regions.find((item) => item.id == vilid) : ""}
                onChange={(_, value) => {
                  setVilid(get(value, "id", null));
                  navigate(
                    `${location.pathname}?${
                      get(value, "id")
                        ? qs.stringify({
                            vilid: get(value, "id", null),
                          })
                        : ""
                    }`
                  );
                }}
                getOptionLabel={(option) => option.nomi || ""}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label={t("SSB")}
                      placeholder={t("bola.add")}
                    />
                  );
                }}
              />
            </div>
            <div className="">
              <h5 className="sarflov_block_inner_div_title">
                {t("bildirishnoma.tuman")}
              </h5>
              <Autocomplete
                options={districts.length ? districts : []}
                value={
                  ttb
                    ? districts.find((item) => item.id == locationValues.ttb)
                    : ""
                }
                disabled={!locationValues.vilid || !districts.length}
                // inputValue={}
               
                onChange={(_, value) => {
                  setTtb(get(value, "id", null));
                  navigate(
                    `${location.pathname}?${
                      get(value, "id")
                        ? qs.stringify({
                            ...locationValues,
                            ttb: get(value, "id", null),
                          })
                        : qs.stringify({
                            vilid: locationValues.vilid,
                          })
                    }`
                  );
                }}
                getOptionLabel={(option) => option.nomi || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("pdf.rmo")}
                    disabled={!locationValues.vilid || !districts.length}
                    placeholder={t("bola.add")}
                  />
                )}
              />
            </div>
            <div className="">
              <h5 className="sarflov_block_inner_div_title">
                {t("bildirishnoma.single.vositainf")}
              </h5>
              <Autocomplete
                id="checkboxes-tags-demo"
                options={points}
                disabled={!locationValues.ttb}
                value={
                  targetid
                    ? points.find((item) => item.id == locationValues.targetid)
                    : ""
                }
                onChange={(_, value,reason) => {
                  setTargetid(get(value, "id", null));
                  navigate(
                    `${location.pathname}?${
                      get(value, "id")
                        ? qs.stringify({
                            ...locationValues,
                            targetid: get(value, "id", null),
                          })
                        : qs.stringify({
                            vilid: locationValues.vilid,
                            ttb: locationValues.ttb,
                          })
                    }`
                  );
                  if (reason === "clear") setSend([])
                }}
                getOptionLabel={(option) => option.nomi || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("pdf.oshp")}
                    disabled={!locationValues.ttb}
                    placeholder={t("bola.add")}
                  />
                )}
              />
            </div>
          </div>
          <Button
            onClick={handleClick}
            style={{ height: "54px", margin: "30px 0 0 24px" }}
            startIcon={<SearchIcon />}
            variant="contained"
          >
            {t("input.qidir")}
          </Button>
        </div>
      </div>
      {!targetid && (
        <div className="prihod_block">
          <div className="prihod_block_inner">
            <div className="prihod_block_inner_top">
              <h4 className="prihod_block_inner_title">{t("input.sps")}</h4>
              <Tabs
                style={{ backgroundColor: "#fff" }}
                value={value}
                onChange={handleChanges}
                aria-label="basic tabs example"
              >
                <Tab label={t("bildirishnoma.kirim")} />
                <Tab label={t("bildirishnoma.chiqim")} />
              </Tabs>
            </div>
            <div
              className="prihod_block_inner_middle"
              style={{ overflowY: "scroll", height: "500px" }}
            >
              <div className="card_blocks">
                {value === 0 &&
                  send &&
                  send
                    .filter((elt) => elt.kirim_chiqim.status === "kirim")
                    .map((el) => {
                      return (
												<div className='kirim_card chiqim_card'>
													<div div className='kirim_card_left chiqim_card_left '>
														<img src={kirims}  alt="..."/>
														<p>
															{t("shifokor.jami")}: {el.vosita_miqdori}
														</p>
													</div>
													<div className='chiqim_card_center'>
														<div className='kirim_card_center_top'>
															<div className='top_left'>
																<p>{t("vosita.partiys")}:</p>
																<h5>{el.kirim_chiqim.partiya_raqam}</h5>
															</div>
															<div className='top_right'>
																<div className='kirim_card_right_left'>
																	<p>{el.created_at.split("T")[0]}</p>
																	<span>
																		{/* <Dayjs> */}
																		{dayjs(el.created_at).format("HH:mm")}
																		{/* </Dayjs> */}
																	</span>
																</div>
																<div className='kirim_card_right_left'>
																	<Button onClick={() => Den(el.id)}>
																		<img src={l1} alt="..." />
																	</Button>
																</div>
															</div>
														</div>
														<div
															className='bottom'
															style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
														>
															<div style={{ border: "none", padding: "5px" }}>
																<span>{t("vosita.vositaturi")}</span>: {el.vosita_turi.nomi}{" "}
															</div>
															<div style={{ border: "none", padding: "5px" }}>
																<span>{t("bildirishnoma.single.nomi")}</span>: {el.vosita_nomi.nomi}
															</div>
														</div>
													</div>
												</div>
											);
                    })}
                {value === 1 &&
                  send &&
                  send
                    .filter((elt) => elt.kirim_chiqim.status === "chiqim")
                    .map((el) => {
                      return (
												<>
													<div className='kirim_card chiqim_card'>
														<div className='kirim_card_left chiqim_card_left'>
															<Button size='large' className={classes.button} startIcon={<CallMadeIcon />}></Button>
															<p>
																{t("shifokor.jami")}: {el.vosita_miqdori}
															</p>
														</div>
														<div className='chiqim_card_center'>
															<div className='kirim_card_center_top'>
																<div className='top_left'>
																	<p>{t("bildirishnoma.send")}:</p>
																	<h5>{el.kirim_chiqim.chiqim_qilingan_tashkilot}</h5>
																</div>
																<div style={{ flexDirection: "column" }} className='top_left'>
																	<p>{t("input.barkod")}:</p>
																	<h5>{!el.kirim_chiqim.unique_raqam ? t("bola.kir") : el.kirim_chiqim.unique_raqam}</h5>
																</div>
																<div className='top_right'>
																	<div className='kirim_card_right_left'>
																		<p>{el.created_at.split("T")[0]}</p>
																		<span>{el.created_at.split("T")[1].slice(0, 5)}</span>
																	</div>
																	<div className='kirim_card_right_left'>
																		<Button onClick={() => More(el.id)}>
																			<img src={l1} alt="...." />
																		</Button>
																	</div>
																</div>
															</div>
															<div
																className='bottom'
																style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
															>
																<div style={{ border: "none" }}>
																	<span>{t("vosita.vositaturi")}</span>: {el.vosita_turi.nomi}{" "}
																</div>
																<div style={{ border: "none" }}>
																	<span>{t("bildirishnoma.single.nomi")}</span>: {el.vosita_nomi.nomi}
																</div>
															</div>
														</div>
													</div>
													
												</>
											);
                    })}

                {value === 1 &&
                  send &&
                  send
                    .filter((elt) => elt.kirim_chiqim.status === "chiqim")
                    .map((el) => {
                      return (
                        <div className="kirim_card chiqim_card">
                          <div className="kirim_card_left chiqim_card_left">
                            <Button
                              size="large"
                              className={classes.button}
                              startIcon={<CallMadeIcon />}
                            ></Button>
                            <p>
                              {t("shifokor.jami")}: {el.vosita_miqdori}
                            </p>
                          </div>
                          <div className="chiqim_card_center">
                            <div className="kirim_card_center_top">
                              <div className="top_left">
                                <p>{t("bildirishnoma.send")}:</p>
                                <h5>
                                  {el.kirim_chiqim.chiqim_qilingan_tashkilot}
                                </h5>
                              </div>
                              <div
                                style={{ flexDirection: "column" }}
                                className="top_left"
                              >
                                <p>{t("input.barkod")}:</p>
                                <h5>
                                  {!el.kirim_chiqim.unique_raqam
                                    ? t("bola.kir")
                                    : el.kirim_chiqim.unique_raqam}
                                </h5>
                              </div>
                              <div className="top_right">
                                <div className="kirim_card_right_left">
                                  <p>{el.created_at.split("T")[0]}</p>
                                  <span>
                                    {el.created_at.split("T")[1].slice(0, 5)}
                                  </span>
                                </div>
                                <div className="kirim_card_right_left">
                                  <Button onClick={() => More(el.id)}>
                                    <img src={l1} alt="" />
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div className="bottom">
                              <div style={{ overflowY: "scroll" }}>
                                <span>
                                  {t("vosita.vositaturi")}:{" "}
                                  {el.vosita_turi.nomi}
                                </span>
                              </div>
                              <div >
                                <span>
                                  {t("bildirishnoma.single.nomi")}:{" "}
                                  {el.vosita_nomi.nomi}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
          <div className="prihod_block_inner">
            <div className="kirim_right_inner_top">
              <h4>{t("input.kr")}</h4>
              <Button
                onClick={() => setClose(false)}
                variant="contained"
                startIcon={<CloseIcon />}
              >
                {t("input.yop")}
              </Button>
            </div>
            {close && (
              <div className="kirim_right_inner_bottom">
                <div className="kirim_right_inner_bottom_bottom">
                  <TableContainer
                    style={{ borderRadius: "12px" }}
                    component={Paper}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            {t("vosita.vositaturi")}
                          </TableCell>
                          <TableCell align="center">
                            {t("bildirishnoma.single.nomi")}
                          </TableCell>
                          <TableCell align="center">
                            {t("input.barkod")}
                          </TableCell>

                          <TableCell align="center">
                            {t("bildirishnoma.single.miqdori")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {chiqim && (
                          <TableRow>
                            <TableCell align="center">
                              {chiqim && chiqim.vosita_turi.nomi}
                            </TableCell>
                            <TableCell align="center">
                              {chiqim && chiqim.vosita_nomi.nomi}
                            </TableCell>
                            <TableCell align="center">
                              {chiqim && chiqim.kirim_chiqim.unique_raqam}
                            </TableCell>
                            <TableCell align="center">
                              {chiqim && chiqim.vosita_miqdori}
                            </TableCell>
                          </TableRow>
                        )}
                        {kirim && (
                          <TableRow>
                            <TableCell align="center">
                              {kirim && kirim.vosita_turi.nomi}
                            </TableCell>
                            <TableCell align="center">
                              {kirim && kirim.vosita_nomi.nomi}
                            </TableCell>
                            <TableCell align="center">
                              {t("bola.kir")}
                            </TableCell>
                            <TableCell align="center">
                              {kirim && kirim.vosita_miqdori}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      { targetid && (
        <>
          <TableContainer style={{ marginTop: "24px" }} component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow style={{ backgroundColor: "white" }}>
                  <TableCell>{t("shifokor.number")}</TableCell>
                  <TableCell align="left">{t("input.pfl")}</TableCell>
                  <TableCell align="left">
                    {t("shifokor.alladd.name")} {t("shifokor.alladd.surname")}{" "}
                    {t("shifokor.alladd.otch")}
                  </TableCell>
                  <TableCell align="left">{t("shifokor.birthday")}</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="center">{t("shifokor.batafsil")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {send &&
                  send.map((row, index) => (
                    <TableRow>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">{row.JSHSHIR}</TableCell>
                      <TableCell align="left">{`${row.familiya} ${row.ism} ${row.otasining_ismi}`}</TableCell>
                      <TableCell align="left">{row.tugilgan_sana}</TableCell>
                      <TableCell align="center">{row.yosh}</TableCell>
                      <TableCell align="right">
                        <div className="button_modal button_modal_1">
                          <Link
                            Link
                            to={`/uzsinglechild/${row.id}`}
                            className="single_info"
                          >
                            <img
                              className="delete_icon"
                              src={l1}
                              alt="batafsil"
                            />
                          </Link>
                          <div className="seans_div">
                            <Button
                              className="seanslar_btn_muassasa"
                              onClick={() => Seansbemor(row.id)}
                            >
                              <img src={l4} alt="...." />
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {send[0] && !send[0].id ? (
            <p style={{ textAlign: "center", color: "red", fontSize: "22px" }}>
              {t("Ma'lumot Mavjud Emas")}
            </p>
          ) : null}
        </>
      )}
      <div className="modal_seans">
              <Koriklar
                open={seans}
                id={bemid}
                handleClose={handleSeansClose}
              />
      </div>
    </div>
  );
};
export default Uzmoni;
