import { get } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { request } from "../../../../../../../api/request";
import { Contextvalue } from "../../../../../../../context/context";
import img2 from "./../../../../../../././../assets/icon/chiqim.svg";
import img from "./../../../../../../././../assets/icon/l1.svg";

const Chiqim = ({ searchValue }) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const { setExit } = useContext(Contextvalue);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  useEffect(() => {
    request
      .get("/omborxona/ttb/kirim/chiqim/malumotlar", config)
      .then((data) => setData(data.data.chiqim));
  }, []);

  const handlerVision = (e) => {
    setExit(data.find((el) => el.chiqim.id === e));
  };
  return (
    <div style={{ height: "550px", overflowY: "scroll" }}>
      {data &&
        data
          .filter(
            (item) =>
              get(item, "vositalar[0].vosita_turi.nomi", "")
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              get(item, "vositalar[0].vosita_nomi.nomi", "")
                .toLowerCase()
                .includes(searchValue.toLowerCase())
          )
          .map((el) => {
            return (
              <div className="box_kirim">
                <div className="jami">
                  <img src={img2} alt="" />
                  {t("shifokor.jami")}:{" "}
                  {el.vositalar.map((item) => item.vosita_miqdori)}
                </div>
                <div className="box_xs">
                  <div className="btn">
                    <button>
                      {t("vosita.vositaturi")}:{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {get(
                          el,
                          "vositalar[0].vosita_turi.nomi",
                          t("Kiritilmagan")
                        )}
                      </span>
                    </button>
                    <button className="btn_gap">
                      {t("bildirishnoma.single.nomi")}:{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {get(
                          el,
                          "vositalar[0].vosita_nomi.nomi",
                          t("Kiritilmagan")
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="data">
                    <div className="time">
                      {el.chiqim.created_at.split("T")[0]} <br />{" "}
                      <span>
                        {el.chiqim.created_at.split("T")[1].slice(0, 5)}
                      </span>
                    </div>
                    <div className="icon">
                      <button onClick={() => handlerVision(el.chiqim.id)}>
                        <img src={img} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default Chiqim;
