import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { request } from "../../../../../../api/request";
import pdfDoc from "../../../../../../assets/icon/pdf_doc.svg";
import scrip from "../../../../../../assets/icon/scripka.svg";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Mohcreatbol = () => {

  const [opens1, setOpens1] = useState(false);
  const handleOpen1 = () => setOpens1(true);
  const handleClose1 = () => setOpens1(false);

  const [num, setNum] = useState({
    from: 0,
    to: 0,
  });
  const [num2, setNum2] = useState({
    from: 0,
    to: 0,
  });

  const [noti, setNoti] = useState(false);
  const [notificationn, setNotificationn] = useState({
    state: "",
    text: "",
  });
  const [numarr, setNumarr] = useState([]);
  const [numarr2, setNumarr2] = useState([]);
  const [down, setDown] = useState([]);
  const [vilId, setVilId] = useState();
  const [text, setText] = useState();
  const [date, setDate] = useState();
  const handleClick = () => {
    setNoti(true);
  };

  const handlenoti = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNoti(false);
  };
  function numAdd() {
    if (!isValidInterval(num, numarr)) return;
      setNumarr([...numarr, num]);
  }
  function numAdd2() {
    if (!isValidInterval(num2, numarr2)) return;
    setNumarr2([...numarr2, num2]);
  }

  const isValidInterval = ({from,to},arr) => {
     if (from >= to) {
       toast.error("Oy oralig'i noto'g'ri berilgan!");
       return false
     }
     if (
       arr.some((item) => item.from === from && item.to === to)
     ) {
       toast.error("Bu toifa oldin qo'shilgan!");
       return false
    }
    return true
  }

  function numDel(e) {
    let delarr = [];
    delarr.push(...numarr);
    delarr.splice(e, 1);
    setNumarr(delarr);
  }
  function numDel2(e) {
    let delarr = [];
    delarr.push(...numarr2);
    delarr.splice(e, 1);
    setNumarr2(delarr);
  }
  const token = window.localStorage.token;

  function addFile(e) {
    setDown([
      ...down,
      {
        filename: e.target.value,
        fil: e.target.files[0],
      },
    ]);
  }

  function delFile(index) {
    let sss = [];
    sss.push(...down);
    sss.splice(index, 1);
    setDown(sss);
  }

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const [vil, setVil] = useState([]);

  useEffect(() => {
    request
      .get("/user/respublika/viloyatlar/", config)
      .then((data) => setVil(data.data.muassasalar));
  }, []);
  const dateHandler = (e) => {
      let dateLimit = moment(Date.now()).add(1, "days").format().split("T")[0];
      if (dateLimit > e.target.value) {
        setDate(dateLimit);
        return toast.error(
          `Topshirish muddati ${dateLimit} dan oldin bo'lishi mumkin emas!`
        );
      }
     setDate(e.target.value);
  }
 
  const navigate = useNavigate();
  const UpdateHandler = (e) => {
    e.preventDefault();
    const numarrsend2 = [];
    numarr.map((item) => {
      numarrsend2.push(`${Number(item.from)}-${Number(item.to)}`);
    });
    console.log(numarrsend2, "lets");
    const numarrsend1 = [];
    numarr2.map((item) => {
      numarrsend1.push(`${Number(item.from)}-${Number(item.to)}`);
    });
    const formmdata = new FormData();
    formmdata.append("kimga", `[${vilId}]`);
    formmdata.append("yosh_toifa", JSON.stringify(numarrsend2));
    formmdata.append("oy_toifa", JSON.stringify(numarrsend1));
    formmdata.append("qoshimcha", text);
    formmdata.append("muddati", date);
    down[0].fil && formmdata.append("fayl", down[0]?.fil);
    // formmdata.append("oy_toifa", `["2-4", "6-9"]`)
    // formmdata.append("muddati", "2023-06-15")
    // formData.append("password", passwordRef.current.value)
    fetch(`https://admin-mpbt.ssv.uz/api/v1/bildirishnoma/respublika/`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
      body: formmdata,
    })
      .then(function (res) {
        if (res.status === 200) {
          setTimeout(() => {
            navigate("/mohbil");
          }, 1500);
          setNotificationn({
            state: "success",
            text: `Yuborildi`,
          });
        }
        if (res.status === 406) {
          handleOpen1();
        }
        console.log(res.data);
        handleClick(true);
      })
      .catch((err) => {
        console.log(err, "errr");
        alert("Yuborilmadi!");
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 500,
    bgcolor: "background.paper",
    background: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


  const { t } = useTranslation();
   
  return (
		<div className='sarflov'>
			<div className='sarflov_inner'>
				<Link to={"/mohbil"}>
					<Button startIcon={<ArrowBackIcon />} variant='contained'>
						{t("bildirishnoma.single.ortga")}
					</Button>
				</Link>
			</div>

			<form onSubmit={UpdateHandler}>
				<div className='sarflov_block'>
					<h4 className='sarflov_block_title'>{t("bildirishnoma.new.kimdankimga")}</h4>
					<div style={{ display: "flex" }} className='sarflov_block_inner'>
						<div
							style={{
								marginRight: "20px",
							}}
							className='sarflov_block_inner_div'
						>
							<h5 className='sarflov_block_inner_div_title'>{t("bildirishnoma.new.mud") }</h5>
							<div style={{ display: "flex" }}>
								<TextField
									id='outlined-basic'
									variant='outlined'
									name='kimga'
									required
									type={"date"}
									value={date}
									onChange={dateHandler}
								/>
							</div>
						</div>

						<div className='sarflov_block_inner_div'>
							<h5 className='sarflov_block_inner_div_title'>
								{t("bildirishnoma.vlssv")} 
							</h5>
							<FormControl fullWidth>
								<InputLabel id='demo-simple-select1'>{t("bildirishnoma.send")}</InputLabel>
								
								<Select label={"kimga"} labelId='demo-simple-select-label' id='demo-simple-select1' onChange={e => setVilId(e.target.value)}>
									
									<MenuItem value={JSON.stringify(vil.map(el => el.id))}>Barchasi</MenuItem>
									{vil?.map((item, key) => (
										<MenuItem id={item.id} value={item.id}>
											{item.nomi}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</div>
				</div>

				<div
					style={{
						display: "flex",
						justifyContent: "space-between", 
					}}
				>
					<div className='sarflov_block' style={{ width: "49%" }}>
						<div id='parent'>
							<div className='sarflov_block_comment_inner' style={{ margin: "0!important" }}>
								<h4 className='sarflov_block_title'>{t("bildirishnoma.new.fail")}</h4>
								<input disabled={down.length > 0} onChange={down.length < 1 && addFile} type='file' id='files' className='input_download' />
								<label htmlFor='files' className='all_download'>
									<img className='scrip_file' src={scrip} alt='' />
									{t("vosita.qosh")}
								</label>
							</div>
							<div className='sarflov_block_inner_div' id='child'>
								{down.map((item, index) =>
									down.length > 0 ? (
										<div key={index} className='sarflov_block_download_file'>
											<label className='input_tyle_download'>
												<img src={pdfDoc} alt='' className='label_img' />
												{item.filename}

												<div className='close_file'>
													<Button onClick={e => delFile(index)} startIcon={<CloseIcon />}></Button>
												</div>
											</label>
										</div>
									) : null
								)}
							</div>
						</div>
					</div>
					<Modal
						open={opens1}
						onClose={handleClose1}
						aria-labelledby='modal-modal-title'
						aria-describedby='modal-modal-description'
					>
						<Box sx={style}>
							<h5 className='err_text'>Xatolik yuz berdi</h5>
							<p className='err_title'>
								Bolalar yoshi va toifasini tanlashda xatolik yuz berdi iltimos quyidagi formula bo'yicha kiriting
							</p>
							<div className='yosh_primer'>
								<div className='age_num_block_inner'>
									<p>{1}</p>
									<p>-</p>
									<p>{2}</p>
									<p>-</p>
									<p>{t("vosita.oy")}</p>
								</div>
								<div className='age_num_block_inner'>
									<p>{3}</p>
									<p>-</p>
									<p>{5}</p>
									<p>-</p>
									<p>{t("vosita.oy")}</p>
								</div>
								<div className='age_num_block_inner'>
									<p>{6}</p>
									<p>-</p>
									<p>{10}</p>
									<p>-</p>
									<p>{t("vosita.oy")}</p>
								</div>
								<div className='age_num_block_inner'>
									<p>{11}</p>
									<p>-</p>
									<p>{15}</p>
									<p>-</p>
									<p>{t("vosita.oy")}</p>
								</div>
							</div>
							<div className='yosh_primer'>
								<div className='age_num_block_inner'>
									<p>{1}</p>
									<p>-</p>
									<p>{2}</p>
									<p>-</p>
									<p>{t("bola.yosh")}</p>
								</div>
								<div className='age_num_block_inner'>
									<p>{3}</p>
									<p>-</p>
									<p>{5}</p>
									<p>-</p>
									<p>{t("bola.yosh")}</p>
								</div>
								<div className='age_num_block_inner'>
									<p>{6}</p>
									<p>-</p>
									<p>{10}</p>
									<p>-</p>
									<p>{t("bola.yosh")}</p>
								</div>
								<div className='age_num_block_inner'>
									<p>{11}</p>
									<p>-</p>
									<p>{15}</p>
									<p>-</p>
									<p>{t("bola.yosh")}</p>
								</div>
							</div>
						</Box>
					</Modal>
					<div className='rol_ariza_bottom_div'>
						<div className='rol_ariza_bottom_div_inner'>
							<div className='rol_ariza_bottom_div_inner_block'>
								<h4 className='rol_ariza_bottom_title'>{t("bildirishnoma.new.bolalar")}</h4>
								<p className='yil_oy'>{t("bola.yosh")}</p>
								<div className='num_block_ariza' style={{ display: "flex", alignItems: "flex-end!important", gap: "1rem" }}>
									<div className='rol_ariza_textarea'>
										<p className='rol_num_ariza'>{t("bildirishnoma.new.dan")}</p>
										<div className='rol_ariza_number_left'>
											{num.from === 0 ? (
												<button disabled type='button' onClick={() => setNum({ ...num, from: num.from - 1 })} className='num_btn'>
													-
												</button>
											) : (
												<button onClick={() => setNum({ ...num, from: num.from - 1 })} type='button' className='num_btn'>
													-
												</button>
											)}
											<p className='num_title'>{num.from}</p>
											<button onClick={() => setNum({ ...num, from: num.from + 1 })} type='button' className='num_btn'>
												+
											</button>
										</div>
									</div>
									<div className='rol_ariza_textarea'>
										<p className='rol_num_ariza'>{t("bildirishnoma.new.gacha")}</p>
										<div
											className='rol_ariza_number_left'
											style={{
												display: "flex",
												alignItems: "center",
												gap: "1rem",
											}}
										>
											{num.to === 0 ? (
												<button disabled onClick={() => setNum({ ...num, to: num.to - 1 })} type='button' className='num_btn'>
													-
												</button>
											) : (
												<button onClick={() => setNum({ ...num, to: num.to - 1 })} type='button' className='num_btn'>
													-
												</button>
											)}
											<p className='num_title'>{num.to}</p>
											<button onClick={() => setNum({ ...num, to: num.to + 1 })} type='button' className='num_btn'>
												+
											</button>
										</div>
									</div>
									<div className='rol_ariza_textarea'>
										<p className='rol_num_ariza'></p>
										<div className=''>
											<Button
												type='button'
												className='num_btn'
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
												onClick={numAdd}
												variant='contained'
											>
												<AddIcon />
											</Button>
										</div>
									</div>
								</div>
								<div className='age_num_block' style={{ display: "flex", alignItems: "flex-end", gap: "1rem" }}>
									{numarr.map((item, index) => (
										<div className='age_num_block_inner'>
											<p>{item.from}</p>
											<p>-</p>
											<p>{item.to}</p>
											<button onClick={e => numDel(index)}>x</button>
										</div>
									))}
								</div>
							</div>
							<div className='rol_ariza_bottom_div_inner_block'>
								<p className='yil_oy'>{t("vosita.oy")}</p>
								<div className='num_block_ariza' style={{ display: "flex", alignItems: "flex-end!important", gap: "1rem" }}>
									<div className='rol_ariza_textarea'>
										<p className='rol_num_ariza'>{t("bildirishnoma.new.dan")}</p>
										<div className='rol_ariza_number_left'>
											{num2.from === 0 ? (
												<button
													disabled
													onClick={() => setNum2({ ...num2, from: num2.from - 1 })}
													type='button'
													className='num_btn'
												>
													-
												</button>
											) : (
												<button onClick={() => setNum2({ ...num2, from: num2.from - 1 })} type='button' className='num_btn'>
													-
												</button>
											)}
											<p className='num_title'>{num2.from}</p>
											<button onClick={() => setNum2({ ...num2, from: num2.from + 1 })} type='button' className='num_btn'>
												+
											</button>
										</div>
									</div>
									<div className='rol_ariza_textarea'>
										<p className='rol_num_ariza'>{t("bildirishnoma.new.gacha")}</p>
										<div className='rol_ariza_number_left'>
											{num2.to === 0 ? (
												<button disabled onClick={() => setNum2({ ...num2, to: num2.to - 1 })} type='button' className='num_btn'>
													-
												</button>
											) : (
												<button onClick={() => setNum2({ ...num2, to: num2.to - 1 })} type='button' className='num_btn'>
													-
												</button>
											)}
											<p className='num_title'>{num2.to}</p>
											<button onClick={() => setNum2({ ...num2, to: num2.to + 1 })} type='button' className='num_btn'>
												+
											</button>
										</div>
									</div>
									<div className='rol_ariza_textarea'>
										<p className='rol_num_ariza'></p>
										<div className=''>
											<Button
												type='button'
												className='num_btn'
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
												onClick={numAdd2}
												variant='contained'
											>
												<AddIcon />
											</Button>
										</div>
									</div>
								</div>
								<div className='age_num_block'>
									{numarr2.map((item, index) => (
										<div className='age_num_block_inner'>
											<p>{item.from}</p>
											<p>-</p>
											<p>{item.to}</p>
											<button onClick={e => numDel2(index)}>x</button>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='sarflov_comment'>
					<div className='sarflov_block_comment'>
						<h4 className='sarflov_block_title'>{t("Qo’shimcha ma’lumot")}</h4>
						<div className='sarflov_block_inner_div1'>
							<TextareaAutosize
								style={{ resize: "none" }}
								aria-label='minimum height'
								minRows={3}
								placeholder='...'
								name='qoshimcha_matn'
								onChange={e => setText(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<footer className='site-footer'>
					<div style={{ textAlign: "center" }}>
						<Button
							style={{
								borderRadius: "12px",
								backgrounColor: "#1464C0",
								width: "448px",
							}}
							type='submit'
							variant='contained'
							color='primary'
							size='large'
							startIcon={<SendIcon />}
						>
							{t("input.otp")}
						</Button>
					</div>
				</footer>
			</form>
			<Snackbar
				Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={noti}
				autoHideDuration={6000}
				onClose={handlenoti}
			>
				<Alert
					Alert
					onClose={handlenoti}
					severity={notificationn.state}
					sx={{
						width: "100%",
					}}
				>
					{notificationn.text}
				</Alert>
			</Snackbar>
		</div>
	);
};
export default Mohcreatbol;
