import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
	Alert,
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { request } from "../../../api/request";
import l1 from "../../../assets/icon/l1.svg";
import "./marizalar.scss";

export default function Ariza() {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams({ tab: 1 });

	const [data, setData] = useState([]);
	const [data2, setData2] = useState([]);
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState("1");
	const [application, setApllication] = useState();

	const handleChange2 = (event, newValue) => {
		setValue(newValue);
		navigate(`${location.pathname}?tab=${newValue}`);
	};

	const [notificationn, setNotificationn] = useState({
		state: "",
		text: "",
	});

	const Create = id => {
		const formmdata = new FormData();
		formmdata.append("id", id);
		formmdata.append("status", `O'qildi`);

		request
			.put(`/ariza/moh/`, formmdata, config)
			.then(function (res) {
				console.log(res.data);
			})
			.catch(function (err) {
				console.log(err);
			});
  };
  
  function statusOfData2(status){
    if (status === 'Yaratildi') {
      return t()
    }
  }

	const [checkboxs, setCheckboxs] = useState(false);
	const token = window.localStorage.token;
	const formData = new FormData();
	formData.append("token", token);
	const [hud, setHud] = useState([]);
	const [checkblock, setCheckblock] = useState([]);
	const [applications, setApplications] = useState([]);

	function addColumn(elId, ariza_id) {
		if (!application.includes(ariza_id) || application.length === 0) {
			setApplications(prev => [...prev, ariza_id]);
		}
		if (checkblock?.length) {
			let ss = checkblock.filter(elem => elem == elId);
			if (ss && ss.length > 0) {
				setCheckblock(checkblock.filter(item => item != elId));
			} else {
				setCheckblock([...checkblock, elId]);
			}
		} else {
			setCheckblock([...checkblock, elId]);
		}
		console.log(checkblock);
	}

	function Send() {
		if (!applications.length) {
			toast.error("Xatolik! Arizalar mavjud emas");
			return;
		}
		const formmdata = new FormData();
		formmdata.append("arizalar", applications);
		request
			.post("/ariza/moh/", formmdata, config)
			.then(res => {
				console.log(res);
				toast.success("Ariza Birlashdi!");
				setTimeout(() => {
					navigate("/barchaArizalar?tab=2");
					window.location.reload();
				}, 1200);
			})
			.catch(err => {
				toast.error("Ariza Birlashmadi!");
				toast.error(err.response.data.error);
				console.log(err);
			});
	}
	const classes = {
		table: {
			minWidth: 700,
		},
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: "white",

			// boxShadow: theme.shadows[5],
			padding: "10px",
			width: "80%",
			margin: "30px auto 0 auto",
			borderRadius: "12px",
		},
		formControl: {
			margin: "1px",
			minWidth: 120,
		},
		selectEmpty: {
			marginTop: "5px",
		},
		button: {
			padding: "8px",
			borderRadius: "12px",
		},
	};
	const [vilId, setVilId] = useState(0);

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
  const datas = hud[vilId];


  const fetchData = () => {
    	request
				.get(datas?.viloyat_id ? `/ariza/moh/?viloyat=${datas?.viloyat_id}` : "/ariza/moh/", config)
				.then(data => setData(data.data.data))
				.catch(err => console.log(err));
  }
	useEffect(() => {
    fetchData()
 
  }, [datas]);
  useEffect(() => {
    console.log(vilId)
           if (vilId === "all") {
							fetchData();
						}
  }, [vilId]);

	useEffect(() => {
		request
			.get(`/hududlar/`, config)
			.then(data => setHud(data.data))
			.catch(err => console.log(err));

		request
			.get("/ariza/moh/birlashtirish/", config)
			.then(data => setData2(data.data.data))
			.catch(err => console.log(err));

		setValue(searchParams.get("tab") ? searchParams.get("tab") : "1");
		request
			.get("/ariza/moh/yaratish/", config)
			.then(data => setApllication(data.data.data))
			.catch(err => console.log(err));
	}, []);

	let statusCount = 0;
	data?.forEach(item => {
		if (item.status !== "O'qildi") {
			statusCount++;
		}
	});
	const tab = searchParams.get("tab");
	useEffect(() => {
		if (+tab !== 1) setCheckboxs(false);
	}, [tab]);
	return (
		<div className='marizalar'>
			<div className='ariza_top'>
				<Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={"space-between"} marginTop={"20px"}>
					<h4 className='ariza_top_title'>
						{t("bildirishnoma.allariza")}: {value === "1" ? data?.length : value === "2" ? data2?.length : application?.length}
					</h4>
					{
					value==3 ? 	<Button onClick={() => navigate('/barchaArizalar/create-application')} variant='contained' color="primary">
						{t("+ UZMEDIMPEKSGA ARIZA YUBORISH")}
					</Button> : null}
				</Stack>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					paddingBottom: "24px",
				}}
			>
				{checkboxs === false && +tab === 1 ? (
					<div className='filter_region'>
						<FormControl fullWidth>
							<InputLabel id='demo-simple-select-label'>{t("sbola.hudud")}</InputLabel>
							<Select
								label={t("hudud")}
								labelId='demo-simple-select-label'
								id='demo-simple-select'
								onChange={e => setVilId(e.target.value)}
							>
								<MenuItem value={"all"}>{t("bola.all")}</MenuItem>
								{Object.keys(hud).map((item, index) => (
									<MenuItem value={item}>{item}</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				) : null}

				<div className='create_ariza_btn'>
					{checkboxs === true && +tab === 1 ? (
						<div>
							<Button
								style={{ marginRight: "15px" }}
								onClick={() => {
									setCheckboxs(prev => !prev);
									setCheckblock([]);
									setApplications([]);
								}}
								variant='green'
								startIcon={<CloseIcon />}
							>
								{t("bildirishnoma.single.bekor")}
							</Button>
						</div>
					) : null}
					{checkboxs === false && +tab === 1 ? (
						<Button onClick={() => setCheckboxs(!checkboxs)} variant='contained' startIcon={<AddIcon />}>
							{t("vosita.birlash")}
						</Button>
					) : +tab === 1 ? (
						<Button onClick={() => Send()} variant='contained' startIcon={<CheckIcon />}>
							{t("vosita.birlash")}
						</Button>
					) : null}
				</div>
			</div>
			<div className='ariza_bottom'>
				<div className='ariza_bottom_bottom'>
					<TableContainer component={Paper}>
						<Box sx={{ width: "100%", typography: "body1" }}>
							<TabContext value={value}>
								<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
									<TabList onChange={handleChange2}>
										<Tab className='bgc-white' label={`${t("input.qabul")}`} value='1' />

										<div>
											{!!statusCount && (
												<span
													style={{
														fontWeight: "bold",
														background: "blue",
														borderRadius: "50%",
														width: "22px",
														height: "22px",
														color: "white",
														textAlign: "center",
														paddingTop: "2px",
														fontSize: "14px",
													}}
												>
													{statusCount}
												</span>
											)}
										</div>

										<Tab className='bgc-white' label={t("vosita.bir")} value='2' />
										<Tab className='bgc-white' label={t("input.yubor")} value='3' />
									</TabList>
								</Box>
								<TabPanel label={"Erkin Xabornoma"} value='1'>
									<Table className={classes.table} aria-label='customized table'>
										<TableHead>
											<TableRow style={{ backgroundColor: "white" }}>
												<TableCell>{t("bildirishnoma.single.soni")}</TableCell>
												<TableCell align='left'>ID</TableCell>
												<TableCell align='left'>{t("sidebar.li10")} ID</TableCell>
												<TableCell align='left'>{t("bildirishnoma.single.kimdan")}</TableCell>

												<TableCell align='left'>{t("bildirishnoma.sana")}</TableCell>
												<TableCell align='center'>{t("bildirishnoma.single.status")}</TableCell>
												<TableCell align='center'>{t("bildirishnoma.harakat")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data?.map((el, index) => {
												return (
													<TableRow>
														<TableCell align='center'>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																	gap: "10px",
																	// justifyContent: "space-between",
																	cursor: "pointer",
																}}
															>
																{checkboxs === true ? (
																	<input
																		className='check_box_inp_inner'
																		type='checkbox'
																		style={{
																			margin: 0,
																			cursor: "pointer",
																		}}
																		name=''
																		checked={checkblock?.includes(el.id)}
																		onClick={e => {
																			console.log(el.id);
																			addColumn(el.id, el.ariza_id);
																		}}
																	/>
																) : null}
																<span>{index + 1}</span>
															</div>
														</TableCell>
														<TableCell align='left'>{el.id}</TableCell>
														<TableCell align='left'>{el.ariza_id}</TableCell>
														<TableCell align='left'>{el.kimdan}</TableCell>
														<TableCell align='left'>
															{new Date(el.date).getFullYear()}-{new Date(el.date).getMonth() + 1}-{new Date(el.date).getDate()}
														</TableCell>
														<TableCell align='center'>
															<button className={el.status !== "O'qildi" ? "status_btn" : "status_btn--not"}>
																{el.status !== "O'qildi" ? t("bildirishnoma.arstatus.yangi") : t("vosita.oqil")}
															</button>
														</TableCell>
														<TableCell align='center'>
															<div className='button_modal button_modal_1'>
																<Button
																	onClick={() => {
																		navigate(`/ariza/${el.id}/com`);
																		Create(el.id);
																	}}
																	className='single_info'
																>
																	<img className='delete_icon' src={l1} alt='batafsil' />
																</Button>
															</div>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TabPanel>
								<TabPanel label={t("vosita.bir")} value='2'>
									<Table className={classes.table} aria-label='customized table'>
										<TableHead>
											<TableRow
												style={{
													backgroundColor: "white",
													display: "grid",
													width: "100%",
													gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
												}}
											>
												<TableCell>{t("bildirishnoma.single.soni")}</TableCell>
												<TableCell align='left'>ID</TableCell>
												<TableCell align='left'>{t("bildirishnoma.sana")}</TableCell>
												<TableCell align='center'>{t("bildirishnoma.harakat")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data2?.map((el, index) => {
												return (
													<TableRow
														key={index}
														style={{
															display: "grid",
															width: "100%",
															gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
														}}
													>
														<TableCell
															align='center'
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																width: "60px",
																cursor: "pointer",
															}}
														>
															<p>{index + 1}</p>

															<div className='ariza_bgc'></div>
														</TableCell>
														<TableCell align='left'>{el.id}</TableCell>
														<TableCell align='left'>
															{new Date(el.date).getFullYear()}-{new Date(el.date).getMonth() + 1}-{new Date(el.date).getDate()}
														</TableCell>
														<TableCell align='center'>
															<div className='button_modal button_modal_1'>
																<button
																	onClick={() => {
																		navigate(`/birmoh/${el.id}`);
																	}}
																	className='single_info'
																>
																	<img className='delete_icon' src={l1} alt='batafsil' />
																</button>
															</div>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TabPanel>
								<TabPanel label={t("vosita.bir")} value='3'>
									<Table className={classes.table} aria-label='customized table'>
										<TableHead>
											<TableRow style={{ backgroundColor: "white" }}>
												<TableCell>{t("bildirishnoma.single.soni")}</TableCell>
												<TableCell align='left'>ID</TableCell>

												<TableCell align='left'>{t("bildirishnoma.sana")}</TableCell>
												<TableCell align='center'>{t("bildirishnoma.single.status")}</TableCell>
												<TableCell align='center'>{t("bildirishnoma.harakat")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{application?.map((el, index) => {
												return (
													<TableRow>
														<TableCell
															align='center'
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																width: "60px",
																cursor: "pointer",
															}}
														>
															<p>{index + 1}</p>

															<div className='ariza_bgc'></div>
														</TableCell>
														<TableCell align='left'>{el.id}</TableCell>

														<TableCell align='left'>
															{new Date(el.date).getFullYear()}-{new Date(el.date).getMonth() + 1}-{new Date(el.date).getDate()}
														</TableCell>
														<TableCell align='center'>
															<button className={el.status === "Yuborildi" ? "status_btn" : "status_btn--not"}>
																{el.status === "Yuborildi" ? t("vosita.yubor") : t("vosita.oqil")}
															</button>
														</TableCell>

														<TableCell align='center'>
															<div className='button_modal button_modal_1'>
																<button
																	onClick={() => {
																		navigate(`/ariza/${el.id}/send`);
																	}}
																	className='single_info'
																>
																	<img className='delete_icon' src={l1} alt='batafsil' />
																</button>
															</div>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TabPanel>
							</TabContext>
						</Box>
					</TableContainer>
				</div>
			</div>
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
					<Alert
						onClose={() => setOpen(false)}
						severity={notificationn.state}
						style={{ position: "fixed", top: "6rem", right: "3rem" }}
					>
						{notificationn.text}
					</Alert>
				</Snackbar>
			</Stack>
		</div>
	);
}
