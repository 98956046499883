import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DescriptionIcon from "@mui/icons-material/Description";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { request } from "../../../api/request";
import Loading from "../../../components/loading/loading";
import "./singlebemor.scss";
const SingleArxiv = () => {
  const { t } = useTranslation();
  const token = window.localStorage.token;

  const [person, setPerson] = useState([]);
  const [shifokorlar, setShifokorlar] = useState([]);
  const [delebemor, setDeleBemor] = useState({
    isFetched: false,
    data: {},
    error: null,
  });

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const params = useParams();

  const { id } = useParams();

  useEffect(() => {
    request
      .get(`/muassasa/arxiv/`, config)
      .then(function (res) {
        setDeleBemor({
          isFetched: true,
          data: res.data,
          error: false,
        });
        console.log(res.data);
        setPerson(
          res.data.bolalar.filter((item) => +item.id === +params.id)[0]
        );
        setLoeder(false);
        setShifokorlar(res.data);
      })
      .catch(function (err) {
        setDeleBemor({
          isFetched: false,
          data: [],
          error: err,
        });
      });
  }, [params.id]);

  const [loader, setLoeder] = useState(true);
  const [bemorid, setBemorId] = useState([]);

  function Iddiagnoz(id) {
    const formmdata = new FormData();
    formmdata.append("token", token);
    request
      .post(`/diagnoz/${id}`, formmdata)
      .then(function (res) {
        setBemorId({ isFetched: true, data: res.data, error: false });
        setLoeder(false);
      })
      .then(() => setLoeder(false))
      .catch(function (err) {
        setBemorId({ isFetched: false, data: [], error: err });
      });
  }

  if (loader) return <Loading />;
  return (
    <div className="singlebemor">
      <div className="singlebemor_top">
        <div className="singlebemor_top_left">
          <Link to={"/arxiv"}>
            <Button startIcon={<ArrowBackIcon />} variant="contained">
              {t("bildirishnoma.single.ortga")}
            </Button>
          </Link>
        </div>
        {/* <div className="singlebemor_top_right">
          <Button startIcon={<DescriptionIcon />} variant="contained">
            <CSVLink style={{color:"#fff"}} data={[person]} separator=";" >
                {t("bola.excel")}
            </CSVLink>
          </Button>
        </div> */}
      </div>
      <div className="singlebemor_block">
        <div className="singlebemor_block_left">
          <div className="singlebemor_block_info">
            <h4 className="singlebemor_block_info_title">{t("sbola.sh")}</h4>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">
                {t("shifokor.alladd.surname")}
              </h5>
              <h5 className="singlebemor_block_info_desc">{person.familiya}</h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">
                {t("shifokor.alladd.name")}
              </h5>
              <h5 className="singlebemor_block_info_desc">{person.ism}</h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">
                {t("shifokor.alladd.otch")}
              </h5>
              <h5 className="singlebemor_block_info_desc">
                {person.otasining_ismi}
              </h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">{t("input.pfl")}</h5>
              <h5 className="singlebemor_block_info_desc">{person.JSHSHIR}</h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">
                {t("shifokor.birthday")}
              </h5>
              <h5 className="singlebemor_block_info_desc">
                {person.tugilgan_sana}
              </h5>
            </div>

            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">{t("bola.guruh")}</h5>
              <h5 className="singlebemor_block_info_desc">
                {person.qon_guruhi == 1
                  ? "AB(IV)Rh+"
                  : person.qon_guruhi == 2
                  ? "AB(IV)Rh-"
                  : person.qon_guruhi == 3
                  ? "A(II)Rh+"
                  : person.qon_guruhi == 4
                  ? "A(II)Rh-"
                  : person.qon_guruhi == 5
                  ? "B(III)Rh+"
                  : person.qon_guruhi == 6
                  ? "B(III)Rh-"
                  : person.qon_guruhi == 7
                  ? "O(I)Rh+"
                  : person.qon_guruhi == 8
                  ? "O(I)Rh-"
                  : t("bola.kir")}
              </h5>
            </div>
            {/* <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">Kasbi</h5>
              <h5 className="singlebemor_block_info_desc">
                {person.ijtimoiy_maqom}
              </h5>
            </div> */}
          </div>

          {/* <div className="singlebemor_block_info">
            <h4 className="singlebemor_block_info_title">Bemor haqida</h4>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">Status</h5>
              <h5 className="singlebemor_block_info_desc">{person.status}</h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">
                Bemorning kassaligi
              </h5>
              <h5 className="singlebemor_block_info_desc">Umarov</h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">Bemorning holati</h5>
              <h5 className="singlebemor_block_info_desc">
                {person.bemor_holati}
              </h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">Diagnoz</h5>
              <div className="singlebemor_block_info_desc">
                <Button
                  className="popup"
                  onMouseEnter={() => Iddiagnoz(person.diagnoz_id)}
                  startIcon={<TroubleshootIcon />}
                >
                  <div className="popup_inner">
                    <h6>Asosiy</h6>
                    <p>{bemorid?.data?.asosoiy}</p>
                    <br />
                    <h6>Fon</h6>
                    <p>{bemorid?.data?.fon}</p>
                    <br />
                    <h6>Bog'liq</h6>
                    <p>{bemorid?.data?.bogliq}</p>
                    <br />
                    <h6>Raqobat</h6>
                    <p>{bemorid?.data?.raqobat}</p>
                    <br />
                    <h6>Asorati</h6>
                    <p>{bemorid?.data?.asorati}</p>
                  </div>
                </Button>
              </div>
            </div>
          </div> */}

          <div className="singlebemor_block_info">
            <h4 className="singlebemor_block_info_title">
              {t("sbola.contact")}
            </h4>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">
                {t("shifokor.tel")}
              </h5>
              <h5 className="singlebemor_block_info_desc">
                {person.tel_raqami}
              </h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">
                {t("input.qosh1")}
              </h5>
              <h5 className="singlebemor_block_info_desc">
                {person?.qoshimcha_raqam}
              </h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">
                {t("shifokor.alladd.male")}
              </h5>
              <h5 className="singlebemor_block_info_desc">{person.jinsi}</h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">
                {t("sbola.manzil")}
              </h5>
              <h5 className="singlebemor_block_info_desc">
                {person.manzil_tumani} {person.manzil_mahalla}{" "}
                {person.manzil_uyi}
              </h5>
            </div>
          </div>

          <div className="singlebemor_block_info">
            <h4 className="singlebemor_block_info_title">{t("sbola.g1")}</h4>

            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">{t("sbola.g2")}</h5>
              <h5 className="singlebemor_block_info_desc">
                {person.passport_seriya_va_raqami}
              </h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">{t("bola.who")}</h5>
              <h5 className="singlebemor_block_info_desc">
                {person.passport_qayerdan_kim_tomonidan_berilgan}
              </h5>
            </div>
            {/* <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">
                Bemorning pasporti
              </h5>
              <h5 className="singlebemor_block_info_desc">
                <a
                  className="pasport_file"
                  download
                  href={person.bemor_passporti}
                >
                  yuklash
                </a>
              </h5>
            </div> */}
          </div>
        </div>

        <div className="singlebemor_block_right">
          <div className="singlebemor_block_info">
            <h4 className="singlebemor_block_info_title">{t("sbola.b1")}</h4>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">ID</h5>
              <h5 className="singlebemor_block_info_desc">{person.id}</h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">{t("bola.shifo")}</h5>
              <h5 className="singlebemor_block_info_desc">{`${person.biriktirilgan_shifokor.ismi} ${person.biriktirilgan_shifokor.familiyasi}`}</h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">{t("bola.ms")}</h5>
              <h5 className="singlebemor_block_info_desc">
                {person.biriktirilgan_muassasa.nomi}
              </h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">{t("sbola.izoh")}</h5>
              <h5
                className="singlebemor_block_info_desc"
                style={{ color: "green" }}
              >
                {person.izoh}
              </h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">{t("bola.rxt")}</h5>
              <h5 className="singlebemor_block_info_desc">
                {person.royxatga_olingan_sana}
              </h5>
            </div>
          </div>
          <div className="singlebemor_block_info">
            <h4 className="singlebemor_block_info_title">{t("sbola.onot")}</h4>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">{t("sbola.ot1")}</h5>
              <h5 className="singlebemor_block_info_desc">
                {person.otasi.ism} {person.otasi.familiya}{" "}
                {person.otasi.otasining_ismi}
              </h5>
            </div>
            <div className="singlebemor_block_info_inner">
              <h5 className="singlebemor_block_info_desc">{t("sbola.on1")}</h5>
              <h5 className="singlebemor_block_info_desc">
                {person.onasi.ism} {person.onasi.familiya} {person.onasi.ism}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleArxiv;
