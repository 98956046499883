import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Autocomplete,
  Button,
  FormControl,
  TextareaAutosize,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import useGet from "hooks/useGet";
import usePost from "hooks/usePost";
import { get } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./ariza.scss";

const Erkinobl = () => {
  let todayDate = moment().add(1,"days").format().split("T")[0];
  const navigate = useNavigate();
  const [data, setData] = useState({
    whom: [],
    district: {},
    text: "",
    date: todayDate,
    addition: "",
    file: null,
  });

  const { t } = useTranslation();
  const { type } = useParams();

  const {
    data: { muassasalar: districts = [] },
  } = useGet({
    url: `/user/viloyat/tumanlar/`,
  });
  const {
    data: { muassasalar: points = [] },
  } = useGet({
    url: `/user/viloyat/muassasalar/${get(data, "district.id", null)
      ? `?tuman_id=${get(data, "district.id", null)}`
      : ""
      }`,
  });

  const handleChange = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const { mutate } = usePost();

  const onSuccess = () => {
    toast.success("Bildirishnoma yuborildi");
    navigate("/bildirishnoma_viloyat");
  };
  const onError = () => {
    toast.error("Bildirishnomani yuborib bo'lmad,");
  };

  const handleSubmit = () => {
    if (data.whom.length === 0) toast.warning("Yuboriluvchi Muassasa Tanlanmadi!");
    const formdata = new FormData();
    formdata.append(
      "kimga",
      get(data, "whom", []).map((item) => item.id)
    );
    formdata.append("fayl", get(data, "file"));
    formdata.append("qoshimcha", get(data, "addition"));
    formdata.append("muddati", get(data, "date"));
    formdata.append("text", get(data, "text"));
    if (type === "oilaviy") {
      mutate({
        url: "/bildirishnoma/erkin/VSSBdanLPUga/",
        data: formdata,
        onSuccess,
        onError,
      });
    } else {
      mutate({
        url: "/bildirishnoma/erkin/VSSBdanTTBga/",
        data: formdata,
        onSuccess,
        onError,
      });
    }
  };
if (todayDate > data.date) {
  toast.error("Belgilangan Muddatdan Ortga Surib Bo'llmaydi!");
  setData(
    {
      whom: data.whom,
      district: data.district,
      text: data.text,
      date: todayDate,
      addition: data.addition,
      file: data.file,
    }
  )
}

  return (
    <div className="rol_ariza">
      <div className="rol_ariza_top">
        <Button variant="contained" onClick={() => navigate(-1)}>
          {t("bildirishnoma.new.ortga")}
        </Button>
        <h3 className="rol_ariza_top_title">
          {t("bildirishnoma.new.yaratish")}
        </h3>
      </div>
      <div className="grid">
        <div className="rol_ariza_bottom_top">
          <h4 className="rol_ariza_bottom_title">
            {t("bildirishnoma.new.kimdankimga")}
          </h4>
          <div className="grid grid-cols-3 gap-10">
            <div className="rol_ariza_bottom_block">
              <p className="rol_ariza_bottom_block_desc">
                {t("bildirishnoma.new.kimdan")}
              </p>
              <TextField
                className="w-full"
                id="outlined-basic"
                onChange={(e) => handleChange("text", e.target.value)}
                label="Ism sharif"
                variant="outlined"
              />
            </div>
            <div className="rol_ariza_bottom_block">
              <p className="rol_ariza_bottom_block_desc">{t("pdf.rmo")}</p>
              <Autocomplete
                multiple={type === "tuman"}
                options={districts}
                onChange={(_, value) =>
                  handleChange(type === "tuman" ? "whom" : "district", value)
                }
                getOptionLabel={(option) => option.nomi || ""}
                renderInput={(params) => (
                  <TextField {...params} label={t("pdf.rmo")} placeholder={t("bola.add")} />
                )}
              />
            </div>
            {type === "oilaviy" && (
              <div className="rol_ariza_bottom_block">
                <p className="rol_ariza_bottom_block_desc">{t("pdf.oshp")}</p>
                <Autocomplete
                  multiple
                  options={points}
                  onChange={(_, value) => handleChange("whom", value)}
                  getOptionLabel={(option) => option.nomi || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("pdf.oshp")}
                      placeholder={t("bola.add")}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
        <div className="rol_ariza_flex">
          <div className="rol_ariza_bottom_div">
            <div className="rol_ariza_bottom_div_inner">
              <div className="rol_ariza_bottom_div_inner_block">
                <h4 className="rol_ariza_bottom_title">
                  {t("bildirishnoma.new.boshqa")}
                </h4>
                <div className="rol_ariza_bottom_div_t7">
                  <div className="rol_ariza_bottom_div_inner_block_select">
                    <p className="rol_ariza_bottom_block_desc">
                      {t("bildirishnoma.new.turi")}
                    </p>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <TextField variant="outlined" value={"Erkin xabar"} />
                      </FormControl>
                    </Box>
                  </div>
                  <div className="rol_ariza_bottom_div_inner_block_select">
                    <p className="rol_ariza_bottom_block_desc">
                      {t("bildirishnoma.new.vazifasi")}
                    </p>
                    <TextField
                      type="date"
                      onChange={(e) => handleChange("date", e.target.value)}
                      id="outlined-basic"
                      variant="outlined"
                      name="muddati"
                      value={data.date}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="rol_ariza_bottom_div_inner">
              <div className="rol_ariza_bottom_div_inner_block">
                <h4 className="rol_ariza_bottom_title">
                  {t("bildirishnoma.new.inf")}
                </h4>
                <div className="rol_ariza_textarea">
                  <TextareaAutosize
                    style={{ "maxWidth": "100%" }}
                    onChange={(e) => handleChange("addition", e.target.value)}
                    placeholder={t("bildirishnoma.new.infP")}
                  />
                </div>
              </div>
            </div>
            <Button onClick={handleSubmit} variant="contained">
              {t("input.otp")}
            </Button>
          </div>
          <div className="rol_ariza_bottom_div">
            <div className="rol_ariza_bottom_div_inner">
              <div className="sarflov_top_blocks">
                <h4 className="sarflov_block_title">
                  {t("bildirishnoma.new.fail")}
                </h4>

                {get(data, "file") ? (
                  <Button
                    className="delets_icons_file"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => handleChange("file", null)}
                    style={{ zIndex: 0 }}
                    variant="contained"
                    type="button"
                  >
                    {t("bildirishnoma.new.del")}
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <input
                onChange={(e) => handleChange("file", e.target.files[0])}
                type="file"
                id="files"
                className="file_add_input"
                name="fayl"
              />
              <label className="download_label" htmlFor="files">
                <div className="files_block_title">
                  <p className="files_add_title">
                    {get(data, "file")
                      ? t("bildirishnoma.new.failinf1")
                      : t("bildirishnoma.new.failinf")}
                  </p>
                  <span className="files_add_span">
                    {get(data, "file") ? "" : ""}
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Erkinobl;
