import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Fade,
  InputLabel,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { get } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { request } from "../../../api/request";
import l1 from "../../../assets/icon/l1.svg";
import Loading from "../../../components/loading/loading";
import Error from "../../../Error/Error";
import "./shifokor.scss";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";

const Shifokor = () => {
  const { t } = useTranslation();
  const token = window.localStorage.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [noti, setNoti] = React.useState(false);
  const [notificationn, setNotificationn] = React.useState({
    state: "",
    text: "",
  });

  const handleClick = () => {
    setNoti(true);
  };

  const handlenoti = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNoti(false);
  };

  const formData = new FormData();
  formData.append("token", token);

  const [input, setInput] = useState({
    qoshimcha_fayl: "",
    tugilgan_sana: "",
  });

  const pnflChange = (e) => {
    if (e.length == 14) {
      const body = new FormData();
      body.append("JSHSHIR", e);
      body.append("token", token);
      request
        .get(`https://admin-mpbt.ssv.uz/api/v1/muassasa/shifokor/${e}/`, config)
        .then((data) =>
          setInput({
            JSHSHIR: data.data.data.JSHSHIR,
            ism: data.data.data.ism,
            familiya: data.data.data.familiya,
            otasining_ismi: data.data.data.otasini_ismi,
            tugilgan_sana: data.data.data.tugilgan_sana,
            pasport_raqami: data.data.data.pasport_raqami,
            passport_seriya: data.data.data.pasport_seriya,
            lavozimi: data.data.data.lavozimi,
            tel_raqami: data.data.data.telefon_raqami,
            tashkiloti: data.data.data.tashkiloti,
            jinsi: data.data.data.jinsi,
            qoshimcha_fayl: "",
          })
        )
        .catch((error) => {
          alert(get(error, "response.data"));
        });
    }
  };
  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      setInput({
        ...input,
        [e.target.name]: String(e.target.checked),
      });
    } else {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    }
  };

  const classes = {
    table: {
      minWidth: 700,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      border: "2px solid #000",
      padding: "50px",
      width: "80%",
      overflow: "auto",
      maxHeight: "95%",
      margin: "30px auto 0 auto",
    },
    formControl: {
      margin: "1px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
  };

  const [loader, setLoeder] = useState(true);

  const [open2, setOpen2] = React.useState(false);
  const [ides, setides] = useState(null);

  const handleOpen2 = (e) => {
    setides(e);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [del, setDel] = React.useState(false);

  const deleteClose = () => {
    setDel(false);
  };

  //  const [person, setPerson] = useState([]);
  const [bemorIdpro, setBemorIdPro] = useState([]);
  const [shifokorlar, setShifokorlar] = useState([]);

  React.useEffect(() => {
    request
      .get(`/muassasa/shifokor/`, config)
      .then(function (res) {
        setBemorIdPro({
          isFetched: true,
          data: res.data,
          error: false,
        });
        //  setPerson(res.data.bemorlar);
        setShifokorlar(res.data.shifokorlar);
        setLoeder(false);
      })
      .catch(function (err) {
        setBemorIdPro({
          isFetched: false,
          data: [],
          error: true,
        });
        setLoeder(false);
        throw err;
      });
  }, [loader]);

  function Deletes() {
    const body = new FormData();
    body.append("id", ides);
    // body.append("izoh",izohs)
    request
      .post(`/muassasa/delete/shifokor/`, body, config)
      .then(function (res) {
        setNotificationn({
          state: "success",
          text: `Shifokor o'chirildi`,
        });
        console.log(res.data);
        setLoeder(true);
        handleClick(true);
      })
      .catch(function (err) {
        console.log(err);
      });
    setLoeder(false);
    deleteClose();
  }
  function Search(e) {
    const regex = new RegExp(e.target.value, "gi");
    const value = shifokorlar.filter(
      (el) => el.ism.match(regex) || el.familiya.match(regex)
    );
    if (e.target.value.length > 1) setShifokorlar(value);
    else setShifokorlar(bemorIdpro.data.shifokorlar);
  }
  function Create(e) {
    const formmdata = new FormData();
    for (let [key, value] of Object.entries(input)) {
      formmdata.append(key, value);
    }
    request
      .post(`/muassasa/shifokor/`, formmdata, config)
      .then(function (res) {
        setNotificationn({
          state: "success",
          text: `Shifokor qo'shildi`,
        });
        setInput({ ...input, diagnoz: res.data.id });
        setLoeder(false);
        handleClick(true);
        handleClose2();
      })
      .then(() => setLoeder(false))
      .catch(function (err) {
        toast.error(
          get(err, "response.data.error") +
            " " +
            get(err, "response.data.field", "")
        );
        setLoeder(false);
      });
    //  window.location.reload()
  }

  const idls = localStorage.getItem("id");
  if (bemorIdpro.error) return <Error />;
  if (loader) return <Loading />;

  return (
    <>
      <div
        className="shifokor"
        style={{ paddingRight: "20px", paddingLeft: "20px" }}
      >
        <div className="poliklinika_top">
          <div className="poliklinika_top_left">
            <h2 className="all_doctor">
              {t("shifokor.allshifokor")}: {shifokorlar.length}
            </h2>
            <div className="search_input">
              <TextField
                className="w-full"
                id="standard-basic"
                onChange={Search}
                label={t("shifokor.placholder")}
                variant="standard"
              />
            </div>
          </div>
          <Button
            variant="contained"
            onClick={handleOpen2}
            style={{ marginTop: "15px" }}
            startIcon={<AddIcon />}
          >
            {t("shifokor.add")}
          </Button>
        </div>
        <div className="poliklinika">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow
                  style={{ backgroundColor: "white", marginTop: "25px" }}
                >
                  <TableCell>{t("shifokor.number")}</TableCell>
                  <TableCell align="left">{t("input.fio")}</TableCell>
                  <TableCell align="left">{t("shifokor.birthday")}</TableCell>
                  <TableCell align="left">{t("shifokor.lavozim")}</TableCell>
                  <TableCell align="left">{t("shifokor.professia")}</TableCell>
                  <TableCell align="left">{t("shifokor.tel")}</TableCell>
                  {idls && (
                    <TableCell align="center">
                      {t("shifokor.batafsil")}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {shifokorlar?.map((row, index) => (
                  <TableRow TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{`${row.familiya} ${row.ism} ${row.otasining_ismi}`}</TableCell>
                    <TableCell align="left">{row.tugilgan_sana}</TableCell>
                    <TableCell align="left">{row.lavozimi}</TableCell>
                    <TableCell align="left">
                      {row.mutaxassislik_toifasi}
                    </TableCell>
                    <TableCell align="left">{row.tel_raqami}</TableCell>
                    {idls && (
                      <TableCell align="left">
                        <div className="button_modal button_modal_1">
                          <div className=" button_modal_1">
                            <Link
                              Link
                              to={`/shifokor/${row.id}`}
                              className="single_info"
                            >
                              <img
                                className="delete_icon"
                                src={l1}
                                alt="batafsil"
                              />
                            </Link>
                          </div>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="modal_scrool">
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open2}
            onClose={handleClose2}
            closeAfterTransition
            // BackdropProps={{
            //   timeout: 400,
            // }}
          >
            <Fade in={open2}>
              <div style={classes.paper}>
                <div style={{ textAlign: "end" }}>
                  <Button onClick={() => setOpen2(false)}>
                    {t("input.yop")}
                  </Button>
                </div>
                <div className="modal_scrool_y" style={{ paddingTop: "20px" }}>
                  <Box
                    component="form"
                    className="grid grid-cols-2 gap-20"
                    autoComplete="off"
                  >
                    <div className="add_shifokor_12">
                      <InputLabel>{t("input.pfl")}</InputLabel>
                      <TextField
                        className="w-full"
                        onChange={(e) => pnflChange(e.target.value)}
                        name="JSHSHIR"
                        id="jshshir"
                        variant="outlined"
                        type={"number"}
                        inputProps={{ min: 0 }}
                      />
                    </div>
                    <div className="add_shifokor_12">
                      <InputLabel>{t("shifokor.alladd.name")}</InputLabel>

                      <TextField
                        className="w-full"
                        onChange={onChange}
                        id="ism"
                        name="ism"
                        variant="outlined"
                        value={input.ism}
                      />
                    </div>
                    <div className="add_shifokor_12">
                      <InputLabel>{t("shifokor.alladd.surname")}</InputLabel>
                      <TextField
                        className="w-full"
                        onChange={onChange}
                        name="familiya"
                        id="familiya"
                        variant="outlined"
                        value={input.familiya}
                      />
                    </div>
                    <div className="add_shifokor_12">
                      <InputLabel>{t("shifokor.alladd.otch")}</InputLabel>

                      <TextField
                        className="w-full"
                        id="otasi"
                        onChange={onChange}
                        name="otasining_ismi"
                        variant="outlined"
                        value={input.otasining_ismi}
                      />
                    </div>
                    <div className="add_shifokor_12">
                      <InputLabel>{t("shifokor.alladd.male")}</InputLabel>

                      <TextField
                        className="w-full"
                        id="jinsi"
                        onChange={onChange}
                        name="jinsi"
                        variant="outlined"
                        value={
                          input.jinsi
                            ? input.jinsi === "male"
                              ? "Erkak"
                              : "Ayol"
                            : ""
                        }
                      />
                    </div>
                    <div className="">
                      <InputLabel>{t("shifokor.birthday")}</InputLabel>
                      <div className="w-full">
                        <DatePicker
                          className="w-full"
                          format="DD/MM/YYYY"
                          value={
                            input.tugilgan_sana
                              ? dayjs(input.tugilgan_sana)
                              : dayjs(new Date())
                          }
                          onChange={(e) =>
                            onChange({
                              target: {
                                value: dayjs(e).format("YYYY-MM-DD"),
                                name: "tugilgan_sana",
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    {input?.lavozimi ? (
                      <div className="add_shifokor_12">
                        <InputLabel>{t("shifokor.lavozim")}</InputLabel>
                        <TextField
                          className="w-full"
                          id="lavozimi"
                          onChange={onChange}
                          name="lavozimi"
                          variant="outlined"
                          value={input.lavozimi}
                        />
                      </div>
                    ) : (
                      <div className="add_shifokor_12">
                        <InputLabel>{t("shifokor.lavozim")}</InputLabel>
                        <TextField
                          className="w-full"
                          id="lavozimi"
                          type="string"
                          onChange={onChange}
                          name="lavozimi"
                          variant="outlined"
                        />
                      </div>
                    )}
                    <div className="add_shifokor_12">
                      <InputLabel>{t("shifokor.tel")}</InputLabel>

                      <TextField
                        className="w-full"
                        id="tel-raqam"
                        onChange={onChange}
                        type="number"
                        name="tel_raqami"
                        variant="outlined"
                        value={input.tel_raqami}
                      />
                    </div>
                    <div className="add_shifokor_12">
                      <InputLabel>{t("shifokor.alladd.tashkilot")}</InputLabel>

                      <TextField
                        className="w-full"
                        id="tashkilot"
                        onChange={onChange}
                        type="text"
                        name="tashkiloti"
                        variant="outlined"
                        value={input.tashkiloti}
                      />
                    </div>
                    <div className="add_shifokor_12">
                      <InputLabel>{t("shifokor.alladd.staj")}</InputLabel>
                      <TextField
                        className="w-full"
                        id="staj"
                        type="number"
                        onChange={onChange}
                        name="ish_staji"
                        variant="outlined"
                        inputProps={{ min: 0 }}
                      />
                    </div>
                    <div className="add_shifokor_12">
                      <InputLabel>{t("shifokor.alladd.malaka1")}</InputLabel>

                      <TextField
                        className="w-full"
                        id="malaka"
                        onChange={onChange}
                        name="oxirgi_malaka_oshirgan_vati_va_joyi"
                        variant="outlined"
                      />
                    </div>
                    <div className="add_shifokor_12">
                      <InputLabel>{t("shifokor.alladd.malaka2")}</InputLabel>

                      <TextField
                        className="w-full"
                        id="oshirish-vaqti"
                        onChange={onChange}
                        name="qayta_malaka_oshirish_vaqti"
                        variant="outlined"
                      />
                    </div>
                    <div className="add_shifokor_12">
                      <InputLabel>{t("shifokor.professia")}</InputLabel>
                      <TextField
                        className="w-full"
                        id="toifa"
                        onChange={onChange}
                        name="mutaxassislik_toifasi"
                        variant="outlined"
                      />
                    </div>
                  </Box>
                  <Button
                    variant="contained"
                    onClick={Create}
                    style={{ marginTop: "15px" }}
                  >
                    {t("shifokor.tasdiq")}
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
          <Snackbar
            Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={noti}
            autoHideDuration={6000}
            onClose={handlenoti}
          >
            <Alert
              Alert
              onClose={handlenoti}
              severity={notificationn.state}
              sx={{
                width: "100%",
              }}
            >
              {notificationn.text}
            </Alert>
          </Snackbar>
          <div className="modal_one_99">
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal_one}
              open={del}
              onClose={deleteClose}
              closeAfterTransition
              BackdropProps={{
                timeout: 400,
              }}
              style={{
                marginTop: "200px",
                width: "600px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Fade in={del}>
                <div style={classes.paper}>
                  <div className="delete_btn_group">
                    <Button
                      className="red_btn"
                      variant="contained"
                      onClick={Deletes}
                    >
                      Shifokor o'chirish
                    </Button>
                    <Button
                      className="no_delete_person"
                      variant="contained"
                      color="success"
                      onClick={deleteClose}
                    >
                      {t("bildirishnoma.single.bekor")}
                    </Button>
                  </div>
                </div>
              </Fade>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shifokor;
