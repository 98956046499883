import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useState } from "react";
// import "./chiqim.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { request } from "../../../../../api/request";
import pdfDoc from "../../../../../assets/icon/pdf_doc.svg";
import scrip from "../../../../../assets/icon/scripka.svg";
import "../style.scss";
import useGet from "hooks/useGet";
import { toast } from "react-toastify";
import { get } from "lodash";
function Chiqimrmo({ setOpense2 }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [ttb, setTtb] = useState([]);

  const [comment, setComment] = useState();
  const [par, setPar] = useState([]);
  const [preparatsList, setPreparatsList] = useState([]);
  const [popone, setPopone] = useState(false);
  const [poptwo, setPoptwo] = useState(false);
  const [value, setValue] = useState(0);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setOpen(true);
    setPopone(false);
  };
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [preparationType, setPreparationType] = useState("");
  const [preparationName, setPreparationName] = useState("");
  const [preparationSeries, setPreparationSeries] = useState("");
  const [preparationQuantity, setPreparationQuantity] = useState("");

  function addMedication(e) {
    e.preventDefault();
    setPreparationType({});
    setPreparationName(" ");
    setPreparationSeries("");
    setPreparationQuantity("");
    setPreparatsList([
      ...preparatsList,
      {
        nomi: preparationName && preparationName.nomi,
        turi: preparationType && preparationType.nomi,
        vosita_miqdori: +preparationQuantity,
        vosita_seryasi: preparationSeries,
        vosita_nomi: preparationName && preparationName.id,
        vosita_turi: preparationType && preparationType.id,
      },
    ]);
    setPoptwo(false);
  }
  const countune = (e) => {
    e.preventDefault();
    setOpen(false);
    setPopone(true);
  };

  const {
    data: { muassasalar: regions },
  } = useGet({ url: "/user/respublika/muassasalar/", initialState: [] });
  const {
    data: { data: preparats },
  } = useGet({
    url: "/ariza/vositalar/",
  });
  const removePreparat = (index) => {
    const oldPreparatsList = [...preparatsList];
    oldPreparatsList.splice(index, 1);
    setPreparatsList(oldPreparatsList);
  };
  const preparatNamesList = preparationType
    ? preparats.find((el) => el.id === preparationType.id)?.vosita_nomlari || []
    : [];
  const Save = () => {
    const formData = new FormData();
    formData.append("partiya_raqam", par);
    formData.append("comment", comment);
    formData.append("ttb_id", localStorage.getItem("tid"));
    formData.append("lpu_id", ttb.id);
    image && formData.append("image", image);
    file && formData.append("file", file);
    formData.append("vositalar", JSON.stringify(preparatsList));
    request
      .post("/omborxona/kirim/qilish/TTB/dan/LPU/ga", formData, config)
      .then(() => {
        setOpense2(true);
      })
      .catch((error) => {
        console.log(error);
        if (get(error, "response.data.error"))
          toast.error(get(error, "response.data.error"));
      });
    setPopone(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style={{ marginRight: "20px", backgroundColor: "#F69641" }}
        startIcon={<ArrowOutwardIcon />}
        variant="contained"
      >
        {t("input.chiq")}
      </Button>
      <Modal
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="modal-one" sx={{ ...style, width: 500 }}>
          <div>
            <Button
              style={{
                marginBottom: "14px",
                marginLeft: "-25px",
              }}
              variant="text"
              onClick={() => setOpen(false)}
            >
              <SvgIcon component={ArrowBackIcon} inheritViewBox />
            </Button>
            <h2 className="kirm-head">{t("bildirishnoma.chiqim")}</h2>
          </div>
          <form onSubmit={countune}>
            <TextField
              style={{
                width: "437px",
                marginBottom: "20px",
              }}
              id="outlined-basic"
              onChange={(e) => setPar(e.target.value)}
              label={t("vosita.partiys")}
              variant="outlined"
              required
              type={"number"}
            />
            <FormControl fullWidth style={{ marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-label">
                {t("pdf.oshp")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => setTtb(e.target.value)}
                label="Nomi"
                required
              >
                {regions?.map((el, index) => {
                  return (
                    <MenuItem value={el} key={index}>
                      {el.nomi}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              style={{
                width: "437px",
                marginBottom: "20px",
              }}
              id="outlined-basic"
              onChange={(e) => setComment(e.target.value)}
              label={t("input.yuk")}
              variant="outlined"
              required
            />
            <section>
              <div
                style={
                  image && {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }
                }
              >
                <h4 className="sarflov_block_title">{t("Rasm biriktirish")}</h4>
                <label
                  className={
                    image ? "image__label" : "input_tyle_download fix-file"
                  }
                  id="f1"
                >
                  <input
                    onChange={(e) => setImage(e.target.files[0])}
                    className="visually-hidden"
                    id="f1"
                    type="file"
                    accept="image/*"
                  />
                  <img
                    className={!image && "visually-hidden"}
                    src={scrip}
                    alt=""
                  />
                  {!image
                    ? t("Rasmni bu yerga tashlang yoki biriktiring")
                    : t("Rasmni o'zgartirish")}
                </label>
              </div>
              {image && (
                <div className="sarflov_block_inner_div">
                  {image && (
                    <div className="input_tyle_download">
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <img src={pdfDoc} alt="" className="label_img" />
                        <span className="file-name">{image.name}</span>
                        <CloseIcon
                          style={{ cursor: "pointer" }}
                          onClick={(e) => setImage(null)}
                        />
                      </label>
                    </div>
                  )}
                </div>
              )}
            </section>

            <Button
              style={{
                width: "100%",
                borderRadius: "12px",
              }}
              type="submit"
              variant="contained"
            >
              {t("vosita.davom")}
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={popone}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="modal-one" sx={{ ...style, width: 500 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Button
                style={{
                  marginBottom: "14px",
                  marginLeft: "-25px",
                }}
                variant="text"
                onClick={handleClose}
              >
                <SvgIcon component={ArrowBackIcon} inheritViewBox />
              </Button>
              <h2 className="kirm-head">{t("bildirishnoma.chiqim")}</h2>
            </div>
            <Button
              style={{
                marginBottom: "14px",
                marginLeft: "-25px",
              }}
              variant="text"
              onClick={() => setPopone(false)}
            >
              <SvgIcon component={ClearIcon} inheritViewBox />
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="chiqim-page"
          >
            {t("bola.tan")} {t("pdf.oshp")} : {ttb.nomi}{" "}
          </div>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "28px",
            }}
          >
            <Tabs
              style={{ backgroundColor: "#fff" }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("bildirishnoma.single.vosi")} />
              <Tab label={t("input.fayl")} />
            </Tabs>
          </Box>

          {value === 0 && (
            <div>
              <div className="preparat__list">
                {preparatsList &&
                  preparatsList?.map((el, index) => {
                    return (
                      <div>
                        <div className="preparat__list--item">
                          <span>#{index + 1}</span>
                          <div
                            className="remove-icon"
                            onClick={() => removePreparat(index)}
                          >
                            <SvgIcon component={ClearIcon} inheritViewBox />
                          </div>
                        </div>
                        <div className="preparat__list--item">
                          <span>{t("Vosita nomi")}</span>: {el.nomi}
                        </div>
                        <div className="preparat__list--item">
                          <span>{t("Vosita turi")}</span>:{el.turi}
                        </div>
                        <div className="preparat__list--item">
                          <span>{t("Vosita seriyasi")}</span>:{" "}
                          {el.vosita_seryasi}
                        </div>
                        <div className="preparat__list--item">
                          <span>{t("bildirishnoma.single.miqdori")}</span>:
                          {el.vosita_miqdori}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {value === 1 && (
            <section className={value === 0 && "visually-hidden"}>
              <div
                style={
                  file && {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    overflow: "hidden",
                  }
                }
              >
                <h4 className="sarflov_block_title">
                  {t("bildirishnoma.new.fail")}
                </h4>
                <label
                  className={
                    file ? "image__label" : "input_tyle_download fix-file"
                  }
                  id="f1"
                >
                  <input
                    onChange={(e) => setFile(e.target.files[0])}
                    className="visually-hidden"
                    id="f1"
                    type="file"
                  />
                  <img
                    className={!file && "visually-hidden"}
                    style={{
                      display: "block",
                      margin: "0 auto",
                    }}
                    alt=""
                    src={scrip}
                  />
                  {!file
                    ? t("bildirishnoma.new.failinf")
                    : t("Faylni o'zgartirish")}
                </label>
              </div>
              {file && (
                <div className="sarflov_block_inner_div">
                  {file && (
                    <div
                      className="sarflov_block_download_file "
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <div className="input_tyle_download">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <img src={pdfDoc} alt="" className="label_img" />
                          <span className="file-name"> {file.name}</span>
                          <div className="">
                            <CloseIcon
                              style={{ cursor: "pointer" }}
                              onClick={(e) => setFile(null)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </section>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "36px",
              borderTop: "1px solid #E7EBF2",
            }}
          >
            {value === 0 ? (
              <Button
                onClick={() => setPoptwo(true)}
                startIcon={<AddIcon />}
                style={{
                  border: "none",
                }}
                variant="outlined"
              >
                {t("vosita.vositaqosh")}
              </Button>
            ) : null}

            {/* <Button 
                startIcon={<AddIcon />}
                style={{
                    border:"none",
                }}
                variant="outlined">Vitamin qo’shish</Button> */}
          </div>
          <Button
            onClick={Save}
            style={{
              width: "100%",
              marginTop: "20px",
              borderRadius: "12px",
            }}
            variant="contained"
          >
            {t("input.chiq")}
          </Button>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={poptwo}
        onClose={() => setPoptwo(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <Button
            style={{
              marginBottom: "14px",
              marginLeft: "-25px",
            }}
            variant="text"
            onClick={() => setPoptwo(false)}
          >
            <SvgIcon component={ArrowBackIcon} inheritViewBox />
          </Button>
          <h2 className="kirm-head">{t("vosita.vositaqosh")}</h2>
          <form onSubmit={addMedication}>
            <FormControl fullWidth style={{ marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-label">
                {t("vosita.vositaturi")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t("vosita.vositaturi")}
                value={preparationType}
                onChange={(e) => setPreparationType(e.target.value)}
                required
              >
                {preparats &&
                  preparats?.map((el) => {
                    return <MenuItem value={el}>{el.nomi}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-label">
                {t("bildirishnoma.single.nomi")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={preparationName}
                onChange={(e) => setPreparationName(e.target.value)}
                id="demo-simple-select"
                label={t("bildirishnoma.single.nomi")}
                required
              >
                {preparatNamesList &&
                  preparatNamesList?.map((el) => {
                    return <MenuItem value={el}>{el.nomi}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            <TextField
              style={{
                width: "437px",
                marginBottom: "20px",
              }}
              value={preparationSeries}
              onChange={(e) => setPreparationSeries(e.target.value)}
              id="outlined-basic"
              label="Vosita Seriyasi"
              variant="outlined"
              required
            />
            <TextField
              style={{
                width: "437px",
                marginBottom: "20px",
              }}
              value={preparationQuantity}
              onChange={(e) => setPreparationQuantity(e.target.value)}
              id="outlined-basic"
              label={t("bildirishnoma.single.miqdori")}
              variant="outlined"
              required
              type={"number"}
            />
            <Button
              style={{
                display: "block",
              }}
              type="submit"
              variant="contained"
            >
              {t("bildirishnoma.jonat")}
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
}
export default Chiqimrmo;
