import { Button } from "@mui/material";
import CallMadeIcon from "@mui/icons-material/CallMade";
import l1 from "../../../../../assets/icon/l1.svg";
import Loading from "../../../../../components/loading/loading";
import { useContext, useEffect, useState } from "react";
import { request } from "../../../../../api/request";
import { Contextvalue } from "../../../../../context/context";
import { useTranslation } from "react-i18next";
const Chiqimcarduz = ({classes,close,date,setExcel}) => {
  const [data,setData] = useState({loading:false,error:false,data:[]});
  const [back,setBack] = useState({loading:false,error:false,data:[]});
  const {setCur2} = useContext(Contextvalue)
  const { t } = useTranslation();
  const token = window.localStorage.token
  const config = {
   headers: { Authorization: `Bearer ${token}` }
 }; 
  useEffect(() =>{
    request
    .get("/omborxona/UzMedImpeks/kirim/chiqim/malumotlar",config)
    .then(data => {
      if (date){
          setData({loading:true,error:false,data:data.data.chiqim.filter(el => el.created_at.split("T")[0] === date)})
      }
      else {
        setData({loading:true,error:false,data:data.data.chiqim})
      }
      setBack({ loading: true, error: false, data: data.data.chiqim });
			console.log(data.data.chiqim);
			setExcel(
        data.data.chiqim.map(el => {
          const { kirim_chiqim } = el
          console.log(kirim_chiqim);
					return {
						...el,
						kirim_chiqim: {
							"ID": kirim_chiqim.id,
							"Barkod": kirim_chiqim.unique_raqam,
							"Partya raqam": kirim_chiqim.partiya_raqam,
							"Status": kirim_chiqim.qabul_qilish_status,
							"Chiqim nomi": kirim_chiqim.chiqim_qilingan_tashkilot,
							"Chiqim ID": kirim_chiqim.chiqim_qilingan_tashkilot_id,
							"Izoh": kirim_chiqim.comment,
							"Yaratilgan sana": kirim_chiqim.created_at?.split(":").splice(0, 2).join(":"),
							"O'zgartirilgan sana": kirim_chiqim.edited_at?.split(":").splice(0, 2).join(":"),
						},
					};
				})
			);
    })
  },[date])
  const More = e =>{
    setCur2(data.data.find(el => +el.id === +e))
    close(true);
  } 

  if (!data.loading) return <Loading/>
    return (
      <>
      {data.data && !data.data[0]&& 
        <>
             <p style={{color:"red",fontWeight:"bold"}}>Ro'yxat Topilmadi!</p>
              <Button onClick={() => setData(back)} variant="contained">Ortga</Button>
        </>
      }
      {
        data.data && data.data.reverse().map(el => {
          return(
        <div className="kirim_card chiqim_card">
          <div div className="kirim_card_left chiqim_card_left">
            <Button
              size="large"
              className={classes.button}
              startIcon={<CallMadeIcon />}
            ></Button>
            <p>{t("shifokor.jami")}: {el.vosita_miqdori}</p>
          </div>
          <div className="chiqim_card_center">
            <div className="kirim_card_center_top">
              <div className="top_left">
                <p>{t("bildirishnoma.send")}:</p>
                <h5>{el.kirim_chiqim.chiqim_qilingan_tashkilot}</h5>
              </div>
              <div style={{flexDirection:"column"}} className="top_left">
                <p>{t("input.barkod")}:</p>
                <h5>{!el.kirim_chiqim.unique_raqam?t("bola.kir"):el.kirim_chiqim.unique_raqam }</h5>
              </div>
              <div className="top_right">
                <div className="kirim_card_right_left">
                  <p>{el.created_at.split("T")[0]}</p>
                  <span>{el.created_at.split("T")[1].slice(0,5)}</span>
                </div>
                <div className="kirim_card_right_left">
                  <Button onClick={() => More(el.id)}>
                    <img src={l1} alt="..."/>
                  </Button>
                </div>
              </div>
            </div>
            <div style={{flexDirection:"column",alignItems:"start"}} className="bottom">
              <div style={{border:"none"}}>
                <span>{t("vosita.vositaturi")}: {el.vosita_turi.nomi}</span>
              </div>
              <div style={{border:"none"}}>
                <span>{t("bildirishnoma.single.nomi")}: {el.vosita_nomi.nomi}</span>
              </div>
            </div>
          </div>
          
        </div>

          )
        }) 
      }
      </>
    );
}
 
export default Chiqimcarduz;