import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ChildrenListTable = ({ bildirishnoma }) => {
  const { t } = useTranslation();
  return (
    <div className="sarflov_block">
      <h4 className="sarflov_block_title" style={{ marginBottom: "16px" }}>
        {t("bola.ball1")}
      </h4>
      <TableContainer component={Paper}>
        <Table
          style={{ minWidth: 100 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{t("input.toif")}</TableCell>
              <TableCell>{t("input.toif")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bildirishnoma &&
              Object.keys(bildirishnoma.bolalar).map((el, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{el}</TableCell>
                    <TableCell>
                      {bildirishnoma.bolalar && bildirishnoma.bolalar[el]}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>{t("modalariza.toif")}</TableCell>
              <TableCell>{t("bildirishnoma.single.bolalar")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bildirishnoma &&
              Object.keys(bildirishnoma.bola_oy).map((el, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{el}</TableCell>
                    <TableCell>
                      {bildirishnoma.bola_oy && bildirishnoma.bola_oy[el]}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ChildrenListTable;
