import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { request } from "../../../../../api/request";
import l1 from "../../../../../assets/icon/l1.svg";
import Loading from "../../../../../components/loading/loading";
import Error from "../../../../../Error/Error";
import "./uzbildirish.scss";
import Uzbilmodal from "./uzbilmodal/uzbilmodal";
import dayjs from "dayjs";
const Uzbildirish = () => {
  const [data, setData] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [searchParams, setSearchParams] = useSearchParams({value:0});
  const [full, setFull] = useState([]);
  const [value, setValue] = useState();
  const { t } = useTranslation();
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const handleChange = (event, newValue) => {
   setSearchParams({value:newValue})
    setValue(newValue);
  };
  useEffect(() => {
    if (searchParams.get("value")) setValue(+searchParams.get("value"));
  }, []);
  useEffect(() => {
    if (value === 0) {
      request
        .get("/bildirishnoma/erkin/UzMedImpeksdanVSSBga/", config)
        .then((data) => {
          setData({ loading: true, data: data.data.data, error: false });
          setFull({ loading: true, data: data.data.data, error: false });
        })
        .catch((err) => {
          setData({
            error: true,
            loading: false,
            data: [],
          });
        });
    }
    if ( value === 1) {
      request
        .get("/bildirishnoma/uzmedimpeks/", config)
        .then((data) => {
          setData({ loading: true, data: data.data.data, error: false });
          setFull({ loading: true, data: data.data.data, error: false });
        })
        .catch((err) => {
          setData({
            error: true,
            loading: false,
            data: [],
          });
          throw err;
        });
    }
  }, [value])
  let milliseconds;

  if (data?.data[0]?.sana) {milliseconds =  new Date(data.data[0].sana)};
  console.log(milliseconds?.getTime());
  const Search = (e) => {
    const regex = new RegExp(e.target.value, "gi");
    if (e.target.value.split("").length > 1) {
      setData({
        data: data.data.filter((el) => el.kimga.match(regex)),
        loading: true,
        error: false,
      });
    } else setData({ data: full.data, loading: true, error: false });
  };
  if (data.error) return <Error />;
  if (!data.loading) return <Loading />;
  return (
    <div style={{ marginTop: "28px", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2>
            {t("input.bso")}: {data.data && data.data.length}
          </h2>
          <TextField
            className="search-ariza"
            onChange={Search}
            placeholder={t("input.qidir")}
            style={{ marginLeft: "40px" }}
            id="standard-basic"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="end"
                  style={{ position: "absolute", right: "10px" }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="green_block">
          <Uzbilmodal />
        </div>
      </div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            style={{ backgroundColor: "#fff", borderRadius: "12px" }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          > 
            <Tab label={t("vosita.erkin")} value={0} />
            <Tab label={t("vosita.bola")} value={1} />
          </Tabs>
        </Box>
      <TableContainer className="table-not" component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              <TableCell>{t("bildirishnoma.soni")}</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>{t("bildirishnoma.single.kimdan")}</TableCell>
              <TableCell style={{width:"250px"}} >{t("bildirishnoma.single.kimga")}</TableCell>
              <TableCell >{t("bildirishnoma.turi")}</TableCell>
              <TableCell>{t("bildirishnoma.sana")}</TableCell>
              <TableCell>{t("bildirishnoma.status")}</TableCell>
              <TableCell>{t("bildirishnoma.harakat")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data &&
              data.data.map((el, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{el.id}</TableCell>
                    <TableCell>{el.kimdan}</TableCell>
                    <TableCell style={{}}>{el.kimga}</TableCell>
                    <TableCell>
                      {value === 0 ? t("vosita.erkin") : t("vosita.bola")}
                    </TableCell>
                    <TableCell>{dayjs(el.sana).format("DD.MM.YYYY")}</TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      <button className={`${el.status==="Yuborildi"?"status_btn":"status_btn--not"}`}>{el.status}</button>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={
                          value === 0
                            ? `/uzbilsin/${el.id}/${el.qabul_qiluvchi}`
                            : `/uzbilbol/${el.id}`
                        }
                      >
                        <img src={l1} alt="l1" /> 
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Uzbildirish;
