import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Modalchild({
  setMonthfirst,
  setMonthtwo,
  setYear,
  setYear2,
  setArr,
  arr,
  monthfirst,
  monthtwo,
  year,
  year2,
}) {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const { t } = useTranslation();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const submitData = (e) => {
    e.preventDefault();
      if (+monthfirst >= +monthtwo){
            setMonthtwo(`${+monthfirst+1}`);
            toast.error("Iltimos Oy Toifalarini To'g'ri Kiriting!");
            return;
       }
       if (+year >= +year2){
        setYear2(`${+year+1}`)
        toast.error("Iltimos Yosh Toifalarini To'g'ri Kiriting!");
        return;
   }
       if (arr.some(el => el.monthFirst === monthfirst && el.monthTwo === monthtwo && monthfirst && monthtwo )){
            toast.error("Bir Xil Oy toifasi Kiritish Mumkin Emas!");
            return;
       }
       if (arr.some(el => el.yearOne === year && el.yearTwo === year2 ) && year && year2){
        toast.error("Bir Xil Yosh toifasi Kiritish Mumkin Emas!");
        return;
   }
      setArr([
        ...arr,
        {
          ...(monthfirst && {monthFirst: monthfirst}),
          ...(monthtwo && {monthTwo: monthtwo}),
          ...(year && {yearOne:  year}),
          ...(year2 && {yearTwo: year2}),
        },
      ]);
      setMonthtwo();
      setYear2();
      setOpen2(false);
    }
  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        {t("Yosh va Oy toifa kiritish")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button onClick={handleClose} startIcon={<ArrowBackIcon />} />
          <ul
            style={{ overflowY: "scroll", height: "300px", listStyle: "none" }}
          >
            {arr.map((el, index) => {
              return (
                <React.Fragment key={index}>
                  <li
                    style={{
                      marginBottom: "6px",
                      marginTop: "2px",
                      fontSize: "18px",
                    }}
                  >
                    #{index + 1}
                  </li>
                  <li style={{ marginBottom: "6px", fontSize: "18px" }}>
                    Oy dan:{el.monthFirst}
                  </li>
                  <li style={{ marginBottom: "6px", fontSize: "18px" }}>
                    Oy Gacha:{el.monthTwo}
                  </li>
                  <li style={{ marginBottom: "6px", fontSize: "18px" }}>
                    Yosh Dan:{el.yearOne}
                  </li>
                  <li style={{ marginBottom: "6px", fontSize: "18px" }}>
                    Yosh Gacha:{el.yearTwo}
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
          {!arr[0] && (
            <p style={{ textAlign: "center" }}>
              {t("tugma")}
            </p>
          )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              style={{ textAlign: "center" }}
              startIcon={<AddIcon />}
              onClick={() => setOpen2(true)}
            >
                {t("bola.add")}
            </Button>
            <Button
              style={{ marginTop: "10px", borderRadius: "12px" }}
              variant="contained"
              onClick={handleClose}
            >
              {t("Saqlash")}
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            onClick={() => setOpen2(false)}
            startIcon={<ArrowBackIcon />}
          />
          <form onSubmit={submitData}>
            <TextField
              onChange={(e) => setMonthfirst(getOnlyPositiveValues.call(e, 29))}
              style={{ width: "100%", marginBottom: "22px", marginTop: "12px" }}
              id="outlined-basic"
              label={t("Oy dan")}
              variant="outlined"
            />
            <TextField 
              onChange={(e) => {
                if (e.target.value >= 0)
                  setMonthtwo(getOnlyPositiveValues.call(e, 30));
                else e.target.value = "";
              }}
              style={{ width: "100%", marginBottom: "22px" }}
              id="outlined-basic"
              label={t("Oy gacha")}
              value={monthtwo}
              maxLength={2}
              variant="outlined"
            />

            <TextField
              onChange={(e) => setYear(getOnlyPositiveValues.call(e, 89))}
              style={{ width: "100%", marginBottom: "22px" }}
              id="outlined-basic"
              inputProps={{ min: 0 }}
              maxLength={2}
              label={t("Yosh dan")}
              variant="outlined"
            />
            <TextField
              onChange={(e) => setYear2(getOnlyPositiveValues.call(e, 90))}
              style={{ width: "100%", marginBottom: "22px" }}
              id="outlined-basic"
              label={t("Yosh gacha")}
              value={year2}
              variant="outlined"
            />
            <Button variant="contained" type="submit">
              {t("Saqlash")}
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

function getOnlyPositiveValues(maxValue) {
  const val = this.target.value;
  if (!/^\d+$/.test(val)) {
    this.target.value = "";
    return "";
  }
  if (this.target.value.length > 2)
    this.target.value = this.target.value.slice(0, 2);
  if (this.target.value > maxValue) this.target.value = maxValue;
  return this.target.value;
}
