import qs from "qs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
const useTab = ({ tabKey = "tab" } = {}) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState("1");
  const handleTabChange = (v) => {
    setTab(v);
    navigate(
      `${pathname}?${qs.stringify({
        ...qs.parse(search.slice(1)),
        [tabKey]: v,
      })}`
    );
  };
  useEffect(() => {
    if (searchParams.get(tabKey)) {
      setTab(searchParams.get(tabKey));
    }
  }, []);

  return { tab, handleTabChange };
};

export default useTab;
