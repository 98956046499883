import useGet from "hooks/useGet";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
function User() {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    data: { users = [] },
  } = useGet({ url: `/user/tuman/?muassasa_id=${id}` });
  console.log(users?.sort((a,b)=>a.user_id>b.user_id ? 1 : -1), "ueserasdfasdfasdf")
  return (
    <div>
      <div className="div_m">
        <div
          style={{ height: "800px", overflowY: "scroll" }}
          className="tablisa"
        >
          <span>{t("vosita.foy")}</span>
          {users.sort((a,b)=>a.id>b.id ? 1 : -1).map((el, index) => {
            return (
              <div className="body" style={{ marginTop: "30px" }}>
                <span>N{index + 1}</span>
                <div className="div">
                  <div className="left"> {t("vosita.nomi")}</div>
                  <div className="rigth">{el.username}</div>
                </div>
                <div className="div">
                  <div className="left">{t("vosita.parol")}</div>
                  <div className="rigth">{el.user_password}</div>
                </div>
                <div className="div">
                  <div className="left">{t("vosita.vaqt")}</div>
                  <div className="rigth">
                    {el.expire_date.split("T")[0].split("-")[0]}-
                    {el.expire_date.split("T")[0].split("-")[1]}-
                    {el.expire_date.split("T")[0].split("-")[2] - 1}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default User;
