import DescriptionIcon from "@mui/icons-material/Description";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import l1 from "../../../../../assets/icon/l1.svg";
import l2 from "../../../../../assets/icon/l2.svg";
import useGet from "hooks/useGet";
import { get } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import { request } from "api/request";
const Uzariza = () => {
  const [value, setValue] = useState("");
  const { data: applications } = useGet({ url: "/ariza/uzmedimpeks/" });

  const filteredData = useMemo(() => {
    return get(applications, "data", []).filter((item) =>
      item.mavzu.toLowerCase().includes(value.toLowerCase())
    );
  }, [value, applications]);
  const { t } = useTranslation();
  const changeStatus = (id)=>{
    const formdata = new FormData();
    formdata.append("id", id);
    formdata.append("status", "O'qildi");
    request.put("/ariza/uzmedimpeks/", formdata, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
  }
  return (
    <div style={{ marginTop: "28px", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2>{t("sidebar.li6")}</h2>
          <TextField
            className="search-ariza"
            onChange={(e) => setValue(e.target.value)}
            placeholder={t("input.qidir")}
            style={{ marginLeft: "40px" }}
            id="standard-basic"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ position: "absolute", right: "18px" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <TableContainer className="table-not" component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              <TableCell>{t("bildirishnoma.soni")}</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>{t("bildirishnoma.single.kimdan")}</TableCell>
              <TableCell>{t("input.ta")}</TableCell>
              <TableCell>{t("bildirishnoma.single.data")}</TableCell>
              <TableCell>{t("bildirishnoma.status")}</TableCell>
              <TableCell>{t("bildirishnoma.harakat")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length &&
              filteredData.map((el, index) => {
                return (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{el.id}</TableCell>
                    <TableCell>MOH</TableCell>
                    <TableCell style={{ width: "300px" }}>{el.mavzu.length > 100 ? el.mavzu.slice(0, 100) + "..." : el.mavzu}</TableCell>
                    <TableCell>
                      {moment(get(el, "date")).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {
                        get(el, "status") === "O'qildi" ? (
                          <button className="status_btn--not">
                            {t("vosita.oqil")}
                          </button>
                        ):
                        <button className="status_btn">
                          {t("bildirishnoma.arstatus.yangi")}
                        </button>
                      }
                    </TableCell>
                    <TableCell>
                      <Link to={`/uzarizasin/${el.id}`}
                      onClick={()=>changeStatus(el.id)}
                      >
                        <img src={l1} alt="uzariza" />
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Uzariza;
