import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Button,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { request } from "../../../api/request";
import pnflIcon from "../../../assets/img/pnfl.png";
import "../../../assets/scss/visually-hidden.scss";
import Loading from "../../../components/loading/loading";
import Error from "../../../Error/Error";
import "./muassasa.scss";
// import Excels from '../../../assets/icon/excel.svg'
import DescriptionIcon from "@mui/icons-material/Description";
import { Box } from "@mui/system";
import { CSVLink } from "react-csv";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import l1 from "../../../assets/icon/l1.svg";
import l2 from "../../../assets/icon/l2.svg";
import l3 from "../../../assets/icon/l3.svg";
import l4 from "../../../assets/icon/l4.svg";
import Mockdata from "../../../assets/json/icd-10.json";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Koriklar from "components/component/seanslar/koriklar";

const assignObjectPaths = (obj, stack) => {
  Object.keys(obj).forEach((k) => {
    const node = obj[k];
    if (typeof node === "object") {
      node.path = stack ? `${stack}.${k}` : k;
      assignObjectPaths(node, node.path);
    }
  });
};

export default function Muassasa() {
  assignObjectPaths(Mockdata);

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [noti, setNoti] = useState(false);
  const [notificationn, setNotificationn] = useState({
    state: "",
    text: "",
  });
  const { t } = useTranslation();

  const handleClick = () => {
    setNoti(true);
  };

  const handlenoti = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNoti(false);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const token = window.localStorage.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [loader, setLoeder] = useState(true);

  const [person, setPerson] = useState([]);
  const [shifokorlar, setShifokorlar] = useState([]);
  const [pass, setPass] = useState();
  const [file, setFile] = useState();
  const [bemorIdpro, setBemorIdPro] = useState([]);
  const [doc, setDoc] = useState();
  const [input, setInput] = useState({
    kasalliklar: "",
    tuman: "",
    bemor_passporti: "",
    qoshimcha_malumot: "",
    qoshimcha_tel_raqami: " ",
    passport_qayerdan_kim_tomonidan_berilgan: " ",
  });
  React.useEffect(() => {
    request
      .get(`/muassasa/bola/`, config)
      .then(function (res) {
        setBemorIdPro({
          isFetched: true,
          data: res.data.bolalar,
          error: false,
        });
        setPerson(res.data.bolalar);
        setShifokorlar(res.data.bolalar);
        setLoeder(false);
      })
      .catch(function (err) {
        setBemorIdPro({
          isFetched: false,
          data: [],
          error: err,
        });
      });
  }, [loader]);
  const [shifo, setShifo] = useState();
  useEffect(() => {
    request
      .get("/muassasa/shifokor/", config)
      .then((data) => setShifo(data.data.shifokorlar));
  }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    borderRadius: 2,
    bgcolor: "#fff",
    boxShadow: 24,
    p: 2,
  };
  const [edits, setEdits] = useState();
  const [pin, setPin] = useState({});
  const [pin2, setPin2] = useState({});
  function EditIt(e) {
    setEdits(e);
    const child = person.find((el) => el.id === e);
    setInput(child);
    setDoc(child.biriktirilgan_shifokor.shifokor_id);
    setPin({ ...child.onasi, otasini_ismi: child.otasining_ismi });
    setPin2({ ...child.otasi, otasini_ismi: child.otasining_ismi });
    handleOpen(true);
    setEdi(true);
  }
  const [edi, setEdi] = useState(false);
  function Heets(e) {
    e.preventDefault();
    const fordata = new FormData();
    fordata.append("id", edits);

    Object.keys(input).forEach((key) => {
      if (typeof input[key] !== "object") {
        fordata.append(key, input[key]);
      }
    });
    fordata.append("otasi", fath || input.otasi_id);
    fordata.append("onasi", math || input.onasi_id);
    request
      .put(`/muassasa/bola/`, fordata, config)
      .then(function (res) {
        setNotificationn({
          state: "success",
          text: `Bemor o'zgardi`,
        });

        console.log(res.data);
        setLoeder(false);
        handleClick(true);
      })
      .catch(function (err) {
        setNotificationn({
          state: "error",
          text: `Bemor o'zgarmadi`,
        });
        handleClick(true);
      });
    // setInput({});
    handleClose();
    setEdi(false);
    setLoeder(true);
  }

  const classes = {
    table: {
      minWidth: 700,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      border: "2px solid #000",
      padding: "50px",
      width: "80%",
      margin: "auto",
      maxHeight: "90vh",
    },
    formControl: {
      margin: "1px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    button: {
      padding: "8px",
      borderRadius: "12px",
    },
  };
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = useState({
    ageFilter: false,
    koriklar: false,
    id: null,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);
  const [ides, setides] = useState(null);
  const [opens2, setOpens2] = useState(false);

  const handleOpen2 = (e) => {
    setides(e);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [seans, setSeans] = React.useState(false);
  const [izohs, setIzohs] = React.useState("");

  const handleSeansClose = () => {
    setSeans(false);
  };

  const [fath, setFath] = useState();
  const [math, setMath] = useState();
  function Create(e) {
    e.preventDefault();
    if (Object.keys(pin).length === 0) {
      toast.error("Ona malumotlarini kiritish majburiy");
      return;
    }
    if (Object.keys(pin2).length === 0) {
      toast.error("Ota malumotlarini kiritish majburiy");
      return;
    }

    const formmdata = new FormData();
    formmdata.append("bemor_passporti", pass);
    formmdata.append("qoshimcha_malumot", file);
    formmdata.append("otasi", fath);
    formmdata.append("onasi", math);
    formmdata.append("biriktirilgan_shifokor_id", doc);
    for (let [key, value] of Object.entries(input)) {
      formmdata.append(key, value);
    }
    request
      .post(`/muassasa/bola/`, formmdata, config)
      .then(function (res) {
        setNotificationn({
          state: "success",
          text: `Bola qo'shildi`,
        });
        console.log(res.data);
        handleClick(true);
      })
      .catch(function (err) {
        setNotificationn({ state: "error", text: `Bola qo'shilmadi` });
        handleClick(true);
        if (err.response.data.message === "By bola allaqachon mavjud") {
          alert("Bu Bola Qo'shilgan !");
        }
        console.log(err, "err");
      });
    setLoeder(true);
    handleClose();
  }

  function Deletes(id) {
    request
      .delete(`/muassasa/bola/${ides}/${izohs}`, config)
      .then(function (res) {
        setNotificationn({
          state: "success",
          text: `Bemor o'chirildi`,
        });
      })
      .catch(function (err) {
        setNotificationn({
          state: "success",
          text: `Bemor o'chirilmadi`,
        });
        console.log(err);
      });
    setLoeder(true);
    handleClose2();
    setIzohs("");
  }

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      setInput({ ...input, [e.target.name]: String(e.target.checked) });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  const doctr = (e) => {
    setDoc(e.target.value);
  };

  function filt(params) {
    if (params.length > 1) {
      const regex = new RegExp(params, "gi");
      const value = shifokorlar.filter(
        (el) => el.ism.match(regex) || el.familiya.match(regex)
      );
      setPerson(value);
    } else {
      setPerson(shifokorlar);
    }
  }

  const [sea, setSea] = useState({
    isFetched: false,
    data: {},
    error: null,
  });

  const idls = localStorage.getItem("id");
  const [bemId, setBemId] = useState(null);

  function Seansbemor(e) {
    setBemId(e);
    setSeans(true);
  }

  function fathersub(e) {
    e.preventDefault();
    const formmdata = new FormData();
    formmdata.append("JSHSHIR", pin2.JSHSHIR);
    formmdata.append("ism", pin2.ism);
    formmdata.append("familiya", pin2.familiya);
    formmdata.append("otasining_ismi", pin2.otasini_ismi);
    request
      .post("/muassasa/bola/otasi/", formmdata, config)
      .then((data) => setFath(data.data.id));
    setPoptwo(false);
  }

  function mathersub(e) {
    e.preventDefault();
    const formmdata = new FormData();
    formmdata.append("JSHSHIR", pin.JSHSHIR);
    formmdata.append("ism", pin.ism);
    formmdata.append("familiya", pin.familiya);
    formmdata.append("otasining_ismi", pin.otasini_ismi);
    request
      .post("/muassasa/bola/onasi/", formmdata, config)
      .then((data) => setMath(data.data.id));
    setPopthree(false);
  }
  const [poptwo, setPoptwo] = useState(false);
  const [popthree, setPopthree] = useState(false);
  const danref = useRef(0);
  const garef = useRef(0);
  function filter() {
    if (danref.current.value > 0 || garef.current.value > 0) {
      setModal({ ...modal, ageFilter: false });
      request
        .get(
          `/muassasa/bola/${danref.current.value || 0}/${
            garef.current.value || 0
          }/`,
          config
        )
        .then((data) => {
          if (!data.data.soni)
            toast.error("Bu yosh oralig'ida bolalar topilmadi.");
          setPerson(data.data.bolalar);
        })
        .catch();
    } else {
      toast.error("Iltimos yoshni belgilang");
    }
  }

  const [pinflLoading, setPinflLoading] = useState(false);
  const [pinflError, setPinflError] = useState(false);
  function pinfl(e) {
    setPinflLoading(true);
    setPinflError(false);
    request
      .get(`/pinfl/${e.target.value}/`, config)
      .then((data) => {
        setPin(data.data);
        setPinflLoading(false);
      })
      .catch((err) => {
        toast.error("Bunday PINFL bo'yicha hech qanday ma'lumot topilmadi");
        setPin({ ...pin, ism: "", familiya: "", otasini_ismi: "" });
        setPinflError(true);
        setPinflLoading(false);
      });
  }

  function pinfl2(e) {
    setPinflLoading(true);
    setPinflError(false);

    request
      .get(`/pinfl/${e.target.value}/`, config)
      .then((data) => {
        setPin2(data.data);
        setPinflLoading(false);
      })
      .catch((err) => {
        toast.error("Bunday PINFL bo'yicha hech qanday ma'lumot topilmadi");
        setPinflError(true);
        setPinflLoading(false);
        setPin2({ ...pin2, ism: "", familiya: "", otasini_ismi: "" });
      });
  }
  const [val, setVal] = useState();
  const [loading, setLoading] = useState(false);
  function subpinfl(e) {
    e.preventDefault();
    setLoading(true);
    request
      .get(`/pinfl/${val}/`, config)
      .then((data) => {
        setInput({
          JSHSHIR: data.data.JSHSHIR,
          ism: data.data.ism,
          familiya: data.data.familiya,
          otasining_ismi: data.data.otasini_ismi,
          tugilgan_sana: data.data.tugilgan_sana,
          passport_seriya_va_raqami: `${data.data.pasport_seriya} ${data.data.pasport_raqami}`,
          manzil: data.data.address.line,
          passport_qayerdan_kim_tomonidan_berilgan: " ",
          yashash_manzili: " ",
          bemor_passporti: " ",
          qoshimcha_malumot: " ",
          qoshimcha_raqam: " ",
          jinsi: data.data.jinsi === "male" ? "Erkak" : "Ayol",
        });
        handleOpen();
        setOpens2(false);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.data.error.status_code === 404)
          toast.error("Bemor Mavjud Emas!");
        toast.error("Xatolik");
        setLoading(false);
      });
  }
  const [guv, setGuv] = useState();
  const [numg, setNumg] = useState();
  const [birth, setBirth] = useState();
  function bolapnfl(e) {
    e.preventDefault();
    setOpens2(false);
    handleOpen();
    const formData = new FormData();
    formData.append("documentSeries", numg);
    formData.append("documentNumber", guv);
    formData.append("birthDate", birth);
    request
      .post(`/muassasa/bola/pnfl/`, formData, config)
      .then((data) => {
        if (data.data.error.status_code === 404)
          toast.error("Bola Mavjud Emas!");
        setInput({
          JSHSHIR: data.data.data.identifier.nnuzb,
          ism: data.data.data.name.firstName,
          familiya: data.data.data.name.lastName,
          otasining_ismi: data.data.data.name.patronymic,
          tugilgan_sana: data.data.data.birthDate,
          passport_seriya_va_raqami: data.data.data.identifier.btc,
          manzil: "",
          passport_qayerdan_kim_tomonidan_berilgan: " ",
          yashash_manzili: " ",
          bemor_passporti: " ",
          qoshimcha_malumot: " ",
          qoshimcha_tel_raqami: " ",
          jinsi: data.data.data.jinsi === "male" ? "Erkak" : "Ayol",
        });
      })
      .catch((err) => {
        toast.error("Xatolik");
        throw err;
      });
  }

  if (bemorIdpro.error) return <Error />;
  if (loader) return <Loading />;
  return (
    <div
      className="muassasa_personal"
      style={{
        paddingRight: "20px",
        paddingLeft: "20px",
        paddingBottom: "20px",
      }}
    >
      {modal.koriklar ? (
        <Koriklar
          open={modal.koriklar}
          id={modal.id}
          handleClose={() => setModal({ ...modal, koriklar: false, id: null })}
        />
      ) : null}
      <div className="muassasa_blocks">
        <div className="muassasa_blocks_left">
          <h4 className="person_all">
            {t("bola.ball")}: {person.length}
          </h4>
          <div className="button_block">
            {idls && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                // onClick={edi ? Heets : handleOpen}
                onClick={() => setOpens2(true)}
                className={classes.button}
                startIcon={<AddIcon />}
              >
                {t("bola.add")}
              </Button>
            )}
            <div className="green_block">
              <Button
                style={{
                  backgroundColor: "#18CF6C ",
                  borderRadius: "12px",
                  marginLeft: "24px",
                }}
                variant="contained"
                // color="primary"
                size="large"
                className={classes.button}
                startIcon={<DescriptionIcon />}
              >
                <CSVLink data={person} separator=";" className="excel_download">
                  {t("bola.excel")}
                </CSVLink>
              </Button>
            </div>

            <div className="sonlar"></div>
          </div>
        </div>
        <div className="muassasa_blocks">
          <div className="serach_person">
            <TextField
              onChange={(e) => filt(e.target.value)}
              id="standard-search"
              label={t("bola.qidirish")}
              type="search"
              variant="standard"
            />
          </div>
        </div>
      </div>

      <Modal
        open={modal.ageFilter}
        onClose={() => setModal({ ...modal, ageFilter: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ p: 2 }}>
            <div className="sort_block">
              <div className="sort_block_inner">
                <p className="age_title">{t("bola.dan")}</p>
                <TextField
                  inputRef={danref}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  InputProps={{ min: 0 }}
                />
              </div>
              <div className="sort_block_inner">
                <p className="age_title">{t("bola.ga")}</p>
                <TextField
                  inputRef={garef}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  InputProps={{ min: 0 }}
                />
              </div>
            </div>
            <div className="sorf_block_btn">
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => setPerson(shifokorlar)}
                variant="contained"
              >
                {t("bola.all")}
              </Button>
              <Button
                onClick={() => {
                  filter();
                }}
                variant="contained"
              >
                {t("shifokor.tasdiq")}
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>

      <div className="poliklinika">
        <div className="poliklinika">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow style={{ backgroundColor: "white" }}>
                  <TableCell>{t("shifokor.number")}</TableCell>
                  <TableCell align="left">{t("input.pfl")}</TableCell>
                  <TableCell align="left">
                    {t("shifokor.alladd.name")} {t("shifokor.alladd.surname")}{" "}
                    {t("shifokor.alladd.otch")}
                  </TableCell>
                  <TableCell align="left">{t("shifokor.birthday")}</TableCell>
                  <TableCell align="left">
                    <div className="age_children">
                      <Button
                        variant="contained"
                        onClick={() => setModal({ ...modal, ageFilter: true })}
                      >
                        {t("bola.yosh")}
                      </Button>
                    </div>
                  </TableCell>
                  <TableCell align="left">{t("bola.shifo")}</TableCell>
                  <TableCell align="left">{t("bola.ms")}</TableCell>
                  <TableCell align="center">{t("shifokor.batafsil")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {person?.map((row, index) => (
                  <TableRow>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{row.JSHSHIR}</TableCell>
                    <TableCell align="left">{`${row.familiya} ${row.ism} ${row.otasining_ismi}`}</TableCell>
                    <TableCell align="left">{row.tugilgan_sana}</TableCell>
                    <TableCell align="center">{row.yosh}</TableCell>
                    <TableCell align="left">{`${row.biriktirilgan_shifokor.ismi} ${row.biriktirilgan_shifokor.familiyasi}`}</TableCell>
                    <TableCell align="left">
                      {row.biriktirilgan_muassasa.nomi}
                    </TableCell>

                    {idls && (
                      <TableCell align="right">
                        <div className="button_modal button_modal_1">
                          <Link
                            Link
                            to={`/bemormalumoti/${row.id}`}
                            className="single_info"
                          >
                            <img
                              className="delete_icon"
                              src={l1}
                              alt="batafsil"
                            />
                          </Link>
                          <button
                            onClick={(e) => EditIt(row.id)}
                            className="edit_btn"
                          >
                            <img
                              className="delete_icon"
                              src={l2}
                              alt="o'zgartirish"
                            />
                          </button>
                          <button
                            className="delete_div"
                            onClick={() => handleOpen2(row.id)}
                            id={row.id}
                          >
                            <img
                              className="delete_icon"
                              src={l3}
                              alt="o'chirish"
                            />
                          </button>
                          <div className="seans_div">
                            <Button
                              className="seanslar_btn_muassasa"
                              onClick={() =>
                                setModal({
                                  ...modal,
                                  koriklar: true,
                                  id: row.id,
                                })
                              }
                            >
                              <img src={l4} alt="" />
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="modal_scrool">
          <Modal
            keepMounted
            open={opens2}
            onClose={() => setOpens2(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box className="modal-one" sx={{ ...style, width: 500 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Button
                  style={{
                    marginLeft: "-25px",
                  }}
                  variant="text"
                  onClick={() => setOpens2(false)}
                >
                  <SvgIcon component={ArrowBackIcon} inheritViewBox />
                </Button>
                <h2 className="modal-head">{t("input.bo")}</h2>
              </div>
              <Tabs
                style={{ marginBottom: "20px", borderBottom: "1px solid" }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label={t("input.pfl")} />
                <Tab label={t("bola.g")} />
              </Tabs>
              <form
                className={value === 1 && "visually-hidden"}
                onSubmit={subpinfl}
              >
                <div style={{ width: "100%" }} className="w-full jshshir_inner">
                  <TextField
                    id="outlined-basic"
                    label={t("input.pfl")}
                    style={{ width: "100%" }}
                    onChange={(e) => setVal(e.target.value)}
                    // name="ismi"
                    variant="outlined"
                    type="number"
                    // error={true}
                    required
                  />
                  <div className="jshshir_inner">
                    {/* <img src={more}/> */}
                    <div className="jshshir_inner_item">
                      <img className="pnfl_icon" src={pnflIcon} alt="icon" />
                    </div>
                  </div>
                </div>
                <Button
                  disabled={loading}
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                    marginTop: "16px",
                  }}
                  type={"submit"}
                  variant="contained"
                >
                  {loading ? "Izlanmoqda..." : t("input.iz")}
                </Button>
              </form>
              <form
                className={value === 0 && "visually-hidden"}
                onSubmit={bolapnfl}
              >
                <div style={{ width: "100%" }} className="w-full jshshir_inner">
                  <TextField
                    onChange={(e) => setGuv(e.target.value)}
                    id="outlined-basic"
                    label={t("bola.g")}
                    style={{ width: "100%" }}
                    variant="outlined"
                    type="number"
                    required
                  />
                  <TextField
                    onChange={(e) => setNumg(e.target.value)}
                    id="outlined-basic"
                    label={t("jihoz.ser")}
                    style={{ width: "100%", marginTop: "17px" }}
                    variant="outlined"
                    required
                  />
                  <InputLabel style={{ marginTop: "6px" }}>
                    {t("shifokor.birthday")}
                  </InputLabel>
                  <TextField
                    onChange={(e) => setBirth(e.target.value)}
                    id="outlined-basic"
                    style={{ width: "100%", marginTop: "10px" }}
                    variant="outlined"
                    type="date"
                    required
                  />
                </div>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                    marginTop: "16px",
                  }}
                  type={"submit"}
                  variant="contained"
                >
                  {t("input.iz")}
                </Button>
              </form>
            </Box>
          </Modal>
        </div>
        <div className="modal_scrool">
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
          >
            <Fade in={open}>
              <div style={classes.paper}>
                <form
                  className="form_control_scrool"
                  onSubmit={edi ? Heets : Create}
                >
                  <h1>{edi ? t("input.boz") : t("input.bo")}</h1>
                  <div className="grid grid-cols-2 gap-10">
                    <div className="">
                      <TextField
                        id="outlined-basic"
                        name="JSHSHIR"
                        className="w-full"
                        variant="outlined"
                        inputProps={{ maxLength: 14 }}
                        type="number"
                        value={input.JSHSHIR}
                        disabled
                        required
                        error={[]}
                      />
                      <div className="jshshir_inner">
                        {/* <img src={more}/> */}
                        <div className="jshshir_inner_item">
                          <img
                            className="pnfl_icon"
                            src={pnflIcon}
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <TextField
                        className="w-full"
                        id="outlined-basic"
                        label={t("shifokor.alladd.name")}
                        onChange={(e) => onChange(e)}
                        name="ism"
                        variant="outlined"
                        value={input.ism}
                        required
                        disabled
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-10">
                    <div className="w-full">
                      <TextField
                        className="w-full"
                        id="outlined-basic"
                        label={t("shifokor.alladd.surname")}
                        variant="outlined"
                        onChange={onChange}
                        name="familiya"
                        value={input.familiya}
                        required
                        disabled
                      />
                    </div>
                    <div className="w-full">
                      <TextField
                        className="w-full"
                        id="outlined-basic"
                        label={t("shifokor.alladd.otch")}
                        variant="outlined"
                        onChange={onChange}
                        name="otasini_ismi"
                        value={input.otasining_ismi}
                        required
                        disabled
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-10">
                    <div className="w-full">
                      <TextField
                        className="w-full"
                        id="outlined-basic"
                        variant="outlined"
                        type="date"
                        label={t("shifokor.birthday")}
                        onChange={onChange}
                        name="tugilgan_sanasi"
                        value={input.tugilgan_sana}
                        required
                        disabled
                      />
                    </div>
                    <div className="">
                      {input.passport_seriya_va_raqami && (
                        <div className="w-full">
                          <TextField
                            className="w-full"
                            id="outlined-basic"
                            label={t("bola.pass")}
                            variant="outlined"
                            inputProps={{ maxLength: 2 }}
                            onChange={onChange}
                            name="passport_seriyasi"
                            value={input.passport_seriya_va_raqami}
                            required
                            disabled
                          />
                        </div>
                      )}
                      {/* <div className="w-full">
                        <TextField
                          className={input.ismi && "input-sel"}
                          id="outlined-basic"
                          label="Passport raqam"
                          variant="outlined"
                          inputProps={{ maxLength: 7 }}
                          onChange={onChange}
                          name="passport_raqami"
                          value={input.passport_raqami}
                          required
                          disabled
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-10">
                    <div className="select_div_jinsi">
                      <FormControl className="w-full">
                        <InputLabel InputLabel id="qon-guruh" required>
                          {input.jinsi === "Ayol" ? "Ayol" : "Erkak"}
                        </InputLabel>
                        <Select
                          labelId="qon-guruh"
                          id="demo-simple-select2"
                          // value={input.jinsi === "female" ? "Ayol" : "Erkak"}
                          onChange={onChange}
                          name={"jinsi"}
                          disabled
                        >
                          <MenuItem value="Erkak">{t("input.e")}</MenuItem>
                          <MenuItem value="Ayol">{t("input.a")}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <TextField
                      className="w-full"
                      id="outlined-basic"
                      label={t("Yashash manzili")}
                      variant="outlined"
                      type="text"
                      inputProps={{ min: 0 }}
                      onChange={onChange}
                      name="manzil_uyi"
                      value={input.manzil_uyi}
                    />
                    {/* <div className="w-full">
                      <TextField
                        className="w-full"
                        id="outlined-basic"
                        label={t("bola.ro")}
                        variant="outlined"
                        onChange={onChange}
                        name="manzil"
                        value={input.ism && input.manzil}
                      />
                    </div> */}
                  </div>
                  <div className="grid grid-cols-2 gap-10">
                    <div className="w-full">
                      <Button
                        onClick={() => setPopthree(true)}
                        labelId="diag"
                        id="demo-simple-select4"
                        name="diagnoz"
                        required
                        style={{
                          width: "100%",
                          padding: "12px",
                          border: "2px solid rgb(201 201 201)",
                          justifyContent: "flex-start",
                          cursor: "pointer",
                        }}
                        className="dializa_div_99"
                      >
                        {t("sbola.on1")}
                      </Button>
                    </div>
                    <div className="w-full">
                      <TextField
                        className="w-full"
                        id="outlined-basic"
                        label={t("bola.who")}
                        variant="outlined"
                        onChange={onChange}
                        name="qachon_kim_tomonidan_berilgan"
                        value={input.qachon_kim_tomonidan_berilgan}
                        // required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-10">
                    <div className="w-full">
                      <Button
                        onClick={() => setPoptwo(true)}
                        labelId="diag"
                        id="demo-simple-select4"
                        name="diagnoz"
                        required
                        style={{
                          width: "100%",
                          padding: "12px",
                          border: "2px solid rgb(201 201 201)",
                          justifyContent: "flex-start",
                          cursor: "pointer",
                        }}
                        className="dializa_div_99"
                      >
                        {t("sbola.ot1")}
                      </Button>
                    </div>

                    <div className="w-full">
                      <TextField
                        className="w-full"
                        id="outlined-basic"
                        label={t("shifokor.tel")}
                        variant="outlined"
                        type="number"
                        inputProps={{ min: 0 }}
                        onChange={onChange}
                        name="tel_raqami"
                        value={input.tel_raqami}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-10">
                    <div className="w-full">
                      <TextField
                        className="w-full"
                        id="outlined-basic"
                        label={t("bola.qtel")}
                        variant="outlined"
                        type="number"
                        inputProps={{ min: 0 }}
                        onChange={onChange}
                        name="qoshimcha_raqam"
                        value={input.qoshimcha_raqam}
                      />
                    </div>
                    <div className="w-full select_div_100">
                      <Autocomplete
                        style={{
                          width: "100%",
                        }}
                        className="w-full"
                        options={shifo}
                        onChange={doctr}
                        autoHighlight
                        value={shifo?.find((item) => item.id === doc)}
                        getOptionLabel={(option) =>
                          `${option?.ism} ${option?.familiya}`
                        }
                        renderOption={(props, option) => (
                          <Box
                            value={option.id}
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.ism} {option.familiya}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label={t("bola.shifo")}
                          />
                        )}
                      />
                      {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-controlled-open-select-label">
                          Age
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={open}
                          // onClose={}
                          // onOpen={handleOpen}
                          value={doc}
                          label="Age"
                          onChange={handleChange}
                          // label={t("bola.shifo")}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </FormControl> */}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-10">
                    <div className="w-full">
                      <DatePicker
                        label={t("bola.rxt")}
                        placeholder={t("bola.rxt")}
                        className="w-full"
                        format="DD/MM/YYYY"
                        value={dayjs(input.royxatga_olingan_sana)}
                        onChange={(e) =>
                          onChange({
                            target: {
                              value: dayjs(e).format("YYYY-MM-DD"),
                              name: "royxatga_olingan_sana",
                            },
                          })
                        }
                      />
                    </div>
                    <div className="w-full">
                      <FormControl className={"w-full"}>
                        <InputLabel id="qon-guruh">
                          {t("bola.guruh")}
                        </InputLabel>
                        <Select
                          label={t("bola.guruh")}
                          labelId="qon-guruh"
                          className="w-full"
                          id="demo-simple-select2"
                          value={input.qon_guruhi}
                          onChange={onChange}
                          name="qon_guruhi"
                          required
                        >
                          <MenuItem value={1}>AB(IV)Rh+</MenuItem>
                          <MenuItem value={2}>AB(IV)Rh-</MenuItem>
                          <MenuItem value={3}>A(II)Rh+</MenuItem>
                          <MenuItem value={4}>A(II)Rh-</MenuItem>
                          <MenuItem value={5}>B(III)Rh+</MenuItem>
                          <MenuItem value={6}>B(III)Rh-</MenuItem>
                          <MenuItem value={7}>O(I)Rh+</MenuItem>
                          <MenuItem value={8}>O(I)Rh-</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="button_block1">
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      className={classes.button}
                      // onClick={edi ? Heets : Create}
                    >
                      {edi ? t("input.boz") : t("Qo'shish")}
                    </Button>
                  </div>
                </form>
              </div>
            </Fade>
          </Modal>
          <div className="modal_one_99">
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal_one}
              open={open2}
              onClose={handleClose2}
              closeAfterTransition
              BackdropProps={{
                timeout: 400,
              }}
              style={{
                marginTop: "200px",
                width: "600px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Fade in={open2}>
                <div style={{ ...classes.paper, height: "300px" }}>
                  <div className="delete_g">
                    <h4>{t("input.bdel")}</h4>
                    <div className="izoh_text">
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="nogironlik">{t("input.s")}</InputLabel>
                        <Select
                          labelId="123"
                          id="demo-simple-select3"
                          onChange={(e) => setIzohs(e.target.value)}
                          name="izoh"
                          label={t("input.s")}
                          value={input.bemor_kasallik_turi}
                        >
                          <MenuItem MenuItem value={"Vafot etdi"}>
                            {t("input.vafot")}
                          </MenuItem>
                          <MenuItem value={t("input.trans")}>
                            {t("input.trans")}
                          </MenuItem>
                          <MenuItem value={"Muassasa almashtirildi"}>
                            {t("input.muas")}
                          </MenuItem>
                          <MenuItem value={"Sog'aydi"}>
                            {t("input.sog")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="delete_btn_group">
                      {izohs?.length > 3 ? (
                        <Button
                          style={{
                            marignBottom: "14px",
                          }}
                          className="red_btn"
                          variant="contained"
                          onClick={Deletes}
                        >
                          {t("input.b")}
                        </Button>
                      ) : (
                        <Button
                          className="red_btn"
                          variant="contained"
                          onClick={Deletes}
                          disabled
                        >
                          {t("input.b")}
                        </Button>
                      )}

                      <Button
                        className="no_delete_person"
                        variant="contained"
                        color="success"
                        onClick={handleClose2}
                      >
                        {t("bildirishnoma.single.bekor")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
          </div>
        </div>
      </div>
      <Snackbar
        Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={noti}
        autoHideDuration={6000}
        onClose={handlenoti}
      >
        <Alert
          Alert
          onClose={handlenoti}
          severity={notificationn.state}
          sx={{
            width: "100%",
          }}
        >
          {notificationn.text}
        </Alert>
      </Snackbar>
      <div className="modal_seans">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal_one}
          open={seans}
          onClose={handleSeansClose}
          closeAfterTransition
          BackdropProps={{
            timeout: 400,
          }}
          style={{
            marginTop: "0",
            width: "900px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Fade in={seans}>
            <div>
              <Koriklar
                Seansbemor={Seansbemor}
                sea={sea}
                setSea={setSea}
                id={bemId}
                shifokorlar={shifokorlar}
                handleSeansClose={handleSeansClose}
                loader={loader}
                setLoeder={setLoeder}
              />
            </div>
          </Fade>
        </Modal>
        <Modal
          style={{
            zIndex: "1400",
          }}
          keepMounted
          open={poptwo}
          onClose={() => setPoptwo(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={{ ...style, width: 500 }}>
            <Button
              style={{
                marginBottom: "14px",
                marginLeft: "-25px",
              }}
              variant="text"
              onClick={() => setPoptwo(false)}
            >
              <SvgIcon component={ArrowBackIcon} inheritViewBox />
            </Button>
            <form onSubmit={fathersub}>
              <div className="input_blocks">
                <TextField
                  id="outlined-basic"
                  className="w-full"
                  label={t("input.pfl")}
                  onChange={(e) => {
                    e.target.value.split("").length === 14 && pinfl2(e);
                    setPin2({ ...pin, JSHSHIR: e.target.value });
                  }}
                  value={pin2.JSHSHIR || ""}
                  variant="outlined"
                  type={"number"}
                  required
                />
              </div>
              <div className="input_blocks">
                <TextField
                  className={`w-full ${pin2 && pin2.ism && "input-sel"}`}
                  id="outlined-basic"
                  label={t("shifokor.alladd.name")}
                  variant="outlined"
                  value={pin2 && pin2.ism}
                  disabled
                  required
                />
              </div>
              <div className="input_blocks">
                <TextField
                  className={`w-full ${pin2 && pin2.ism && "input-sel"}`}
                  id="outlined-basic"
                  label={t("shifokor.alladd.surname")}
                  disabled
                  variant="outlined"
                  value={pin2 && pin2.familiya}
                  required
                />
              </div>
              <div className="input_blocks">
                <TextField
                  className={`w-full ${pin2 && pin2.ism && "input-sel"}`}
                  id="outlined-basic"
                  disabled
                  label="Otasining Ismi"
                  variant="outlined"
                  value={pin2 && pin2.otasini_ismi}
                  required
                />
              </div>
              <Button
                disabled={pinflLoading || pinflError}
                style={{ width: "100%", marginTop: "10px" }}
                type={"submmit"}
                variant="contained"
              >
                {t("shifokor.tasdiq")}
              </Button>
            </form>
          </Box>
        </Modal>
        <Modal
          style={{
            zIndex: "1400",
          }}
          keepMounted
          open={popthree}
          onClose={() => setPopthree(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={{ ...style, width: 500 }}>
            <Button
              style={{
                marginBottom: "14px",
                marginLeft: "-25px",
              }}
              variant="text"
              onClick={() => setPopthree(false)}
            >
              <SvgIcon component={ArrowBackIcon} inheritViewBox />
            </Button>
            <form onSubmit={mathersub}>
              <div className="input_blocks">
                <TextField
                  id="outlined-basic"
                  className="w-full"
                  label={t("input.pfl")}
                  onChange={(e) => {
                    e.target.value.split("").length === 14 && pinfl(e);
                    setPin({ ...pin, JSHSHIR: e.target.value });
                  }}
                  variant="outlined"
                  value={pin.JSHSHIR || ""}
                  // value={input.ismi}
                  type={"number"}
                  required
                />
              </div>
              <div className="input_blocks">
                <TextField
                  className={`w-full ${pin && pin.ism && "input-sel"}`}
                  id="outlined-basic"
                  label={t("shifokor.alladd.name")}
                  disabled
                  variant="outlined"
                  value={pin && pin.ism}
                  required
                />
              </div>
              <div className="input_blocks">
                <TextField
                  className={`w-full ${pin && pin.ism && "input-sel"}`}
                  id="outlined-basic"
                  label={t("shifokor.alladd.surname")}
                  variant="outlined"
                  disabled
                  value={pin && pin.familiya}
                  required
                />
              </div>
              <div className="input_blocks">
                <TextField
                  className={`w-full ${pin && pin.ism && "input-sel"}`}
                  id="outlined-basic"
                  disabled
                  label="Onasining Ismi"
                  variant="outlined"
                  value={pin && pin.otasini_ismi}
                  required
                />
              </div>
              <Button
                disabled={pinflLoading || pinflError}
                style={{ width: "100%", marginTop: "10px" }}
                type={"submmit"}
                variant="contained"
              >
                Tasdiqalash
              </Button>
            </form>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

// const [diainput, setDiainput] = useState({});
// const [multiselectdata, setMultiSelectData] = useState({
//   asorati: [],
//   asosiy: [],
//   raqobat: [],
//   fon: [],
//   bogliq: [],
// });

// const treeOnchangeHandler = async (a, b) => {
//   const dializes = [...multiselectdata.asorati];
//   dializes.push(a.label);
//   setMultiSelectData({ ...multiselectdata, asorati: dializes });
// };
// const treeOnchangeHandler2 = (a, b) => {
//   const dializes = [...multiselectdata.asosiy];
//   dializes.push(a.label);
//   setMultiSelectData({ ...multiselectdata, asosiy: dializes });
// };
// const treeOnchangeHandler3 = async (a, b) => {
//   const dializes = [...multiselectdata.raqobat];
//   dializes.push(a.label);
//   setMultiSelectData({ ...multiselectdata, raqobat: dializes });
// };
// const treeOnchangeHandler4 = (a, b) => {
//   const dializes = [...multiselectdata.fon];
//   dializes.push(a.label);
//   setMultiSelectData({ ...multiselectdata, fon: dializes });
// };
// const treeOnchangeHandler5 = (a, b) => {
//   const dializes = [...multiselectdata.bogliq];
//   dializes.push(a.label);
//   setMultiSelectData({ ...multiselectdata, bogliq: dializes });
// };

// function Dializa(e) {
//   const formmdata = new FormData();
//   for (let [key, val] of Object.entries(diainput)) {
//     formmdata.append(key, val);
//   }
//   formmdata.append("asosiy", JSON.stringify(multiselectdata.asosiy));
//   formmdata.append("asorati", JSON.stringify(multiselectdata.asorati));
//   formmdata.append("raqobat", JSON.stringify(multiselectdata.raqobat));
//   formmdata.append("fon", JSON.stringify(multiselectdata.fon));
//   formmdata.append("bogliq", JSON.stringify(multiselectdata.bogliq));
//   formmdata.append("token", token);
//   request
//     .post(`/bemor/create/diagnoz/`, formmdata, config)
//     .then(function (res) {
//       setInput({ ...input, diagnoz: res.data.id });
//     })
//     .then(() => setLoeder(false))
//     .catch(function (err) {});
//   handleClose1();
// }

/* <div className="w-full"> */
/* <TextField
                        className="w-full"
                        id="outlined-basic"
                        label={t("Yashash manzili")}
                        variant="outlined"
                        type="text"
                        inputProps={{ min: 0 }}
                        onChange={onChange}
                        name="manzil_uyi"
                        value={input.manzil_uyi}
                      /> */
/* <div
                        labelId="diag"
                        id="demo-simple-select4"
                        onChange={onChange}
                        name="diagnoz"
                        required
                        onClick={handleOpen1}
                        value={input.diagnoz}
                        style={{
                          padding: "17px",
                          border: "2px solid rgb(201 201 201)",
                          cursor: "pointer",
                        }}
                        className="dializa_div_99"
                      >
                        {diainput.asorati && diainput.asosiy
                          ? "Diagnoz to'ldirildi"
                          : t("shifokor.diagnoz")}
                      </div> */

/* <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={open1}
    onClose={handleClose1}
    closeAfterTransition
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open1}>
      <div className="diagnoz_block_div" style={classes.paper}>
        <div
          className="nazad"
          onClick={handleClose1}
          style={{
            cursor: "pointer",
          }}
        >
          {t("bildirishnoma.single.ortga")}
        </div>
        <div className="diagnoz_div">
          <div className="diagniz_div_left">
            <h6 className="title">Asosiy</h6>
            <DropdownTreeSelect
              data={Mockdata}
              onChange={treeOnchangeHandler2}
              className="mdl-demo"
            />
          </div>
          <div className="li_div">
            {multiselectdata.asosiy.map((item) => (
              <ul>
                <li>{item}</li>
              </ul>
            ))}
          </div>
        </div>
        <div className="diagnoz_div">
          <div className="diagniz_div_left">
            <h6 className="title">Asorati</h6>
            <DropdownTreeSelect
              data={Mockdata}
              className="mdl-demo"
              onChange={treeOnchangeHandler}
            />
          </div>
          <div className="li_div">
            {multiselectdata.asorati.map((item) => (
              <ul>
                <li>{item}</li>
              </ul>
            ))}
          </div>
        </div>

        <div className="diagnoz_div">
          <div className="diagniz_div_left">
            <h6 className="title">Raqobat</h6>
            <DropdownTreeSelect
              data={Mockdata}
              onChange={treeOnchangeHandler3}
              className="mdl-demo"
            />
          </div>
          <div className="li_div">
            {multiselectdata.raqobat.map((item) => (
              <ul>
                <li>{item}</li>
              </ul>
            ))}
          </div>
        </div>
        <div className="diagnoz_div">
          <div className="diagniz_div_left">
            <h6 className="title">Fon</h6>
            <DropdownTreeSelect
              data={Mockdata}
              onChange={treeOnchangeHandler4}
              className="mdl-demo"
            />
          </div>
          <div className="li_div">
            {multiselectdata.fon.map((item) => (
              <ul>
                <li>{item}</li>
              </ul>
            ))}
          </div>
        </div>
        <div className="diagnoz_div">
          <div className="diagniz_div_left">
            <h6 className="title">Bogliq</h6>
            <DropdownTreeSelect
              data={Mockdata}
              onChange={treeOnchangeHandler5}
              className="mdl-demo"
            />
          </div>
          <div className="li_div">
            {multiselectdata.bogliq.map((item) => (
              <ul>
                <li>{item}</li>
              </ul>
            ))}
          </div>
        </div>

        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={Dializa}
        >
          Qo'shish
        </Button>
      </div>
    </Fade>
  </Modal>
</div>; */
