import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import l1 from "./../../../src/assets/icon/l1.svg";
import time from "./../../../src/assets/icon/time.svg";
import { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import { request } from "../../api/request";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ptichka from "../../assets/icon/ptichka.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function BasicTable() {
  const [data, setData] = useState({
    data: [],
    loding: false,
    error: false,
  });
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [loader] = useState(true);
  // useEffect(() => {
  //   request
  //     .get(`/ariza/rmo/yuborish/`, config)
  //     .then(function (res) {
  //       console.log(res, "aziza");
  //       setAriza(res?.data.data);
  //       setLoeder(false);
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     });
  // }, [loader]);
  useEffect(() => {
    request
      .get(`/ariza/lpu/list/`, config)
      .then((data) =>
        setData({
          data: data.data.arizalar.reverse(),
          loading: true,
          error: false,
        })
      )
      .catch(function (err) {
        console.log(err);
      });
  }, []);
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "33%" }} aria-label="simple table">
        <TableHead style={{ "background-color": "#ffff !important" }}>
          <TableRow style={{ "background-color": "#ffff !important" }}>
            <TableCell style={{ width: "120px" }}>
              {t("bildirishnoma.send")}
            </TableCell>
            <TableCell align="center" style={{ width: "112px" }}>
              {t("bildirishnoma.sana")}
            </TableCell>
            <TableCell align="center" style={{ width: "112px" }}>
              {t("bildirishnoma.status")}
            </TableCell>
            <TableCell align="center" style={{ width: "120px" }}>
              {t("bildirishnoma.harakat")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data &&
            data.data.map((item) => (
              <TableRow
                key={item.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {item.kimga}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.vaqti.split("T")[0]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.status === "Javob berilmadi" ? (
                    <div className="status">
                      <img src={time} alt="" />
                    </div>
                  ) : (
                    <div className="status_green">
                      <img src={ptichka} alt="" />
                    </div>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Link to={`/arizasingle/${item.id}`} className="single_info">
                    <img className="delete_icon" src={l1} alt="batafsil" />
                  </Link>
                </StyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
