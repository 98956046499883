import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from '@mui/material/styles';
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from '@mui/material/TableCell';
import { request } from "../../../api/request";
import { useTranslation } from "react-i18next";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
const rows = [
  createData("Frozen yoghurt", ""),
  createData("Ice cream sandwich", ""),
  createData("Ice cream sandwich", ""),
  createData("Ice cream sandwich", ""),
  createData("Ice cream sandwich", ""),
];

export default function BasicTableVitamin() {
  const [vosita, setVosita] = React.useState([]);
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  React.useEffect(() => {
    request
      .get(`omborxona/lpu/malumotlar`, config)
      .then(function (res) {
        console.log(res.data, "setvosita");
        setVosita(res?.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "33%" }} aria-label="simple table">
        <TableHead style={{"background-color":"#ffff !important"}}>
          <TableRow style={{"background-color":"#ffff !important"}}>
            <TableCell style={{ width: "120px" }}>{t("vosita.i")}</TableCell>
            <TableCell align="center" style={{ width: "112px" }}>
            {t("bildirishnoma.q1")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {vosita?.otgan_oy_qoldiq?.map((i, k) => (
            <TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <StyledTableCell  component="th" scope="row">
              {i.vosita_nomi} 
            </StyledTableCell>
            <StyledTableCell  align="center">{i.otgan_oy_qoldiq}</StyledTableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}