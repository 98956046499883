import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { request } from "../../../../api/request";
import "./ariza.scss";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import moment from "moment";
import { toast } from "react-toastify";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Rolarizaobl = () => {
  let todayDate = moment().add(1, "days").format().split("T")[0];
  const [age, setAge] = useState("");
  const [pass, setPass] = useState();
  const [tar, setTar] = useState();
  const [date, setDate] = useState(todayDate);
  const [num, setNum] = useState({
    from: 0,
    to: 0,
  });
  const [num2, setNum2] = useState({
    from: 0,
    to: 0,
  });
  const [numarr, setNumarr] = useState([]);
  const [numarr2, setNumarr2] = useState([]);

  // function numAdd() {
  //   let isExist = numarr.some(
  //     (item) => item.from === num.from && item.to === num.to
  //   );
  //   if (!isExist) setNumarr([...numarr, num]);
  // }
  // function numAdd2() {
  //   let isExist = numarr2.some(
  //     (item) => item.from === num2.from && item.to === num2.to
  //   );
  //   if (!isExist) setNumarr2([...numarr2, num2]);
  // }
  function numAdd() {
    if (num.from >= 0 && num.to > 0) {
      if (
        numarr.every((item) => item.from !== num.from || item.to !== num.to)
      ) {
        if (num.from > num.to || num.from === num.to) {
          toast.error("Bunday yosh toifasi kiritib bo'lmaydi.");
        } else {
          setNumarr([...numarr, num]);
        }
      } else {
        toast.error("Bu yosh toifasi mavjud.");
      }
    } else {
      toast.error("Yosh toifasini tanlang.");
    }
  }
  function numAdd2() {
    if (num2.from >= 0 && num2.to > 0) {
      if (
        numarr2.every((item) => item.from !== num2.from || item.to !== num2.to)
      ) {
        if (num2.from > num2.to || num2.from === num2.to) {
          toast.error("Bunday oy toifasi kiritib bo'lmaydi.");
        } else {
          setNumarr2([...numarr2, num2]);
        }
      } else {
        toast.error("Bu oy toifasi mavjud.");
      }
    } else {
      toast.error("Oy toifasini tanlang.");
    }
  }
  const File = (e) => {
    // setNames(true);
    setPass(e.target.files[0]);
  };

  function numDel(e) {
    let delarr = [];
    delarr.push(...numarr);
    delarr.splice(e, 1);
    setNumarr(delarr);
  }
  function numDel2(e) {
    let delarr = [];
    delarr.push(...numarr2);
    delarr.splice(e, 1);
    setNumarr2(delarr);
  }
  function addColumn(e) {
    if (check && check.length > 0) {
      let ss = check.filter((elem) => elem == e);
      if (ss && ss.length > 0) {
        setCheck(check.filter((item) => item != e));
      } else {
        setCheck([...check, `${e}`]);
      }
    } else {
      setCheck([...check, `${e}`]);
    }
  }
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [loader, setLoeder] = useState(true);
  useEffect(() => {
    request
      .get(`/hududlar/`, config)
      .then(function (res) {
        setLoeder(false);
      })
      .catch(function (err) {});
  }, [loader]);

  const [input, setInput] = useState([]);

  const [check, setCheck] = useState([]);

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      setInput({
        ...input,
        [e.target.name]: String(e.target.checked),
      });
    } else {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    }
  };

  const [opens, setOpens] = useState(false);
  const [ariza, setAriza] = useState({
    isFetched: false,
    data: [],
    error: false,
  });

  useEffect(() => {
    request
      .get(`/user/viloyat/tumanlar/`, config)
      .then(function (res) {
        setAriza({
          isFetched: true,
          data: res.data,
          error: false,
        });
        setLoeder(false);
      })
      .catch(function (err) {
        setAriza({
          isFetched: false,
          data: [],
          error: err,
        });
      });
  }, [loader]);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const { t } = useTranslation();
  function Heets(e) {
    e.preventDefault();
    const fordata = new FormData();
    fordata.append("fayl", pass);
    for (let [key, value] of Object.entries(input)) {
      fordata.append(key, value);
    }
    fordata.append("kimga", JSON.stringify([+check]));
    const numarrsend2 = [];
    numarr.map((item) => {
      numarrsend2.push(`${Number(item.from)}-${Number(item.to)}`);
    });
    fordata.append("yosh_toifa", JSON.stringify(numarrsend2));
    const numarrsend1 = [];
    numarr2.map((item) => {
      numarrsend1.push(`${Number(item.from)}-${Number(item.to)}`);
    });
    fordata.append("oy_toifa", JSON.stringify(numarrsend1));
    fordata.append("qoshimcha", tar);
    fordata.append("muddati", date);

    request
      .post(`/bildirishnoma/viloyat/`, fordata, config)
      .then(function (res) {
        alert("Yuborildi!");

        setLoeder(false);
        navigate("/bildirishnoma_viloyat");
      })
      .catch(function (err) {
        alert("Yuborilmadi!");
      });
    setLoeder(true);
    if (!check[0]) alert("Yuboriluvchi Muassasa Tanlanmadi!");
  }

  const { type } = useParams();

  const [opens1, setOpens1] = useState(false);
  const handleClose1 = () => setOpens1(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 500,
    bgcolor: "background.paper",
    background: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [tuman, setTuman] = useState({
    isFetched: false,
    data: [],
    error: false,
  });
  useEffect(() => {
    request
      .get(`/user/viloyat/muassasalar/`, config)
      .then(function (res) {
        setTuman({
          isFetched: true,
          data: res.data,
          error: false,
        });
        setLoeder(false);
      })
      .catch(function (err) {
        setTuman({
          isFetched: false,
          data: [],
          error: err,
        });
      });
  }, [loader]);

  if (todayDate > date) {
    toast.error("Belgilangan Muddatdan Ortga Surib Bo'llmaydi!");
    setDate(todayDate);
  }
  const fileInput = useRef(null);
  return (
    <div className="rol_ariza">
      <div className="rol_ariza_top">
        <Link to={"/bildirishnoma_viloyat"}>
          <Button variant="contained">{t("bildirishnoma.new.ortga")}</Button>
        </Link>
        <h3 className="rol_ariza_top_title">
          {t("bildirishnoma.new.yaratish")}
        </h3>
      </div>
      <div className="rol_ariza_bottom">
        <div className="rol_ariza_bottom_top">
          <h4 className="rol_ariza_bottom_title">
            {t("bildirishnoma.new.kimdankimga")}
          </h4>
          <div className="rol_ariza_bottom_bigbox">
            <div className="rol_ariza_bottom_block">
              <p className="rol_ariza_bottom_block_desc">
                {t("bildirishnoma.new.kimdan")}
              </p>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label={t("pdf.vs")}
                  disabled
                  variant="outlined"
                />
              </Box>
            </div>
            <div className="rol_ariza_bottom_block"></div>
            {type == "tuman" ? (
              <div className="rol_ariza_bottom_block">
                <p className="rol_ariza_bottom_block_desc">
                  {t("bildirishnoma.new.kimga")}
                </p>

                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={
                    ariza?.data?.muassasalar ? ariza?.data?.muassasalar : []
                  }
                  onChange={(_, value) =>
                    setCheck(value.map((item) => item.id))
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.nomi}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option.id}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nomi}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("pdf.oshp")}
                      placeholder="Qo'shish"
                    />
                  )}
                />
              </div>
            ) : (
              <div className="rol_ariza_bottom_block">
                <p className="rol_ariza_bottom_block_desc">
                  {t("bildirishnoma.new.kimga")}
                </p>
                <div className="muassasa_block">
                  <h6 className="muassasa_title">
                    {t("bildirishnoma.single.vositainf")}
                  </h6>
                  <div className="pos_btn">
                    <Button
                      color="primary"
                      onClick={() => setOpens1(!opens1)}
                      startIcon={
                        opens === true ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                    ></Button>
                  </div>
                  <div
                    className="muassasa_block_inner"
                    style={
                      opens1 === true
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <TextField
                      id="outlined-basic"
                      label="Muassasa qidirish"
                      variant="outlined"
                      onChange={onChange}
                      name="name"
                    />
                    {tuman.data.muassasalar &&
                      tuman.data.muassasalar.map((item, index) => (
                        <div className="muassasa_block_inner_scrool">
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={check.some((el) => el == item.id)}
                                  onChange={(e) => addColumn(e.target.value)}
                                  name="kimga"
                                  color="primary"
                                  value={item.id}
                                />
                              }
                              label={item.nomi}
                            />
                          </FormGroup>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="rol_ariza_flex">
          <div className="rol_ariza_bottom_div">
            <div className="rol_ariza_bottom_div_inner">
              <div className="rol_ariza_bottom_div_inner_block">
                <h4 className="rol_ariza_bottom_title">
                  {t("bildirishnoma.new.boshqa")}
                </h4>
                <div className="rol_ariza_bottom_div_t7">
                  <div className="rol_ariza_bottom_div_inner_block_select">
                    <p className="rol_ariza_bottom_block_desc">
                      {t("bildirishnoma.new.turi")}
                    </p>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" disabled>
                          {t("vosita.bola")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          label="Age"
                          onChange={handleChange}
                          defaultValue="Bolalar va ehtiyojlar bo’ yicha so'rov"
                          disabled
                        >
                          <MenuItem MenuItem value={10}>
                            Bolalar va ehtiyojlar bo’ yicha so'rov
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div className="rol_ariza_bottom_div_inner_block_select">
                    <p className="rol_ariza_bottom_block_desc">
                      {t("bildirishnoma.new.vazifasi")}
                    </p>
                    <TextField
                      type="date"
                      id="outlined-basic"
                      variant="outlined"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="rol_ariza_bottom_div_inner">
              <div className="rol_ariza_bottom_div_inner_block">
                <h4 className="rol_ariza_bottom_title">
                  {t("bildirishnoma.new.inf")}
                </h4>
                <div className="rol_ariza_textarea">
                  <TextareaAutosize
                    aria-label="empty textarea"
                    onChange={(e) => setTar(e.target.value)}
                    placeholder={t("bildirishnoma.new.infP")}
                  />
                </div>
              </div>
            </div>
            <Button variant="contained" onClick={(e) => Heets(e)}>
              {t("input.otp")}
            </Button>
          </div>
          <div className="rol_ariza_bottom_div">
            <div className="rol_ariza_bottom_div_inner">
              <div className="rol_ariza_bottom_div_inner_block">
                <h4 className="rol_ariza_bottom_title">
                  {t("bildirishnoma.new.bolalar")}
                </h4>
                <p className="yil_oy">{t("bola.yosh")}</p>
                <div className="num_block_ariza">
                  <div className="rol_ariza_textarea">
                    <p className="rol_num_ariza">
                      {t("bildirishnoma.new.dan")}
                    </p>
                    <div className="rol_ariza_number_left">
                      {num.from === 0 ? (
                        <button
                          disabled
                          onClick={() => setNum({ ...num, from: num.from - 1 })}
                          className="num_btn"
                        >
                          -
                        </button>
                      ) : (
                        <button
                          onClick={() => setNum({ ...num, from: num.from - 1 })}
                          className="num_btn"
                        >
                          -
                        </button>
                      )}
                      <p className="num_title">{num.from}</p>
                      <button
                        onClick={() => setNum({ ...num, from: num.from + 1 })}
                        className="num_btn"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="rol_ariza_textarea">
                    <p className="rol_num_ariza">
                      {t("bildirishnoma.new.gacha")}
                    </p>
                    <div className="rol_ariza_number_left">
                      {num.to === 0 ? (
                        <button
                          disabled
                          onClick={() => setNum({ ...num, to: num.to - 1 })}
                          className="num_btn"
                        >
                          -
                        </button>
                      ) : (
                        <button
                          onClick={() => setNum({ ...num, to: num.to - 1 })}
                          className="num_btn"
                        >
                          -
                        </button>
                      )}
                      <p className="num_title">{num.to}</p>
                      <button
                        onClick={() => setNum({ ...num, to: num.to + 1 })}
                        className="num_btn"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <Button
                    onClick={numAdd}
                    variant="contained"
                    startIcon={<AddIcon />}
                  ></Button>
                </div>
                <div className="age_num_block">
                  {numarr.map((item, index) => (
                    <div className="age_num_block_inner">
                      <p>{item.from}</p>
                      <p>-</p>
                      <p>{item.to}</p>
                      <p>-</p>
                      <p>{t("bola.yosh")}</p>
                      <button onClick={(e) => numDel(index)}>x</button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="rol_ariza_bottom_div_inner_block">
                <p className="yil_oy">{t("vosita.oy")}</p>
                <div className="num_block_ariza">
                  <div className="rol_ariza_textarea">
                    <p className="rol_num_ariza">
                      {t("bildirishnoma.new.dan")}
                    </p>
                    <div className="rol_ariza_number_left">
                      {num2.from === 0 ? (
                        <button
                          disabled
                          onClick={() =>
                            setNum2({ ...num2, from: num2.from - 1 })
                          }
                          className="num_btn"
                        >
                          -
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            setNum2({ ...num2, from: num2.from - 1 })
                          }
                          className="num_btn"
                        >
                          -
                        </button>
                      )}
                      <p className="num_title">{num2.from}</p>
                      <button
                        onClick={() =>
                          setNum2({ ...num2, from: num2.from + 1 })
                        }
                        className="num_btn"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="rol_ariza_textarea">
                    <p className="rol_num_ariza">
                      {t("bildirishnoma.new.gacha")}
                    </p>
                    <div className="rol_ariza_number_left">
                      {num2.to === 0 ? (
                        <button
                          disabled
                          onClick={() => setNum2({ ...num2, to: num2.to - 1 })}
                          className="num_btn"
                        >
                          -
                        </button>
                      ) : (
                        <button
                          onClick={() => setNum2({ ...num2, to: num2.to - 1 })}
                          className="num_btn"
                        >
                          -
                        </button>
                      )}
                      <p className="num_title">{num2.to}</p>
                      <button
                        onClick={() => setNum2({ ...num2, to: num2.to + 1 })}
                        className="num_btn"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <Button
                    onClick={numAdd2}
                    variant="contained"
                    startIcon={<AddIcon />}
                  ></Button>
                </div>
                <div className="age_num_block">
                  {numarr2.map((item, index) => (
                    <div className="age_num_block_inner">
                      <p>{item.from}</p>
                      <p>-</p>
                      <p>{item.to}</p>
                      <p>-</p>
                      <p>{t("vosita.oy")}</p>
                      <button onClick={(e) => numDel2(index)}>x</button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="rol_ariza_bottom_div_inner">
              <div className="sarflov_top_blocks">
                <h4 className="sarflov_block_title">
                  {t("bildirishnoma.new.fail")}
                </h4>

                {pass ? (
                  <Button
                    className="delets_icons_file"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => {
                      fileInput.current.value = null;
                      setPass(null);
                    }}
                    variant="contained"
                    type="button"
                  >
                    {t("bildirishnoma.new.del")}
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <input
                onChange={(e) => File(e)}
                type="file"
                id="files"
                ref={fileInput}
                className="file_add_input"
              />
              <label className="download_label" htmlFor="files">
                <div className="files_block_title">
                  <p className="files_add_title">
                    {pass
                      ? t("bildirishnoma.new.failinf1")
                      : t("bildirishnoma.new.failinf")}
                  </p>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          open={opens1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h5 className="err_text">Xatolik yuz berdi</h5>
            <p className="err_title">
              Bolalar yoshi va toifasini tanlashda xatolik yuz berdi iltimos
              quyidagi formula bo'yicha kiriting
            </p>
            <div className="yosh_primer">
              <div className="age_num_block_inner">
                <p>{1}</p>
                <p>-</p>
                <p>{2}</p>
                <p>-</p>
                <p>{t("vosita.oy")}</p>
              </div>
              <div className="age_num_block_inner">
                <p>{3}</p>
                <p>-</p>
                <p>{5}</p>
                <p>-</p>
                <p>{t("vosita.oy")}</p>
              </div>
              <div className="age_num_block_inner">
                <p>{6}</p>
                <p>-</p>
                <p>{10}</p>
                <p>-</p>
                <p>{t("vosita.oy")}</p>
              </div>
              <div className="age_num_block_inner">
                <p>{11}</p>
                <p>-</p>
                <p>{15}</p>
                <p>-</p>
                <p>{t("vosita.oy")}</p>
              </div>
            </div>
            <div className="yosh_primer">
              <div className="age_num_block_inner">
                <p>{1}</p>
                <p>-</p>
                <p>{2}</p>
                <p>-</p>
                <p>{t("bola.yosh")}</p>
              </div>
              <div className="age_num_block_inner">
                <p>{3}</p>
                <p>-</p>
                <p>{5}</p>
                <p>-</p>
                <p>{t("bola.yosh")}</p>
              </div>
              <div className="age_num_block_inner">
                <p>{6}</p>
                <p>-</p>
                <p>{10}</p>
                <p>-</p>
                <p>{t("bola.yosh")}</p>
              </div>
              <div className="age_num_block_inner">
                <p>{11}</p>
                <p>-</p>
                <p>{15}</p>
                <p>-</p>
                <p>{t("bola.yosh")}</p>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Rolarizaobl;
