import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Fade,
  InputAdornment,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { get } from "lodash";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { request } from "../../../api/request";
import l1 from "../../../assets/icon/l1.svg";
import "./ariza.scss";
import dayjs from "dayjs";
import moment from "moment";
export default function Ariza() {
  const { t } = useTranslation();
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [searchValue, setSearchValue] = useState("");
  const [noti, setNoti] = useState(false);
  const [notificationn, setNotificationn] = useState({
    state: "",
    text: "",
  });
  const [searchParams] = useSearchParams();

  const handleClick = () => {
    setNoti(true);
  };

  const handlenoti = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNoti(false);
  };

  const token = window.localStorage.token;
  const formData = new FormData();
  formData.append("token", token);
  const [loader, setLoeder] = useState(true);

  const classes = {
    table: {
      minWidth: 700,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      padding: "10px",
      width: "80%",
      margin: "30px auto 0 auto",
      borderRadius: "12px",
    },
    formControl: {
      margin: "1px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    button: {
      padding: "8px",
      borderRadius: "12px",
    },
  };
  const [open2, setOpen2] = useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };

  const [newarr, setNewarr] = useState([]);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [erkins1, setErkins1] = useState({
    isFetched: false,
    data: {},
    error: null,
  });

  const [yub, setYub] = useState();

  useEffect(() => {
    request
      .get(`/ariza/rmo/birlashtirish/`, config)
      .then(function (res) {
        setErkins1({
          isFetched: true,
          data: res.data,
          error: false,
        });
        setNewarr(res.data);
        console.log(res.data);
        console.log();
      })
      .catch(function (err) {
        setErkins1({
          isFetched: false,
          data: [],
          error: err,
        });

        setLoeder(true);
      });
    request
      .get("/ariza/rmo/yuborish/", config)
      .then(function (res) {
        setYub(res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [loader]);

  useEffect(() => {
    request
      .get(`/ariza/rmo/`, config)
      .then(function (res) {
        setNewarr(res.data);
        console.log(res.data, "resfdas");
      })
      .catch(function (err) {
        setLoeder(true);
      });
  }, [loader]);
  const [tab, setTab] = useState("qabulqilingan");
  const [checkboxs, setCheckboxs] = useState(false);
  const [checkblock, setCheckblock] = useState({});
  const StatusChanger = (e) => {
    const formData = new FormData();
    formData.append("ariza", e);
    formData.append("status", "O'qildi");
    request.post("/ariza/lpu/changestatus/", formData, config);
  };

  function addColumn(e, id) {
    let arizalar = [];
    if (checkblock && checkblock?.bildirishnoma) {
      if (checkblock.bildirishnoma == id) {
        let ss = checkblock.arizalar?.filter((elem) => elem == e);
        if (ss && ss?.length > 0) {
          arizalar.push(...checkblock.arizalar.filter((item) => item != e));
          if (arizalar?.length > 0) {
            setCheckblock({ ...checkblock, arizalar: arizalar });
          } else {
            setCheckblock({ bildirishnoma: null });
          }
        } else {
          arizalar.push(...checkblock.arizalar);
          arizalar.push(e);
          setCheckblock({ ...checkblock, arizalar: arizalar });
        }
      } else {
        setNotificationn({
          state: "error",
          text: "Faqatgina bir xil idli bildirishnomalarni tanlash mumkin.",
        });
        handleClick(true);
      }
    } else {
      arizalar.push(e);
      setCheckblock({ bildirishnoma: id, arizalar: arizalar });
    }
  }
  function Send() {
    const formmdata = new FormData();
    formmdata.append("bildirishnoma", checkblock.bildirishnoma);
    formmdata.append("arizalar", JSON.stringify(checkblock.arizalar));
    console.log(formmdata);
    request
      .post(`ariza/rmo/birlashtirish/`, formmdata, config)
      .then((res) => {
        setNotificationn({
          state: "success",
          text: `Arizalar birlashtirildi`,
        });
        setCheckboxs(false);

        setLoeder(false);
        handleClick(true);
      })
      .catch(function (err) {
        setNotificationn({
          state: "error",
          text: get(err, "response.data.message"),
        });
        setLoeder(false);
        handleClick(true);
      });

    setLoeder(true);
  }
  let statusCount01 = 0;
  newarr.arizalar?.forEach((item) => {
    if (item.status === "Yangi") {
      statusCount01++;
    }
  });
  const navigate = useNavigate();
  const applicationCount = () => {
    switch (tab) {
      case "qabulqilingan":
        return newarr?.arizalar?.length;
      case "birar":
        return erkins1?.data?.birlashtirilgan_arizalar?.length;
      case "yuborilgan":
        return yub?.length;
      default:
        return 0;
    }
  };

  const handleTabChange = (tab) => {
    setTab(tab);
    navigate(`/arizalar?tab=${tab}`);
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      setTab(searchParams.get("tab"));
    }
  }, []);

  return (
    <div className="ariza">
      <div className="ariza_top">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 className="ariza_top_title">
            {t("bildirishnoma.allariza")}: {applicationCount()}
          </h4>
          <TextField
            className="search-ariza"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t("bildirishnoma.plac")}
            style={{ marginLeft: "40px" }}
            id="standard-basic"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ position: "absolute", right: "18px" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {tab === "qabulqilingan" && (
          <div className="create_ariza_btn">
            {checkboxs === true ? (
              <div>
                <Button
                  style={{ marginRight: "15px" }}
                  onClick={() => setCheckboxs(!checkboxs)}
                  variant="green"
                  startIcon={<CloseIcon />}
                >
                  {t("bildirishnoma.single.bekor")}
                </Button>
              </div>
            ) : null}
            {checkboxs === false ? (
              <Button
                onClick={() => setCheckboxs(!checkboxs)}
                variant="contained"
                startIcon={<AddIcon />}
              >
                {t("vosita.birlash")}
              </Button>
            ) : (
              <Button
                onClick={() => Send()}
                variant="contained"
                startIcon={<CheckIcon />}
              >
                {t("vosita.birlash")}
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="ariza_bottom">
        <div className="ariza_bottom_top">
          <Button
            onClick={() => handleTabChange("qabulqilingan")}
            style={
              tab === "qabulqilingan"
                ? { color: "#1464C0", borderBottom: "2px solid #1464C0" }
                : { color: "black" }
            }
          >
            {t("input.qabul")}
          </Button>
          {statusCount01 ? (
            <span
              style={{
                fontWeight: "bold",
                background: "blue",
                borderRadius: "50%",
                width: "22px",
                height: "22px",
                color: "white",
                textAlign: "center",
                paddingTop: "4px",
                fontSize: "14px",
              }}
            >
              {statusCount01}
            </span>
          ) : (
            ""
          )}

          <Button
            onClick={() => handleTabChange("birar")}
            style={
              tab === "birar"
                ? { color: "#1464C0", borderBottom: "2px solid #1464C0" }
                : { color: "black" }
            }
          >
            {t("vosita.bir")}
          </Button>

          <Button
            onClick={() => handleTabChange("yuborilgan")}
            style={
              tab === "yuborilgan"
                ? { color: "#1464C0", borderBottom: "2px solid #1464C0" }
                : { color: "black" }
            }
          >
            {t("input.yubor")}
          </Button>
        </div>
        <div className="ariza_bottom_bottom">
          {tab === "qabulqilingan" && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "white" }}>
                    <TableCell>{t("bildirishnoma.single.soni")}</TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      {t("bildirishnoma.bil1")}
                      {t("       ID")}
                      
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      ID
                    </TableCell>

                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      {t("bildirishnoma.single.muas")}
                    </TableCell>

                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      {t("bildirishnoma.sana")}
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {t("bildirishnoma.single.status")}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {t("bildirishnoma.harakat")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newarr.arizalar &&
                    newarr?.arizalar
                      ?.filter((item) =>
                        item.kimdan
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      ?.map((item, index) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell align="center">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    width: "60px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {checkboxs === true ? (
                                    <input
                                      className="check_box_inp_inner"
                                      type="checkbox"
                                      name=""
                                      checked={checkblock?.arizalar?.includes(
                                        item?.id
                                      )}
                                      onClick={(e) =>
                                        addColumn(
                                          item.id,
                                          item.bildirishnoma_id
                                        )
                                      }
                                    />
                                  ) : null}
                                  <span>{index + 1}</span>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                {item.bildirishnoma_id}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                {item.id}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                {item.kimdan}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                {moment(item.vaqti).format("DD-MM-YYYY")}
                              </TableCell>
                              {/* <TableCell
                                style={{
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                {item.bildirishnoma_id} 
                              </TableCell> */}
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                <button
                                  className={
                                    item.status !== "O'qildi"
                                      ? "status_btn"
                                      : "status_btn--not"
                                  }
                                >
                                  {item.status !== "O'qildi"
                                    ? t("bildirishnoma.arstatus.yangi")
                                    : t("vosita.oqil")}
                                </button>
                              </TableCell>

                              <TableCell align="center">
                                <div className="button_modal button_modal_1">
                                  <Button
                                    onClick={() => {
                                      StatusChanger(item.id);
                                      navigate(`/rolariza/${item.id}`);
                                    }}
                                    Link
                                    className="single_info"
                                  >
                                    <img
                                      className="delete_icon"
                                      src={l1}
                                      alt="batafsil"
                                    />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {tab === "birar" && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "white" }}>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {t("bildirishnoma.single.soni")}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      ID
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      {t("bildirishnoma.bil1")} ID
                    </TableCell>

                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      {t("bildirishnoma.sana")}
                    </TableCell>

                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      {t("input.vaqt")}
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {t("bildirishnoma.harakat")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {erkins1?.data &&
                    erkins1?.data?.birlashtirilgan_arizalar
                      ?.filter(
                        (item) =>
                          item.sana
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          item.bildirishnoma_id.toString().includes(searchValue)
                      )
                      ?.map((item, index) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell align="left">
                                {index + 1}
                                <div className="ariza_bgc"></div>
                              </TableCell>
                              <TableCell align="center">{item.id}</TableCell>
                              <TableCell align="center">
                                {item.bildirishnoma_id}
                              </TableCell>
                              <TableCell align="center">{item.sana}</TableCell>
                              <TableCell align="center">{item.date}</TableCell>
                              <TableCell align="center">
                                <div className="button_modal button_modal_1">
                                  <Link
                                    Link
                                    to={`/rolarizaRmo/${item.id}`}
                                    className="single_info"
                                  >
                                    <img
                                      className="delete_icon"
                                      src={l1}
                                      alt="batafsil"
                                    />
                                  </Link>
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {tab === "yuborilgan" && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "white" }}>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {t("bildirishnoma.single.soni")}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      ID
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      {t("bildirishnoma.single.kimdan")}
                    </TableCell>

                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                      align="center"
                    >
                      {t("bildirishnoma.sana")}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {t("bildirishnoma.status")}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {t("bildirishnoma.harakat")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {yub &&
                    yub
                      ?.filter(
                        (item) =>
                          item.kimdan
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          item.vaqti
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                      )
                      ?.map((item, index) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell align="left">
                                {index + 1}
                                <div className="ariza_bgc"></div>
                              </TableCell>
                              <TableCell
                                style={
                                  {
                                    // fontWeight: "bold",
                                  }
                                }
                                align="center"
                              >
                                {item.id}
                              </TableCell>
                              <TableCell
                                style={
                                  {
                                    // fontWeight: "bold",
                                  }
                                }
                                align="center"
                              >
                                {item.kimdan}
                              </TableCell>
                              <TableCell
                                style={
                                  {
                                    // fontWeight: "bold",
                                  }
                                }
                                align="center"
                              >
                                {dayjs(item.vaqti).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell
                                style={
                                  {
                                    // fontWeight: "bold",
                                  }
                                }
                                align="center"
                              >
                                <button
                                  className={
                                    item.status === "Yuborildi"
                                      ? "status_btn"
                                      : "status_btn--not"
                                  }
                                >
                                  {t(item.status)}
                                </button>
                              </TableCell>

                              <TableCell align="center">
                                <div className="button_modal button_modal_1">
                                  <Link
                                    Link
                                    to={`/rolarizaRmoYuborilgan/${item.id}`}
                                    className="single_info"
                                  >
                                    <img
                                      className="delete_icon"
                                      src={l1}
                                      alt="batafsil"
                                    />
                                  </Link>
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        <div className="modal_one_99">
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal_one}
            open={open2}
            onClose={handleClose2}
            closeAfterTransition
            BackdropProps={{
              timeout: 400,
            }}
            style={{
              marginTop: "200px",
              width: "600px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Fade in={open2}>
              <div style={classes.paper}>
                <div className="zayavka_block">
                  <Button
                    style={{
                      color: "black",
                      textAlign: "right",
                      margin: "0 0 auto auto",
                      display: "flex",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => handleClose2()}
                  ></Button>
                  <h4 className="zayavka_title">{t("modalariza.arizaturi")}</h4>
                  <div className="delete_btn_group">
                    <Link to={"/sarflov"} className="jayavka_btn">
                      Sarflov vositalar
                    </Link>
                    <Link to={"/apelatsion"} className="jayavka_btn">
                      Jihozlar va ehtiyot qismlar
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
        <Snackbar
          Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={noti}
          autoHideDuration={6000}
          onClose={handlenoti}
        >
          <Alert
            Alert
            onClose={handlenoti}
            severity={notificationn.state}
            sx={{
              width: "100%",
            }}
          >
            {notificationn.text}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
