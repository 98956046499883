import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Fade,
  InputAdornment,
  Modal,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import useGet from "hooks/useGet";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { request } from "../../../../../api/request";
import "./bildirish.scss";
import RecievedNotificationTable from "./components/RecievedNotificationTable";
import SendedNotificationTable from "./components/SendedNotificationTable";
import SystematicNotificationTable from "./components/SystematicNotificationTable";
export default function Tablebildirish() {
  const [searchValue, setSearchValue] = useState("");
  const token = window.localStorage.token;
  const [mainTab, setMainTab] = useState(1);
  const [mainTabInner1, setMainTabInner1] = useState(1);
  const [mainTabInner2, setMainTabInner2] = useState(1);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { t } = useTranslation();

  const classes = {
    table: {
      minWidth: 700,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      padding: "10px",
      width: "80%",
      margin: "30px auto 0 auto",
      borderRadius: "12px",
    },
    formControl: {
      margin: "1px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    button: {
      padding: "8px",
      borderRadius: "12px",
    },
  };
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = (e) => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const StatusChangerErkin = (e, kimdan) => {
    const formData = new FormData();
    formData.append("id", e);
    formData.append("status", "O'qildi");
    formData.append(
      "kimdan",
      ["MOH", "UzMedImpeks", "VSSB"].includes(kimdan) ? kimdan : "VSSB"
    );
    request.post("/bildirishnoma/tuman/erkin/", formData, config);
  };

  const StatusChangerBolalar = (e) => {
    const formData = new FormData();
    formData.append("id", e);
    formData.append("status", "O'qildi");
    request.put("/bildirishnoma/tumanga/", formData, config);
  };
  const {
    data: { data: recivedNotification1 = [] },
  } = useGet({
    url: "/bildirishnoma/tuman/erkin/",
  });
  const {
    data: { data: recivedNotification2 = [] },
  } = useGet({
    url: "/bildirishnoma/tumanga/",
  });

  const {
    data: { data: sendedNotification1 = [] },
  } = useGet({
    url: "/bildirishnoma/erkin/",
  });
  const {
    data: { data: sendedNotification2 = [] },
  } = useGet({
    url: "/bildirishnoma/tuman/",
  });

  const { data: systematicNotification } = useGet({
    url: "/omborxona/tizimli/xabarnoma/ttb",
  });

  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();

  const handleMainTabChange = (v) => {
    setMainTab(v);
    navigate(
      `${pathname}?${qs.stringify({
        ...qs.parse(search.slice(1)),
        mtab: v,
        tab: 1,
      })}`
    );
  };
  const handleInnerTabChange = (tab) => {
    setMainTabInner1(tab);
    setMainTabInner2(tab);
    navigate(
      `${pathname}?${qs.stringify({
        ...qs.parse(search.slice(1)),
        tab: tab,
      })}`
    );
  };

  useEffect(() => {
    if (searchParams.get("mtab")) {
      setMainTab(+searchParams.get("mtab"));
    }
    if (searchParams.get("tab")) {
      setMainTabInner1(+searchParams.get("tab"));
      setMainTabInner2(+searchParams.get("tab"));
    }
  }, [searchParams]);

  const filterNotification = useCallback(
    (data = []) => {
      return data.filter(
        (item) =>
          item.kimdan.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.kimga.toLowerCase().includes(searchValue.toLowerCase())
      );
    },
    [searchValue]
  );
  const filterSystematicNotification = useCallback(
    (data = []) => {
      return data.filter((item) =>
        item.kirim_chiqim.kimdan_kelgan
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    },
    [searchValue]
  );

  return (
    <dvi className="ariza">
      <div className="ariza_top">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 className="ariza_top_title">
            {t("Jami bildirishnomalar soni")}:{" "}
            {mainTab === 1 &&
              mainTabInner1 === 1 &&
              recivedNotification1?.length}
            {mainTab === 1 &&
              mainTabInner1 === 2 &&
              recivedNotification2?.length}
            {mainTab === 2 &&
              mainTabInner2 === 1 &&
              sendedNotification1?.length}
            {mainTab === 2 &&
              mainTabInner2 === 2 &&
              sendedNotification2?.length}
            {mainTab === 3 && systematicNotification.length}
          </h4>
          <TextField
            className="search-ariza"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t("Bildirishnoma Qidirish")}
            style={{ marginLeft: "40px" }}
            id="standard-basic"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ position: "absolute", right: "18px" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="create_ariza_btn">
          <Button
            onClick={() => handleOpen2()}
            variant="contained"
            startIcon={<AddIcon />}
          >
            {t("bildirishnoma.add")}
          </Button>
        </div>
      </div>
      <div className="ariza_bottom">
        <TabContext value={mainTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              style={{ backgroundColor: "#fff" }}
              value={mainTab}
              onChange={(e, v) => handleMainTabChange(v)}
              aria-label="basic tabs example"
            >
              <Tab value={1} label={t("qabxar")} />
              <Tab value={2} label={t("yubxar")} />
              <Tab value={3} label={t("vosita.tizim")} />
            </Tabs>
            <TabPanel value={1} style={{ padding: 0 }}>
              <TabContext value={mainTabInner1}>
                <Tabs
                  style={{ backgroundColor: "#fff" }}
                  value={mainTabInner1}
                  onChange={(e, v) => handleInnerTabChange(v)}
                  aria-label="basic tabs example"
                >
                  <Tab
                    value={1}
                    label={
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {t("vosita.erkin")}
                        {/* <span
                          style={{
                            fontWeight: "bold",
                            background: "blue",
                            borderRadius: "50%",
                            width: "22px",
                            height: "22px",
                            color: "white",
                            textAlign: "center",
                            paddingTop: "4px",
                            fontSize: "14px",
                          }}
                        >
                          0
                        </span> */}
                      </div>
                    }
                  />

                  <Tab value={2} label={t("vosita.bola")} />
                </Tabs>
                <TabPanel value={1} style={{ padding: "0" }}>
                  <RecievedNotificationTable
                    data={filterNotification(recivedNotification1)}
                    path="ttberkin"
                    handleStatusChange={StatusChangerErkin}
                  />
                </TabPanel>
                <TabPanel value={2} style={{ padding: "0" }}>
                  <RecievedNotificationTable
                    data={filterNotification(recivedNotification2)}
                    path="singlettb"
                    handleStatusChange={StatusChangerBolalar}
                  />
                </TabPanel>
              </TabContext>
            </TabPanel>
            <TabPanel value={2} style={{ padding: 0 }}>
              <TabContext value={mainTabInner2}>
                <Tabs
                  style={{ backgroundColor: "#fff" }}
                  value={mainTabInner2}
                  onChange={(e, v) => handleInnerTabChange(v)}
                  aria-label="basic tabs example"
                >
                  <Tab
                    value={1}
                    label={
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {t("vosita.erkin")}
                        {/* <span
                          style={{
                            fontWeight: "bold",
                            background: "blue",
                            borderRadius: "50%",
                            width: "22px",
                            height: "22px",
                            color: "white",
                            textAlign: "center",
                            paddingTop: "4px",
                            fontSize: "14px",
                          }}
                        >
                          0
                        </span> */}
                      </div>
                    }
                  />

                  <Tab value={2} label={t("vosita.bola")} />
                </Tabs>
                <TabPanel value={1} style={{ padding: 0 }}>
                  <SendedNotificationTable
                    data={filterNotification(sendedNotification1)}
                    path="ttbcom"
                  />
                </TabPanel>
                <TabPanel value={2} style={{ padding: 0 }}>
                  <SendedNotificationTable
                    data={filterNotification(sendedNotification2)}
                    path="ttbbol"
                  />
                </TabPanel>
              </TabContext>
            </TabPanel>
            <TabPanel value={3} style={{ padding: 0 }}>
              <SystematicNotificationTable
                data={filterSystematicNotification(systematicNotification)}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </div>

      <div className="modal_one_99">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal_one}
          open={open2}
          onClose={handleClose2}
          closeAfterTransition
          BackdropProps={{
            timeout: 400,
          }}
          style={{
            marginTop: "200px",
            width: "600px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Fade in={open2}>
            <div style={classes.paper}>
              <div className="zayavka_block">
                <Button
                  style={{
                    color: "black",
                    textAlign: "right",
                    margin: "0 0 auto auto",
                    display: "flex",
                  }}
                  startIcon={<CloseIcon />}
                  onClick={() => handleClose2()}
                ></Button>
                <h4 className="zayavka_title">{t("modalariza.arizaturi")}</h4>
                <div className="delete_btn_group">
                  <Link to={"/rmoariza/null"} className="jayavka_btn">
                    {t("vosita.bola")}
                  </Link>
                  <Link to={"/rmoerkin/null/erkin"} className="jayavka_btn">
                    {t("vosita.erkin")}
                  </Link>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </dvi>
  );
}
