import {
	Button,
	Checkbox,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { request } from "../../../../../api/request";
import Modalchild from "./modalchild";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function getStyles(name, personName, theme) {
	return {
		fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
	};
}
function Mohchildren() {
	const token = window.localStorage.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const theme = useTheme();
	const [personName, setPersonName] = useState([]);
	const [personName2, setPersonName2] = useState([]);
	const [personName3, setPersonName3] = useState([]);
	const [region, setRegion] = useState([]);
	const [monthfirst, setMonthfirst] = useState();
	const [monthtwo, setMonthtwo] = useState();
	const [year, setYear] = useState();
	const [year2, setYear2] = useState();
	const [data, setData] = useState([]);
	const [arr, setArr] = useState([]);
	const [institution, setInsitution] = useState([]);
	function fetChregions() {
		request.get("/hududlar/", config).then(data => setRegion(data.data));
	}
	useEffect(() => {
		fetChregions();
	}, []);
	const filterRegion = personName2?.map(el => personName.map(els => region[els][el])) || [];
	const institutionFilter =
		personName3?.map(el => filterRegion.map(els => els[0][el]).filter(item => item !== undefined)[0]) || [];
	const update = () => {
		if (arr.length < 1) {
			toast.error("Iltimos oy va yosh toifalarini  kiriting!");
			return;
		}
		const body = {
			viloyat: personName && personName.map(el => region[el].viloyat_id),
			tuman: personName2[0] ? filterRegion.map(el => el.map(els => els.tuman_id)[0]) : null,
			muassasa: institutionFilter[0] ? institutionFilter.map(el => el.id) : null,
			...(monthfirst && { oy: arr.map(el => `${el.monthFirst},${el.monthTwo}`.split(",").map(el => +el)) }),
			...(year && { yil: arr.map(el => `${el.yearOne},${el.yearTwo}`.split(",").map(el => +el)) }),
		};
		request
			.post("/bolalar/soni", body, config)
			.then(data => setData(data.data))
			.catch(err => {
				if (err.response.status === 500) {
					return toast.error("Ma'lumotlar qato yuborildi. Yoki serverda xatolik yuz berdi");
				}
				toast.error(err.response.data.errors);
			});
	};

	const handleChange = event => {
		const {
			target: { value },
    } = event;
    if (value != personName[0]) {
      		setInsitution([]);
					setPersonName2([]);
					setPersonName3([]);
    }
		if (value) {
			setPersonName(typeof value === "string" ? value.split(",") : value);
		}
	};
	const handleChange2 = event => {
		const {
			target: { value },
		} = event;
		if (value) {
			setPersonName2(
				// On autofill we get a stringified value.
				typeof value === "string" ? value.split(",") : value
			);
		}
	};
	const handleChange3 = event => {
		const {
			target: { value },
		} = event;
		if (value) {
			setPersonName3(
				// On autofill we get a stringified value.
				typeof value === "string" ? value.split(",") : value
			);
		}
	};
	const Data = personName
		?.map(el => region[el])
		.map(el => {
			return Object.keys(el);
		})
		.map(els => els.filter(el => el !== "viloyat_id"));

	useEffect(() => {
		setInsitution(
			filterRegion
				? filterRegion.map(
						els => els?.map(el => (el ? Object.keys(el) : [])).map(els => els.filter(el => el !== "tuman_id")[0])[0]
				  )
				: []
		);
	}, [personName, personName2])
		
  

	const { t } = useTranslation();
	return (
		<>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat( auto-fit, minmax(150px, 1fr) )",
					gap: "1rem",
					padding: "2vmax",
				}}
			>
				<FormControl>
					<InputLabel id='demo-multiple-name-label'>{t("bildirishnoma.viloyat")}</InputLabel>
					<Select
						labelId='demo-multiple-name-label'
						id='demo-multiple-name'
						value={personName}
						onChange={handleChange}
						input={<OutlinedInput label='Name' />}
						MenuProps={MenuProps}
					>
						{region &&
							Object.keys(region).map(name => (
								<MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
									{name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel id='demo-multiple-name-label'>{t("bildirishnoma.tuman1")}</InputLabel>
					<Select
						labelId='demo-multiple-name-label'
						id='demo-multiple-name'
						multiple
            value={personName2}
						onChange={handleChange2}
						input={<OutlinedInput label='Name' />}
						MenuProps={MenuProps}
            disabled={!personName?.every((el,idx) => {
              return !!Data[idx]?.length
            })}
					>
						{/* <MenuItem > 
							<Checkbox checked={true} />
						  Barchasi
						</MenuItem> */}
						{personName &&
							personName.map((el, index) => {
								return (
									Data[index] &&
									Data[index].map(name => (
										<MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
											<Checkbox checked={personName2.indexOf(name) > -1} />
											{name}
										</MenuItem>
									))
								);
							})}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel style={{ backgroundColor: "#E7EBF2" }} id='demo-multiple-name-label'>
						{t("bildirishnoma.single.muas")}
					</InputLabel>
					<Select
						labelId='demo-multiple-name-label'
						id='demo-multiple-name'
						multiple
						value={personName3}
						onChange={handleChange3}
						input={<OutlinedInput label='Name' />}
						MenuProps={MenuProps}
						disabled={!personName2[0]}
					>
						{institution &&
							institution.map(name => (
								<MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
									<Checkbox checked={personName3.indexOf(name) > -1} />
									{name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<Modalchild
					monthfirst={monthfirst}
					monthtwo={monthtwo}
					year={year}
					year2={year2}
					setMonthfirst={setMonthfirst}
					setMonthtwo={setMonthtwo}
					setYear={setYear}
					setYear2={setYear2}
					setArr={setArr}
					arr={arr}
				/>
				<Button onClick={update} variant='contained'>
					{t("Saralash")}
				</Button>
			</div>
			<div style={{ marginTop: "24px", paddingRight: "54px", paddingLeft: "54px" }}>
				<TableContainer component={Paper}>
					<Table aria-label='customized table'>
						<TableHead>
							<TableRow>
								<TableCell>{t("bildirishnoma.single.soni")}</TableCell>
								<TableCell>{t("sbola.hudud")}</TableCell>
								<TableCell>{t("Umumiy")} </TableCell>
								<TableCell>{t("Oraliqlar")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map(item => {
								return (
									item.data &&
									item.data.map((el, index) => {
										return (
											<TableRow>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{el.viloyat ? el.viloyat : el.tuman ? el.tuman : el.muassasa}</TableCell>
												<TableCell>{el.soni}</TableCell>
												<TableCell>
													{item.oraliqlar && item.oraliqlar.oraliq.map(els => <span>{els}-</span>)}
													{item.oraliqlar.type === "yil" ? t("Yosh") : item.oraliqlar.type}
												</TableCell>
											</TableRow>
										);
									})
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
}

export default Mohchildren;
