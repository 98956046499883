import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  Button,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import { get } from "lodash";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { request } from "../../api/request";
import kirim from "../../assets/icon/kirim2.svg";
import l1 from "../../assets/icon/l1.svg";

const Storekirim = () => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0);
  const [close, setClose] = useState(false);
  const [cur5, setCur5] = useState();
  const [cur6, setCur6] = useState();

  const token = window.localStorage.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  useEffect(() => {
    request
      .get("/omborxona/lpu/kirim/chiqim/malumotlar", config)
      .then((data) => setData(data.data));
  }, []);
  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };

  const classes = {
    table: {
      minWidth: 700,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      padding: "50px",
      width: "80%",
      margin: "30px auto 0 auto",
    },
    formControl: {
      margin: "1px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    button: {
      padding: "8px",
      borderRadius: "12px",
    },
    button2: {
      backgroundColor: "#F69641",
    },
  };
  const Get = (index) => {
    setCur5(data.kirim[index]);
    setClose(true);
  };
  const Min = (e) => {
    setCur6(data.chiqim.find((el) => el.bolalar.id === e));
    setClose(true);
  };

  const expansesExcelHeader = (key) => {
    const response = [];
    let max = 0;
    get(data, key, []).forEach((item) => {
      if (max < get(item, "vositalar", []).length) {
        max = get(item, "vositalar", []).length;
      }
    });
    for (let i = 0; i < max; i++) {
      response.push(
        {
          label: "Vosita turi",
          key: `vositalar[${i}].vosita_turi.nomi`,
        },
        {
          label: "Vosita nomi",
          key: `vositalar[${i}].vosita_nomi.nomi`,
        },
        {
          label: "Vosita miqdori",
          key: `vositalar[${i}].vosita_miqdori`,
        }
      );
    }
    return response;
  };

  const { t } = useTranslation();
  return (
    <div className="prihod">
      <div className="prihod_top">
        <div className="prihod_top_inner">
          <Link to="/skladM">
            <Button startIcon={<ArrowBackIcon />} variant="contained">
              {t("bildirishnoma.single.ortga")}
            </Button>
          </Link>
          <h4 className="monitoring_top_inner_title">{t("input.a1")}</h4>
        </div>
        <div className="excel_bl">
          <Button
            variant="contained"
            size="large"
            className={""}
            startIcon={<DescriptionIcon />}
          >
            {value === 0 && data.kirim && (
              <CSVLink
                filename="Kirimlar"
                headers={[
                  { label: "ID", key: "kirim.id" },
                  { label: "Izoh", key: "kirim.comment" },
                  { label: "Kimdan kelgan", key: "kirim.kimdan_kelgan" },
                  {
                    label: "Qabul qilish statusi",
                    key: "kirim.qabul_qilish_status",
                  },
                  {
                    label: "Partiya raqami",
                    key: "kirim.partiya_raqam",
                  },
                  {
                    label: "Sanasi",
                    key: "kirim.created_at",
                  },
                  ...expansesExcelHeader("kirim"),
                ]}
                data={get(data, "kirim", []).map((item) => ({
                  ...item,
                  kirim: {
                    ...get(item, "kirim"),
                    created_at: moment(get(item, "created_at")).format(
                      "YYYY-MM-DD"
                    ),
                  },
                }))}
                className="excel_download"
              >
                {t("bola.excel")}
              </CSVLink>
            )}
            {value === 1 && data.chiqim && (
              <CSVLink
                filename="Chiqimlar"
                headers={[
                  { label: "Ism", key: "bolalar.ism" },
                  { label: "Familiya", key: "bolalar.familiya" },
                  { label: "Jshshir", key: "bolalar.JSHSHIR" },
                  ...expansesExcelHeader("chiqim"),
                ]}
                data={data.chiqim}
                className="excel_download"
              >
                {t("bola.excel")}
              </CSVLink>
            )}
          </Button>
        </div>
      </div>
      <div className="prihod_block">
        <div className="prihod_block_inner">
          <div className="prihod_block_inner_top">
            <h4 className="prihod_block_inner_title">{t("input.sps")}</h4>
            <TextField type="date" id="outlined-basic" variant="outlined" />
          </div>
          <div className="prihod_block_inner_middle">
            <Tabs
              style={{ backgroundColor: "#fff" }}
              value={value}
              onChange={handleChanges}
              aria-label="basic tabs example"
            >
              <Tab label={t("bildirishnoma.kirim")} />
              <Tab label={t("bildirishnoma.chiqim")} />
            </Tabs>
            <div
              style={{ overflowY: "scroll", height: "600px" }}
              className="card_blocks"
            >
              {value === 0 &&
                data.kirim &&
                data.kirim.map((el, index) => {
                  return el.vositalar.length ? (
                    <div className="kirim_card chiqim_card" key={index}>
                      <div className="kirim_card_left chiqim_card_left ">
                        <img src={kirim} alt="" />
                        <p>
                          {t("shifokor.jami")}:{" "}
                          {el.vositalar
                            .map((el) => el.vosita_miqdori)
                            .reduce((acc, cur) => acc + cur, 0)}
                        </p>
                      </div>
                      <div className="chiqim_card_center">
                        <div className="kirim_card_center_top">
                          <div className="top_left">
                            <p>{t("bildirishnoma.send")}:</p>
                            <h5>{el.kirim.kimdan_kelgan}</h5>
                          </div>
                          <div className="top_right">
                            <div className="kirim_card_right_left">
                              <p>
                                {new Date(el.kirim.created_at).getFullYear()}-
                                {new Date(el.kirim.created_at).getMonth() + 1}-
                                {new Date(el.kirim.created_at).getDate()}
                              </p>
                              <span>
                                {new Date(el.kirim.created_at).getHours()}:
                                {new Date(el.kirim.created_at).getMinutes()}
                              </span>
                            </div>
                            <div className="kirim_card_right_left">
                              <Button onClick={() => Get(index)}>
                                <img src={l1} alt="" />
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="bottom vosita">
                          <div style={{ overflowY: "scroll" }}>
                            <span>
                              {t("vosita.vositaturi")}:{" "}
                              {el.vositalar.map((el) => (
                                <span style={{ marginRight: "4px" }}>
                                  {el.vosita_turi.nomi}
                                </span>
                              ))}{" "}
                            </span>
                          </div>
                          <div style={{ overflowY: "scroll" }}>
                            <span>
                              {t("bildirishnoma.single.nomi")}:{" "}
                              {el.vositalar.map((el) => (
                                <span style={{ marginRight: "4px" }}>
                                  {el.vosita_nomi.nomi}
                                </span>
                              ))}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
              {value === 1 &&
                data.chiqim &&
                data.chiqim.map((el, index) => {
                  return el.vositalar.length ? (
                    <div className="kirim_card chiqim_card" key={index}>
                      <div className="kirim_card_left chiqim_card_left">
                        <Button
                          size="large"
                          className={classes.button}
                          startIcon={<CallMadeIcon />}
                        ></Button>
                        <p>
                          {t("shifokor.jami")}:{" "}
                          {el.vositalar
                            .map((el) => el.vosita_miqdori)
                            .reduce((acc, cur) => acc + cur, 0)}
                        </p>
                      </div>
                      <div className="chiqim_card_center">
                        <div className="kirim_card_center_top">
                          <div className="top_left">
                            {/* <p>{t("bildirishnoma.send")}:LPU</p> */}
                          </div>
                          <div className="top_right">
                            <div className="kirim_card_right_left">
                              <p>
                                {el.vositalar[0] &&
                                  el.vositalar[0].created_at.split("T")[0]}
                              </p>
                              <span>
                                {el.vositalar[0] &&
                                  el.vositalar[0].created_at
                                    .split("T")[1]
                                    .slice(0, 5)}
                              </span>
                            </div>
                            <div className="kirim_card_right_left">
                              <Button>
                                <img
                                  onClick={() => Min(el.bolalar.id)}
                                  src={l1}
                                  alt=""
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div style={{ overflowY: "scroll" }}>
                            <span>
                              {t("vosita.vositaturi")}:{" "}
                              {el.vositalar.map((el) => (
                                <span style={{ marginRight: "4px" }}>
                                  {el.vosita_turi.nomi}
                                </span>
                              ))}{" "}
                            </span>
                          </div>
                          <div style={{ overflowY: "scroll" }}>
                            <span>
                              {t("bildirishnoma.single.nomi")}:{" "}
                              {el.vositalar.map((el) => (
                                <span style={{ marginRight: "4px" }}>
                                  {el.vosita_nomi.nomi}
                                </span>
                              ))}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
            </div>
          </div>
        </div>
        <div className="prihod_block_inner">
          <div className="kirim_right_inner_top">
            <h4>{t("bildirishnoma.kir")}</h4>
            <Button
              onClick={() => {
                setClose(false);
              }}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              {t("input.yop")}
            </Button>
          </div>
          <div className="kirim_right_inner_bottom">
            <div className="kirim_right_inner_bottom_bottom">
              <TableContainer
                style={{ borderRadius: "12px" }}
                component={Paper}
              >
                <Table
                  style={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {t("vosita.vositaturi")}
                      </TableCell>
                      <TableCell align="center">
                        {t("bildirishnoma.single.nomi")}
                      </TableCell>
                      <TableCell align="center">{t("input.ser")}</TableCell>
                      <TableCell align="center">
                        {t("bildirishnoma.single.miqdori")}
                      </TableCell>
                      <TableCell align="center">
                        {t("vosita.partiys")}
                      </TableCell>
                      <TableCell align="center">
                        {t("bildirishnoma.status")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {close &&
                      cur5 &&
                      cur5.vositalar.map((el, key) => {
                        return (
                          <TableRow key={key}>
                            <TableCell align="center">
                              {el.vosita_turi.nomi}
                            </TableCell>
                            <TableCell align="center">
                              {el.vosita_nomi.nomi}
                            </TableCell>
                            <TableCell align="center">
                              {el.vosita_seryasi}
                            </TableCell>
                            <TableCell align="center">
                              {el.vosita_miqdori}
                            </TableCell>
                            <TableCell align="center">
                              {cur5.kirim && cur5.kirim.partiya_raqam}
                            </TableCell>
                            <TableCell align="center">
                              {t("bildirishnoma.kirim")}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {close &&
                      cur6 &&
                      cur6.vositalar.map((el, key) => {
                        return (
                          <Fragment key={key}>
                            <TableRow>
                              <TableCell align="center">
                                {el.vosita_turi.nomi}
                              </TableCell>
                              <TableCell align="center">
                                {el.vosita_nomi.nomi}
                              </TableCell>
                              <TableCell align="center">
                                {el.vosita_seriyasi}
                              </TableCell>
                              <TableCell align="center">
                                {el.vosita_miqdori}
                              </TableCell>
                              <TableCell align="center">
                                {t("input.mavjud")}
                              </TableCell>
                              <TableCell align="center">
                                {t("bildirishnoma.chiqim")}
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Storekirim;
