import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  Box,
  Button,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { request } from "../../../../api/request";
import { Contextvalue } from "../../../../context/context";
import Chiqimvss from "./chiqimvsb";
import "./kirim.scss";
import Kirimobl from "./kirimobl";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import qs from "qs";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import ClearIcon from '@mui/icons-material/Clear';
import { get } from "lodash";
const PrihodObl = () => {
  const classes = {
    table: {
      minWidth: 700,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      padding: "50px",
      width: "80%",
      margin: "30px auto 0 auto",
    },
    formControl: {
      margin: "1px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    button: {
      padding: "8px",
      borderRadius: "12px",
    },
    button2: {
      backgroundColor: "#F69641",
    },
  };
  const { t } = useTranslation();

  const { pathname, search } = useLocation();
  const [ombor, setOmbor] = useState({ kirim: [], chiqim: [] });
  const [loader, setLoeder] = useState(true);
  const [close, setClose] = useState(false);
  const [close2, setClose2] = useState(false);
  const [tab, setTab] = useState("1");
  const [filter, setFilter] = useState({ date: "" });
  console.log("🚀 ~ file: kirim.js:76 ~ PrihodObl ~ filter:", filter)
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { cur3, cur4 } = useContext(Contextvalue);
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    request
      .get(`/omborxona/vssb/kirim/chiqim/malumotlar`, config)
      .then(function (res) {
        setOmbor(res.data);
        setLoeder(false);
      })

      .catch(function (err) {
        setOmbor({
          isFetched: false,
          data: [],
          error: err,
        });
      });
  }, [loader]);

 
  const handleChange = (v) => {
    setTab(v);
    navigate(
      `${pathname}?${qs.stringify({ ...qs.parse(search.slice(1)), mtab: v })}`
    );
  };
  useEffect(() => {
    if (searchParams.get("mtab")) {
      setTab(searchParams.get("mtab"));
    }
  }, []);
  const monitoringMapper = (data) =>
    data.map((item) => ({
      ...item?.chiqim,
      ...item?.kirim,
      vositalar: item.vositalar,
      date: dayjs(item.created_at).format("YYYY-MM-DD"),
      time: dayjs(item.created_at).format("HH:mm"),
    }));
  const maxLengthVositalar = (data = []) => {
    let max = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].vositalar.length > max) {
        max = data[i].vositalar.length;
      }
    }
    const result = [];
    for (let i = 0; i < max; i++) {
      result.push(
        { label: "Vosita nomi", key: `vositalar[${i}].vosita_nomi.nomi` },
        { label: "Vosita turi", key: `vositalar[${i}].vosita_turi.nomi` },
        { label: "Vosita seriyasi", key: `vositalar[${i}].vosita_seryasi` },
        { label: "Miqdori", key: `vositalar[${i}].vosita_miqdori` }
      );
    }
    return result;
  };
  console.log(ombor);
  return (
    <div className="prihod">
      <div className="prihod_top">
        <div className="prihod_top_inner">
          <Link to="/vsbsklad">
            <Button startIcon={<ArrowBackIcon />} variant="contained">
              {t("bildirishnoma.single.ortga")}
            </Button>
          </Link>
          <h4 className="monitoring_top_inner_title">{t("input.a1")}</h4>
        </div>
        <div className="excel_bl">
          <CSVLink
            data={monitoringMapper(tab === "1" ? ombor.kirim : ombor.chiqim)}
            headers={[
              { label: "Sanasi", key: "date" },
              { label: "Vaqti", key: "time" },
              { label: "Partiya raqami", key: "partiya_raqam" },
              // {
              //   label: "Chiqim qilingan tashkilot",
              //   key: "chiqim_qilingan_tashkilot",
              // },

              { label: "Qabul qilish statusi", key: "qabul_qilish_status" },
              { label: "Yuk xati", key: "comment" },
              { label: "Barkod", key: "unique_raqam" },
              ...maxLengthVositalar(tab === 0 ? ombor.kirim : ombor.chiqim),
            ]}
            filename={tab === "1" ? "Kirimlar" : "Chiqimlar"}
            className="excel_download"
            separator=";"
          >
            <Button
              style={{ backgroundColor: "#18CF6C", marginLeft: "20px" }}
              variant="contained"
              // color="primary"
              size="large"
              startIcon={<DescriptionIcon />}
            > 
              {t("bola.excel")}
            </Button>
          </CSVLink>
        </div>
      </div>
      <div className="prihod_block">
        <div className="prihod_block_inner">
          <div className="prihod_block_inner_top">
            <h4 className="prihod_block_inner_title">{t("input.sps")}</h4>
            <div style={{display:"flex",alignItems:"center"}}>
            <TextField
              type="date"
              id="outlined-basic"
              format={"YYYY-MM-DD"}
              variant="outlined"
              onChange={(e) => setFilter({ ...filter, date: e.target.value })}
            /> 
            {filter.date ? <Button onClick={() => setFilter("")} startIcon={<ClearIcon/>}/> : null}
            </div>
          </div>
          <div className="prihod_block_inner_middle">
            <div
              style={{ overflowY: "scroll", height: "500px" }}
              className="card_blocks"
            >
              <TabContext value={tab}>
                <Box sx={{}}>
                  <TabList
                    onChange={(e, v) => handleChange(v)}
                    aria-label="lab API tabs example"
                    className="n-tabs"
                  >
                    <Tab label={t("bildirishnoma.kirim")} value="1" />
                    <Tab label={t("bildirishnoma.chiqim")} value="2" />
                  </TabList>
                  <TabPanel value="1" style={{ padding: 0 }}>
                    <Kirimobl
                      filter={filter}
                      classes={classes}
                      setClose={setClose}
                      kirim={ombor.kirim}
                    />
                  </TabPanel>
                  <TabPanel value="2" style={{ padding: 0 }}>
                    <Chiqimvss
                      setClose2={setClose2}
                      data={!filter ? get(ombor,"chiqim") : get(ombor,"chiqim").filter(el => el.chiqim.created_at.split("T")[0].includes(get(filter,"date")))}
                      classes={classes}
                    />
                  </TabPanel>
                </Box>
              </TabContext>
            </div>
          </div>
        </div>
        <div className="prihod_block_inner">
          <div className="kirim_right_inner_top">
            {
              <Button
                onClick={() => {
                  setClose(false);
                  setClose2(false);
                }}
                variant="contained"
                startIcon={<CloseIcon />}
              >
                {t("input.yop")}
              </Button>
            }
          </div>

          <div className="kirim_right_inner_bottom">
            <div className="kirim_right_inner_bottom_top"></div>
            <div className="kirim_right_inner_bottom_bottom">
              <TableContainer
                style={{ borderRadius: "12px" }}
                component={Paper}
              >
                <Table
                  style={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {t("bildirishnoma.single.nomi")}
                      </TableCell>
                      <TableCell align="center">
                        {t("vosita.vositaturi")}
                      </TableCell>
                      <TableCell align="center">{t("input.ser")}</TableCell>
                      <TableCell align="center">
                        {t("bildirishnoma.single.miqdori")}
                      </TableCell>
                      <TableCell align="center">{t("input.barkod")}</TableCell>
                      <TableCell align="center">{t("input.yuk")}</TableCell>
                      <TableCell align="center">{t("input.rasm")}</TableCell>
                      <TableCell align="center">
                        {t("bildirishnoma.status")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {close && (
                      <TableRow>
                        <TableCell align="center">
                          {cur3 &&
                            cur3.vositalar[0] &&
                            cur3.vositalar[0].vosita_nomi.nomi}
                        </TableCell>
                        <TableCell align="center">
                          {cur3 &&
                            cur3.vositalar[0] &&
                            cur3.vositalar[0].vosita_turi.nomi}
                        </TableCell>
                        <TableCell align="center">
                          {cur3 &&
                            cur3.vositalar[0] &&
                            cur3.vositalar[0].vosita_seryasi}
                        </TableCell>
                        <TableCell align="center">
                          {cur3 &&
                            cur3.vositalar[0] &&
                            cur3.vositalar[0].vosita_miqdori}
                        </TableCell>
                        <TableCell align="center">
                          {t("input.mavjud")}
                        </TableCell>
                        <TableCell align="center">
                          {t("input.mavjud")}
                        </TableCell>
                        <TableCell align="center">
                          {t("input.mavjud")}
                        </TableCell>
                        <TableCell align="center">
                          {t("bildirishnoma.kirim")}
                        </TableCell>
                      </TableRow>
                    )}
                    {close2 && (
                      <TableRow>
                        <TableCell align="center">
                          {cur4 &&
                            cur4.vositalar[0] &&
                            cur4.vositalar[0].vosita_nomi.nomi}
                        </TableCell>
                        <TableCell align="center">
                          {cur4 &&
                            cur4.vositalar[0] &&
                            cur4.vositalar[0].vosita_turi.nomi}
                        </TableCell>
                        <TableCell align="center">
                          {cur4 &&
                            cur4.vositalar[0] &&
                            cur4.vositalar[0].vosita_seryasi}
                        </TableCell>
                        <TableCell align="center">
                          {cur4 &&
                            cur4.vositalar[0] &&
                            cur4.vositalar[0].vosita_miqdori}
                        </TableCell>
                        <TableCell align="center">
                          {cur4 && cur4.chiqim && cur4.chiqim.unique_raqam
                            ? cur4.chiqim.unique_raqam
                            : t("bola.kir")}
                        </TableCell>
                        <TableCell align="center">
                          {cur4 && cur4.chiqim && cur4.chiqim.comment}
                        </TableCell>
                        <TableCell align="center">
                          {cur4.chiqim.image ? (
                            <a
                              href={`https://admin-mpbt.ssv.uz/static${cur4.chiqim && cur4.chiqim.image
                                }`}
                            >
                              {t("yuk")}
                            </a>
                          ) : (
                            t("input.mavjud")
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {t("bildirishnoma.chiqim")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrihodObl;
