import axios from "axios";
export const request = axios.create({
  baseURL: "https://admin-mpbt.ssv.uz/api/v1",
  // headers: {
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  //   "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
  // },
});

export function PostData(endpoint, data, token) {
  return fetch(`${"https://admin-mpbt.ssv.uz/api/v1" + endpoint}`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      Accept: "*/*",
      // 'Content-type': 'multipart/form-data'
      // "Content-Type": "multipart/form-data",
      // "type": "formData"
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
}

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // window.location = "/";
      // localStorage.clear();
    }
    if (
      error.response.status === 404 &&
      window.location.pathname !== "/notfound"
    ) {
      window.location = "/notfound";
    }
    return Promise.reject(error);
  }
);

export function GetData(endpoint, token) {
  return fetch(`${`https://admin-mpbt.ssv.uz/api/v1/` + endpoint}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Access-Control-Request-Headers": "*"
    },
  }).then((response) => {
    return response.json();
  });
}
