import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { Button, Snackbar, Switch, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import useGet from "hooks/useGet";
import usePost from "hooks/usePost";
import moment from "moment";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import pdfDoc from "../../../../../../assets/icon/pdf_doc.svg";
import scrip from "../../../../../../assets/icon/scripka.svg";
import Loading from "components/loading/loading";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
function getStyles(name, personName, theme) {
	return {
		fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
	};
}

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const label = { inputProps: { "aria-label": "Switch demo" } };

const Uzsinglebil = () => {
	const theme = useTheme();
	let todayDate = moment().add(1, "days").format().split("T")[0];
	const { t } = useTranslation();
	const [personName, setPersonName] = useState([]);
	const [personName2, setPersonName2] = useState([]);
	const [personName3, setPersonName3] = useState([]);
	const [text, setText] = useState("");
	const [date, setDate] = useState(todayDate);
	const [noti, setNoti] = useState(false);
	const [notificationn, setNotificationn] = useState({
		state: "",
		text: "",
	});
	const [num, setNum] = useState({
		from: 0,
		to: 0,
	});
	const [num2, setNum2] = useState({
		from: 0,
		to: 0,
	});
	const [checked, setChecked] = useState(false);
	const [numarr, setNumarr] = useState([]);
	const [numarr2, setNumarr2] = useState([]);
	const [down, setDown] = useState([]);
	const navigate = useNavigate();
	const { mutate, isLoading } = usePost();
	const { data } = useGet({ url: "/hududlar/" });
	const Data = personName
		.map(el => data[el])
		.map(el => Object.keys(el))
		.map(els => els.filter(el => el !== "viloyat_id"));
	const filterRegion = personName2 ? personName2.map(el => personName.map(els => data[els][el])) : null;
	const flatArr = personName2 ? filterRegion.flat().map(el => Object.keys(el)) : null;
	const institution = flatArr ? flatArr.flat().filter(el => el !== "tuman_id") : null;
	const institutionFilter = personName3.map(el => filterRegion.map(els => els[0][el]).filter(item => item !== undefined)[0]);
	const handlenoti = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setNoti(false);
	};
	const switchHandler = event => {
		setChecked(event.target.checked);
	};
	function addFile(e) {
		setDown([
			...down,
			{
				filename: e.target.value,
				fil: e.target.files[0],
			},
		]);
	}
	const files = useRef({});
	function delFile(index) {
		if (files.current) {
			files.current.value = "";
		}
		let sss = [];
		// sss.push(...down);
		// sss.splice(index, 1);
		setDown(sss);
	}
		console.log(down);

	function Send(e) {
		e.preventDefault();
		const formData = new FormData();
		const numarrsend2 = numarr.map(item=>`${Number(item.from)}-${Number(item.to)}`);
		const numarrsend1 = numarr2.map(item => `${Number(item.from)}-${Number(item.to)}`);


		formData.append("yosh_toifa", JSON.stringify(numarrsend2));
		formData.append("oy_toifa", JSON.stringify(numarrsend1));
		formData.append("muddati", date);
		down[0] && formData.append("fayl", down[0].fil);
	
		formData.append("text", '');
		formData.append("qoshimcha",text);
		if (personName[0]) {
			formData.append("kimga", personName && personName.map(el => data[el].viloyat_id));
			mutate({
				url: "/bildirishnoma/erkin/UzMedImpeksdanVSSBga/",
				data: formData,
				onSuccess: res => {
					setNoti(true);
					setNotificationn({
						text: "VSSBga yuborildi!",
						state: "success",
					});
					navigate("/uzbildirish?value=1");
				},
				onError: err => {
					console.log(err);
					setNoti(true);
					setNotificationn({
						text: "VSSBga yuborilmadi!",
						state: "error",
					});
				},
			});
		}
		if (personName2[0]) {
			formData.append("kimga", personName2[0] && filterRegion.map(el => el.map(els => els.tuman_id)[0]));
			mutate({
				url: "/bildirishnoma/erkin/UzMedImpeksdanTTBga/",
				data: formData,
				onSuccess: () => {
					setNoti(true);
					setNotificationn({
						text: "TTBga yuborildi!",
						state: "success",
					});
					setTimeout(() => {
						navigate("/uzbildirish?value=1");
					}, 1500);
				},
				onError: () => {
					setNoti(true);
					setNotificationn({
						text: "TTBga yuborilmadi!",
						state: "error",
					});
				},
			});
		}
		if (institutionFilter[0]) {
			formData.append("kimga", institutionFilter[0] && institutionFilter.map(el => el.id));
			mutate({
				url: "/bildirishnoma/erkin/UzMedImpeksdanLPUga/",
				data: formData,
				onSuccess: () => {
					setNoti(true);
					setNotificationn({
						text: "LPUga yuborildi!",
						state: "success",
					});
					setTimeout(() => {
						navigate("/uzbildirish?value=1");
					}, 1500);
				},
				onError: () => {
					setNoti(true);
					setNotificationn({
						text: "LPUga yuborilmadi!",
						state: "error",
					});
				},
			});
    }
    if (!checked) {
      	setNoti(true);
				setNotificationn({
					text: "Hudud tanlanmadi!",
					state: "error",
				});
    }
		if (checked) {
			formData.delete("kimga");
			mutate({
				url: "/bildirishnoma/uzmedimpeks/",
				data: formData,
				onSuccess: () => {
					setNoti(true);
					setNotificationn({
						text: "MOHga yuborildi!",
						state: "success",
					});
					setTimeout(() => {
						navigate("/uzbildirish?value=1");
					}, 1500);
				},
        onError: (err) => {
          console.log(err,"error moh")
					setNoti(true);
					setNotificationn({
						text: `MOHga yuborilmadi! ${err?.response && err.response.data.message}`,
						state: "error",
					});
				},
			});
		}
	}
	if (numarr) {
		var uniqueNames = [...new Set(numarr)];
	}
	if (numarr2) {
		var uniqueMonth = [...new Set(numarr2)];
	}

	function numAdd() {
		setNumarr([...numarr, num]);
	}
	function numAdd2() {
		setNumarr2([...numarr2, num2]);
	}
	function numDel(e) {
		let delarr = [];
		delarr.push(...uniqueNames);
		delarr.splice(e, 1);
		setNumarr(delarr);
	}
	function numDel2(e) {
		let delarr = [];
		delarr.push(...uniqueMonth);
		delarr.splice(e, 1);
		setNumarr2(delarr);
	}
	const handleChanges = event => {
		const {
			target: { value },
		} = event;
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	const handleChange2 = event => {
		const {
			target: { value },
		} = event;
		setPersonName2(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	const handleChange3 = event => {
		const {
			target: { value },
		} = event;
		setPersonName3(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};

	console.log(uniqueNames);
	if (todayDate > date) {
		toast.error("Topshirish Muddatini Ortga Surib Bo'lmaydi!");
		setDate(todayDate);
	}
	if (isLoading) return <Loading />;
	return (
		<div className='sarflov'>
			<div className='sarflov_inner'>
				<Link to={"/uzbildirish"}>
					<Button startIcon={<ArrowBackIcon />} variant='contained'>
						{t("bildirishnoma.single.ortga")}
					</Button>
				</Link>
			</div>

			<form onSubmit={Send}>
				<div className='sarflov_block' style={{ paddingBottom: "24px" }}>
					<div style={{ display: "flex", alignItems: "center" }} className='sarflov_block_inner'>
						<div style={{ marginRight: "7px", marginTop: "9px", width: "30%" }}>
							<h4 style={{ marginBottom: "23px" }} className='sarflov_block_title'>
								{t("bildirishnoma.new.kimdankimga")}
							</h4>
							<TextField
								style={{ width: "42%" }}
								id='outlined-basic'
								label={t("bildirishnoma.sog")}
								variant='outlined'
								disabled
							/>
							<Switch checked={checked} onChange={switchHandler} {...label} />
						</div>
						{/* <div style={{marginRight:"7px"}}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">{t("bildirishnoma.viloyat")}</InputLabel>
                <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={personName}
                onChange={handleChanges}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
                >
                    {data ? Object.keys(data).map((name) => (
                        <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, personName, theme)}
                        >
                        {name}
                        </MenuItem>
                    )):null}
                </Select>
      </FormControl>
            </div> */}
						{/* <div style={{marginRight:"7px"}}>
            <FormControl sx={{ m: 1, width: 300 }}>
             <InputLabel id="demo-multiple-name-label">{t("bildirishnoma.tuman1")}</InputLabel>
            <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={personName2}
            onChange={handleChange2}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
            required={!personName[0]}
            disabled={!personName[0]}
            >
                {
                 personName ?  personName.map((el,index) =>{
                       return(
                        Data[index] && Data[index].map((name) => (
                            <MenuItem
                            key={name}
                            value={
                                name}
                            style={getStyles(name, personName, theme)}
                            >
                            {name}
                            </MenuItem> 
                        ))
                       )
                    })
                :null}
            </Select>
      </FormControl>
            </div> */}
						{/* <div style={{marginRight:"7px"}}>
            <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">{t("bildirishnoma.single.muas")}</InputLabel>
            <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={personName3}
            onChange={handleChange3}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
            required={!personName2[0]}
            disabled={!personName2[0]}

            >
                {institution ? institution.map((name) => (
                    <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                    >
                    {name}
                    </MenuItem>
                )):null}
            </Select>
        </FormControl>
            </div> */}
						<div>
							<h4 className='sarflov_block_title' style={{ marginBottom: "16px" }}>
								{t("bildirishnoma.new.mud")}
							</h4>
							<TextField
								className='befor-date'
								style={{ width: "359px" }}
								id='outlined-basic'
								variant='outlined'
								required
								type='date'
								value={date ? date : todayDate}
								// disabled={todayDate >= date}
								onChange={e => setDate(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<div className='rol_ariza_bottom' style={{ width: "50%" }}>
						<div className='rol_ariza_bottom_div' style={{ width: "100%" }}>
							<div className='rol_ariza_bottom_div_inner'>
								<div className='rol_ariza_bottom_div_inner_block'>
									<h4 style={{ fontWeight: "normal" }} className='rol_ariza_bottom_title'>
										{t("bildirishnoma.new.bolalar")}
									</h4>
									<p className='yil_oy'>{t("bola.yosh")}</p>
									<div className='num_block_ariza'>
										<div className='rol_ariza_textarea'>
											<p className='rol_num_ariza'>{t("bildirishnoma.new.dan")}</p>
											<div className='rol_ariza_number_left'>
												{num.from === 0 ? (
													<button
														type='button'
														disabled
														onClick={() => setNum({ ...num, from: num.from - 1 })}
														className='num_btn'
													>
														-
													</button>
												) : (
													<button type='button' onClick={() => setNum({ ...num, from: num.from - 1 })} className='num_btn'>
														-
													</button>
												)}
												<p className='num_title'>{num.from}</p>
												<button type='button' onClick={() => setNum({ ...num, from: num.from + 1 })} className='num_btn'>
													+
												</button>
											</div>
										</div>
										<div className='rol_ariza_textarea'>
											<p className='rol_num_ariza'>{t("bildirishnoma.new.gacha")}</p>
											<div className='rol_ariza_number_left'>
												{num.to === 0 ? (
													<button type='button' disabled onClick={() => setNum({ ...num, to: num.to - 1 })} className='num_btn'>
														-
													</button>
												) : (
													<button type='button' onClick={() => setNum({ ...num, to: num.to - 1 })} className='num_btn'>
														-
													</button>
												)}
												<p className='num_title'>{num.to}</p>
												<button type='button' onClick={() => setNum({ ...num, to: num.to + 1 })} className='num_btn'>
													+
												</button>
											</div>
										</div>
										<Button
											style={{ marginTop: "53px", borderRadius: "12px" }}
											type='button'
											onClick={numAdd}
											variant='contained'
											disabled={num.from >= num.to}
											startIcon={<AddIcon />}
										></Button>
									</div>
									<div className='age_num_block'>
										{uniqueNames.map((item, index) => (
											<div className='age_num_block_inner'>
												<p>{item.from}</p>
												<p>-</p>
												<p>{item.to}</p>
												<p>-</p>
												<p>{t("bola.yosh")}</p>
												<button type='button' onClick={e => numDel(index)}>
													x
												</button>
											</div>
										))}
									</div>
								</div>
								<div className='rol_ariza_bottom_div_inner_block'>
									<p className='yil_oy'>{t("vosita.oy")}</p>
									<div className='num_block_ariza'>
										<div className='rol_ariza_textarea'>
											<p className='rol_num_ariza'>{t("bildirishnoma.new.dan")}</p>
											<div className='rol_ariza_number_left'>
												{num2.from === 0 ? (
													<button
														type='button'
														disabled
														onClick={() => setNum2({ ...num2, from: num2.from - 1 })}
														className='num_btn'
													>
														-
													</button>
												) : (
													<button type='button' onClick={() => setNum2({ ...num2, from: num2.from - 1 })} className='num_btn'>
														-
													</button>
												)}
												<p className='num_title'>{num2.from}</p>
												<button type='button' onClick={() => setNum2({ ...num2, from: num2.from + 1 })} className='num_btn'>
													+
												</button>
											</div>
										</div>
										<div className='rol_ariza_textarea'>
											<p className='rol_num_ariza'>{t("bildirishnoma.new.gacha")}</p>
											<div className='rol_ariza_number_left'>
												{num2.to === 0 ? (
													<button
														type='button'
														disabled
														onClick={() => setNum2({ ...num2, to: num2.to - 1 })}
														className='num_btn'
													>
														-
													</button>
												) : (
													<button type='button' onClick={() => setNum2({ ...num2, to: num2.to - 1 })} className='num_btn'>
														-
													</button>
												)}
												<p className='num_title'>{num2.to}</p>
												<button type='button' onClick={() => setNum2({ ...num2, to: num2.to + 1 })} className='num_btn'>
													+
												</button>
											</div>
										</div>
										<Button
											type='button'
											style={{ marginTop: "53px", borderRadius: "12px" }}
											onClick={numAdd2}
											disabled={num2.from >= num2.to}
											variant='contained'
											startIcon={<AddIcon />}
										></Button>
									</div>
									<div className='age_num_block'>
										{uniqueMonth.map((item, index) => (
											<div className='age_num_block_inner'>
												<p>{item.from}</p>
												<p>-</p>
												<p>{item.to}</p>
												<p>-</p>
												<p>{t("vosita.oy")}</p>
												<button type='button' onClick={e => numDel2(index)}>
													x
												</button>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='sarflov_block_comment' style={{ width: "47%", height: 444 }}>
						<div className='sarflov_block_comment_inner'>
							<h4 className='sarflov_block_title'>{t("bildirishnoma.new.fail")}</h4>
							<input onChange={e => addFile(e)} ref={files} type='file' id='files' className='input_download' />
							{
								down.length === 0 && (
									<label htmlFor='files' className='all_download'>
								<img className='scrip_file' src={scrip} alt='' />
								{t("vosita.fayl")}
							</label>
								)
							}
						</div>
						<div className='sarflov_block_inner_div'>
					
							{	down.length > 0 ? (
									<div className='sarflov_block_download_file'>
										<label className='input_tyle_download'>
											<img src={pdfDoc} alt='' className='label_img' />
											{down[0]?.filename}
											<div className='close_file'>
												<Button onClick={e => delFile(0)} startIcon={<CloseIcon />}></Button>
											</div>
										</label>
									</div>
								) : "Fayl yuklanmagan"}
						
						</div>
					</div>
				</div>
				<div>
					<div className='sarflov_block_comment'>
						<h4 className='sarflov_block_title'>{t("sbola.p6")}</h4>
						<textarea onChange={e => setText(e.target.value)}></textarea>
					</div>
				</div>
				<footer className='site-footer'>
					<div style={{ textAlign: "center" }}>
						<Button
							style={{ borderRadius: "12px", backgrounColor: "#1464C0", width: "448px" }}
							type='submit'
							variant='contained'
							color='primary'
							size='large'
							startIcon={<SendIcon />}
						>
							{t("input.otp")}
						</Button>
					</div>
				</footer>
			</form>
			<Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={noti} autoHideDuration={6000} onClose={handlenoti}>
				<Alert
					Alert
					onClose={handlenoti}
					severity={notificationn.state}
					sx={{
						width: "100%",
					}}
				>
					{notificationn.text}
				</Alert>
			</Snackbar>
		</div>
	);
};
export default Uzsinglebil;
