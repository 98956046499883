import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../../../../../api/request";
import Eyemodal from "../singlebuy/eyemodal/eyemodal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
function Uzbuyin() {
	const [data, setData] = useState([]);
	const { t } = useTranslation();
	const [filealert, setAlert] = useState(false);
	const params = useParams();
	const navigate = useNavigate();
	const token = window.localStorage.token;
	const config = {
		headers: {
			Authorization: "Bearer " + token,
		},
	};

	useEffect(() => {
		request
			.get("/omborxona/buyurtma/yaratish", config)
			.then(data => setData(data.data.find(el => el.buyurtma && el.buyurtma.id == params.id)));
		if (filealert) {
			setAlert(true);
			alert("Fayl mavjud emas");
		}
	}, [params.id, filealert]);
	console.log(data);
	return (
		<>
			<div style={{paddingTop:"34px"}} className='container'>
				<Button
					onClick={() => navigate(-1)}
					style={{
						backgroundColor: "#DDEBFB",
						color: "#1464C0",
						borderRadius: "12px",
					}}
					variant='contained'
					startIcon={<ArrowBackIcon />}
				>
					{t("bildirishnoma.single.ortga")}
				</Button>
				<div style={{ display: "flex", justifyContent: "space-between", gap: "2rem" }}>
					<section style={{ flex: 1 }}>
						<div className='card-block'>
							<h2 className='card-block__head'>{t("input.mal1")}</h2>
							<div className='singlebemor_block_info'>
								<div className='singlebemor_block_info_inner'>
									<h5 className='singlebemor_block_info_desc'>{t("input.shart")}</h5>
									<h5 className='singlebemor_block_info_desc'>{data.buyurtma && data.buyurtma.shartnoma_raqami}</h5>
								</div>
								<div className='singlebemor_block_info_inner'>
									<h5 className='singlebemor_block_info_desc'>{t("input.yt")}</h5>
									<h5 className='singlebemor_block_info_desc'>{data.buyurtma && data.buyurtma.yetgazib_beruvchi_firma_nomi}</h5>
								</div>
								<div className='singlebemor_block_info_inner'>
									<h5 className='singlebemor_block_info_desc'>{t("input.xar")}</h5>
									<h5 className='singlebemor_block_info_desc'>{data.buyurtma && data.buyurtma.xarid_qilish_usuli}</h5>
								</div>
								<div className='singlebemor_block_info_inner'>
									<h5 className='singlebemor_block_info_desc'>{t("input.shart1")}</h5>
									<h5 className='singlebemor_block_info_desc'>
										{data.buyurtma && new Date(data.buyurtma.created_at).getFullYear()}-
										{data.buyurtma && new Date(data.buyurtma.created_at).getMonth() + 1}-
										{data.buyurtma && new Date(data.buyurtma.created_at).getDate()}
									</h5>
								</div>
								<div className='singlebemor_block_info_inner'>
									<h5 className='singlebemor_block_info_desc'>{t("input.pulmiq")}</h5>
									<h5 className='singlebemor_block_info_desc'>
										{data.buyurtma && data.buyurtma.shartnomaning_umumiy_pul_miqdori}
									</h5>
								</div>
							</div>
						</div>
					</section>
					<section style={{ flex: 1 }}>
						<div style={{ width: "100%" }} className='card-block'>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<h2 className='card-block__head'>{t("input.zakaz")}</h2>
								{/* <Singlemodal/> */}
							</div>
							<div className='site-list__div'>
								<ul style={{ listStyle: "none" }}>
									<li>#1</li>
									<li style={{ marginTop: "10px", marginBottom: "14px" }}>
										<Eyemodal data={data} />
									</li>
									<li className='site-list__item'>
										{t("vosita.vositaturi")}: {data.vosita_turi && data.vosita_turi.nomi}
									</li>
									<li className='site-list__item'>
										{t("bildirishnoma.single.nomi")}: {data.vosita_turi && data.vosita_nomi.nomi}
									</li>
									<li className='site-list__item'>
										{t("sbola.olchov")}: {data.olchov_birligi}
									</li>
									<li className='site-list__item'>
										{t("bildirishnoma.single.miqdori")}: {data.vosita_miqdori}
									</li>
									<li className='site-list__item'>
										{t("bildirishnoma.yet")}: {data.partiyadan_kelgan_vosita_miqdori}
									</li>
								</ul>
							</div>
							{/* <p className="card-page">
                            Bu yerga, buyurtma qilingan va shartnomaga kiritilgan vositalarni qo'shishingiz mumkin, uning uchun "Vosita qo'shish" tugmachasini bosing
                        </p> */}
						</div>
						<div className='card-block'>
							<h2 className='card-block__head'>{t("Qo’shimcha ma’lumot")}</h2>
							<p>{data.buyurtma && data.buyurtma.qoshimcha_malumot}</p>
						</div>
						<div className='card-block'>
							<div className='sarflov_block_comment_inner'>
								<h4 className='sarflov_block_title'>{t("input.yuklab")}</h4>
								{data.buyurtma && data.buyurtma.file ? (
									<Button
										onClick={() => {
											if (data?.buyurtma.file) {
												setAlert(false);
												return;
											} else setAlert(true);
										}}
										variant='contained'
									>
										<a
											href={`https://admin-mpbt.ssv.uz/static${data?.buyurtma.file}`}
											className='download_document_t9'
                      style={{ color: "white" }} 
                      download={data?.buyurtma.file}
										>
											{t("input.yuklab")}
										</a>
									</Button>
								) : (
									t("input.mavjud")+"!"
								)}
							</div>
						</div>
					</section>
				</div>
			</div>
		</>
	);
}
export default Uzbuyin;
