import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DescriptionIcon from "@mui/icons-material/Description";
import HistoryIcon from "@mui/icons-material/History";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import OutsideClick from "hooks/OutsideClick";
import { get } from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { request } from "../../../../api/request";
import l1 from "../../../../assets/icon/l1.svg";
import Chiqimvsb from "./chiqimvsb";
import "./omborhona.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Oblsklad() {
  const { isVisible, setIsVisible, ref } = OutsideClick();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    background: "white",
    pt: 2,
    px: 4,
    pb: 3,
  };
  const [openss, setOpenss] = React.useState(false);
  const [data2, setData2] = useState([]);
  const [openss2, setOpenss2] = React.useState(false);
  const [opens2, setOpens2] = useState(false);
  const [opens1, setOpens1] = useState(false);
  const [value, setValue] = useState(0);
  const [detail, setDetail] = useState([]);
  const [loader, setLoeder] = useState(true);
  const [ombor, setOmbor] = useState([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenss(false);
    setOpenss2(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    request
      .get(`/omborxona/vssb/kirim/chiqim/malumotlar`, config)
      .then(function (res) {
        setOmbor(res.data);
        setLoeder(false);
      })
      .catch(function (err) {
        setOmbor({
          isFetched: false,
          data: [],
          error: err,
        });
      });
  }, [loader]);

  const classes = {
    table: {
      minWidth: 700,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      padding: "50px",
      width: "80%",
      margin: "30px auto 0 auto",
    },
    formControl: {
      margin: "1px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    button: {
      padding: "8px",
      borderRadius: "12px",
    },
    button2: {
      backgroundColor: "#F69641",
    },
  };

  const [kirim, setKirim] = useState("first");
  const [part, setPart] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [modals, setModals] = useState("null");
  const [opens, setOpens] = useState(false);
  const [check, setCheck] = useState([]);
  const [input, setInput] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  function addColumn(e) {
    if (check && check.length > 0) {
      let ss = check.filter((elem) => elem == e);
      if (ss && ss.length > 0) {
        setCheck(check.filter((item) => item != e));
      } else {
        setCheck([...check, `${e}`]);
      }
    } else {
      setCheck([...check, `${e}`]);
    }
  }
  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      setInput({
        ...input,
        [e.target.name]: String(e.target.checked),
      });
    } else {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handlerDetail = (e) => {
    setIsLoading(true);
    request
      .get(`omborxona/vositalar/vssb?vosita=${e}`, config)
      .then((data) => {
        setDetail(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          window.location.reload();
         alert("Ma'lumotlar Ba'zada Mavjud Emas!")
        }
        setIsLoading(false);
      });
    setOpens2(true);
  };

  const [ariza, setAriza] = useState({
    isFetched: false,
    data: [],
    error: false,
  });
  useEffect(() => {
    request
      .get(`/user/respublika/muassasalar/`, config)
      .then(function (res) {
        setAriza({
          isFetched: true,
          data: res.data,
          error: false,
        });
        setLoeder(false);
      })
      .catch(function (err) {
        setAriza({
          isFetched: false,
          data: [],
          error: err,
        });
      });
  }, [loader]);

  const [ariza2, setAriza2] = useState({
    isFetched: false,
    data: [],
    error: false,
  });
  useEffect(() => {
    request
      .get(`/user/respublika/tumanlar/`, config)
      .then(function (res) {
        setAriza2({
          isFetched: true,
          data: res.data,
          error: false,
        });
        setLoeder(false);
      })
      .catch(function (err) {
        setAriza2({
          isFetched: false,
          data: [],
          error: err,
        });
      });
  }, [loader]);
  useEffect(() => {
    request
      .get("/omborxona/vssb/malumotlar", config)
      .then((data) => setData2(data.data));
  }, []);
  const excelData = useMemo(() => {
    let result = [];
    let hozirgi_oy_kirim = get(data2, "hozirgi_oy_kirim");
    let otgan_oy_qoldiq = get(data2, "otgan_oy_qoldiq");
    let shu_oy_mavjud = get(data2, "shu_oy_mavjud");
    get(data2, "hozirgi_oy_chiqim", []).forEach((item, index) => {
      result.push({
        ...item,
        ...hozirgi_oy_kirim[index],
        ...otgan_oy_qoldiq[index],
        ...shu_oy_mavjud[index],
      });
    });
    return result;
  }, [data2]);
  return (
    <div className="" style={{ paddingRight: "20px" }}>
      <div className="sklad_top_block">
        <div className="sklad_top_block_inner">
          <h1 className="sklad_title">{t("sidebar.li3")}</h1>
          <TextField
            id="outlined-basic"
            label={t("bildirishnoma.doriq")}
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <div className="sklad_top"></div>
        </div>
        <div className="sklad_top_block_inner">
          <Link Link to={"/kirim_viloyat"}>
            <Button
              variant="contained"
              // color="primary"
              size="large"
              className={classes.button}
              startIcon={<HistoryIcon />}
            >
              {t("bildirishnoma.kir")}
            </Button>
          </Link>

          <Chiqimvsb setOpens={setOpenss} setOpens2={setOpenss2} />
          <CSVLink
            data={excelData}
            headers={[
              { label: "Vosita nomi", key: "vosita_nomi" },
              { label: "O'tgan oyning qoldig'i", key: "otgan_oy_qoldiq" },
              { label: "Kirim", key: "kirim" },
              { label: "Chiqim", key: "chiqim" },
              { label: "Qoldiq", key: "shu_oy_qoldiq" },
            ]}
            filename="Omborxona"
            separator=";"
          >
            <Button
              variant="contained"
              // color="primary"
              size="large"
              className={classes.button}
              startIcon={<DescriptionIcon />}
            >
              {t("bola.excel")}
            </Button>
          </CSVLink>
        </div>
      </div>

      <div className="sklad">
        <h2 className="sklad-head">{t("jihoz.j10")}</h2>
        <TableContainer style={{ borderRadius: "12px" }} component={Paper}>
          <Table
            style={{ minWidth: 650 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {t("bildirishnoma.single.soni")}
                </TableCell>
                <TableCell align="center">
                  {t("bildirishnoma.single.nomi")}
                </TableCell>
                <TableCell TableCell align="center">
                  {t("bildirishnoma.qoldig")}
                </TableCell>
                <TableCell align="center">{t("bildirishnoma.kirim")}</TableCell>
                <TableCell align="center">
                  {t("bildirishnoma.chiqim")}
                </TableCell>
                <TableCell align="center">
                  {t("bildirishnoma.qoldiq")}
                </TableCell>
                <TableCell align="center">
                  {t("bildirishnoma.harakat")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2.otgan_oy_qoldiq &&
                data2.otgan_oy_qoldiq
                  .filter((item) =>
                    item.vosita_nomi
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  )
                  .map((el, index) => {
                    return (
                      <Fragment key={el.id}>
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{el.vosita_nomi}</TableCell>
                          <TableCell align="center">
                            {el.otgan_oy_qoldiq}
                          </TableCell>
                          <TableCell align="center">
                            {data2.hozirgi_oy_kirim &&
                              data2.hozirgi_oy_kirim[index].kirim}
                          </TableCell>
                          <TableCell align="center">
                            {data2.hozirgi_oy_chiqim &&
                              data2.hozirgi_oy_chiqim[index].chiqim}
                          </TableCell>
                          <TableCell align="center">
                            {data2.shu_oy_mavjud &&
                              data2.shu_oy_mavjud[index].shu_oy_qoldiq}
                          </TableCell>
                          <TableCell align="center">
                            <Link
                              to="#"
                              onClick={() => handlerDetail(el.vosita_id)}
                            >
                              <img src={l1} alt="..."/>
                            </Link>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="modal_manitoring_seans">
        <Modal
          keepMounted
          open={opens2}
          onClose={() => setOpens2(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className="modal-one" sx={{ ...style }}>
            <div
              className="seans_modal"
              style={{ height: "90vh", overflowY: "auto" }}
            >
              <Tabs
                style={{ marginBottom: "20px", borderBottom: "1px solid" }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label={t("bildirishnoma.kirim")} />
                <Tab label={t("bildirishnoma.chiqim")} />
              </Tabs>
              <h4 className="seans_modal_title">{t("vosita.mal")}</h4>
              <h1>{isLoading ? "Yuklanmoqda..." : ""}</h1>
              <div>
                {value === 0 && detail.shu_oy_kirim?.length
                  ? detail.shu_oy_kirim.map((el, index) => {
                      return (
                        <div className="seans_modal_inner">
                          <div className="seans_modal_inner_top">
                            <p className="seans_modal_inner_desc">
                              {index + 1}
                            </p>
                          </div>
                          <div className="seans_modal_inner_bottom">
                            <div className="seans_modal_inner_bottom_left">
                              <p className="">
                                {t("bildirishnoma.single.nomi")}
                              </p>
                              <p className="">{t("vosita.vositaturi")}</p>
                              <p className="">
                                {t("bildirishnoma.single.seriyasi")}
                              </p>
                              <p className="">{t("vosita.miq")}</p>
                              <p className="">{t("Yaratilgan Sanasi")}</p>
                            </div>
                            <div className="seans_modal_inner_bottom_left">
                              <p className="">{el.vosita_nomi.nomi}</p>
                              <p className="">{el.vosita_turi.nomi}</p>
                              <p className="">{el.vosita_seryasi}</p>
                              <p className="">{el.vosita_miqdori}</p>
                              <p className="">{el.created_at.split("T")[0]}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <h1>
                  {!isLoading &&
                  detail?.shu_oy_kirim?.length === 0 &&
                  value === 0
                    ? "Kirimlar mavjud emas"
                    : ""}
                </h1>
              </div>

              <div>
                {value === 1 && detail.shu_oy_chiqim
                  ? detail.shu_oy_chiqim.map((el, index) => {
                      return (
                        <div className="seans_modal_inner">
                          <div className="seans_modal_inner_top">
                            <p className="seans_modal_inner_desc">
                              {index + 1}
                            </p>
                          </div>
                          <div className="seans_modal_inner_bottom">
                            <div className="seans_modal_inner_bottom_left">
                              <p className="">
                                {t("bildirishnoma.single.nomi")}
                              </p>
                              <p className="">{t("vosita.vositaturi")}</p>
                              <p className="">
                                {t("bildirishnoma.single.seriyasi")}
                              </p>
                              <p className="">{t("vosita.miq")}</p>
                              <p className="">Chiqim qilingan sana</p>
                            </div>
                            <div className="seans_modal_inner_bottom_left">
                              <p className="">{el.vosita_nomi.nomi}</p>
                              <p className="">{el.vosita_turi.nomi}</p>
                              <p className="">{el.vosita_seryasi}</p>
                              <p className="">{el.vosita_miqdori}</p>
                              <p className="">{el.created_at.split("T")[0]}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <h1>
                  {" "}
                  {!isLoading &&
                  detail?.shu_oy_chiqim?.length === 0 &&
                  value === 1
                    ? "Chiqimlar mavjud emas"
                    : ""}
                </h1>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="modal_manitoring_seans_info">
        <Modal
          keepMounted
          open={opens1}
          onClose={() => setOpens1(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className="modal-one" sx={{ ...style, width: 500 }}>
            <div className="modal_info_seans">
              <div className="block_one">
                {kirim === "region" ? (
                  <Button
                    startIcon={<ArrowBackIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() => setKirim("first")}
                  >
                    {" "}
                  </Button>
                ) : (
                  <Button
                    startIcon={<ArrowBackIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() => setOpens1(false)}
                  ></Button>
                )}
                <h4 className="seans_modal_title">{t("input.qil")} </h4>
              </div>
              <div className="partiya_number">
                {kirim === "region" ? (
                  <div>
                    <Button variant="outlined">
                      {t("bildirishnoma.tuman")}
                    </Button>{" "}
                    <Button style={{ marginTop: "15px" }} variant="outlined">
                      {t("bildirishnoma.single.vositainf")}
                    </Button>
                  </div>
                ) : (
                  <TextField
                    id="outlined-basic"
                    label={t("vosita.partiys")}
                    variant="outlined"
                    onChange={(e) => setPart(e.target.value)}
                  />
                )}
                <Button
                  style={{ marginTop: "15px" }}
                  variant="contained"
                  onClick={(e) => setKirim("region")}
                >
                  {t("bildirishnoma.jonat")}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            Box
            sx={{
              ...style,
              width: 700,
            }}
          >
            {modals === "null" ? (
              <div className="one_block">
                <Button
                  variant="contained"
                  value="oilavit"
                  onClick={(e) => setModals(e.target.value)}
                >
                  {t("bildirishnoma.single.vositainf")}
                </Button>
                <Button
                  variant="contained"
                  value="tuman"
                  onClick={(e) => setModals(e.target.value)}
                >
                  Tuman Tibbiyot Birlashmasi
                </Button>
              </div>
            ) : modals === "oilavit" ? (
              <div className="block_two">
                <Button variant="contained" onClick={() => setModals("null")}>
                  Prev
                </Button>
                <div
                  className="muassasa_block"
                  ref={ref}
                  onClick={() => setIsVisible((prev) => !prev)}
                >
                  <h6 className="muassasa_title">Oilaviy shifokorlik punkti</h6>
                  <div className="pos_btn">
                    <Button
                      color="primary"
                      startIcon={
                        isVisible === true ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                    ></Button>
                  </div>
                  <div
                    className="muassasa_block_inner"
                    style={
                      isVisible === true
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <TextField
                      id="outlined-basic"
                      label="Muassasa qidirish"
                      variant="outlined"
                      onChange={onChange}
                      name="name"
                    />
                    {ariza.data.muassasalar &&
                      ariza.data.muassasalar.map((item, index) => (
                        <div className="muassasa_block_inner_scrool">
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={check.some((el) => el == item.id)}
                                  onChange={(e) => addColumn(e.target.value)}
                                  name="kimga"
                                  color="primary"
                                  value={item.id}
                                />
                              }
                              label={item.nomi}
                            />
                          </FormGroup>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="block_two">
                <Button variant="contained" onClick={() => setModals("null")}>
                  Prev
                </Button>
                <div className="muassasa_block">
                  <h6 className="muassasa_title">
                    {t("bildirishnoma.single.vositainf")}
                  </h6>
                  <div className="pos_btn">
                    <Button
                      color="primary"
                      onClick={() => setOpens(!opens)}
                      startIcon={
                        opens === true ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                    ></Button>
                  </div>
                  <div
                    className="muassasa_block_inner"
                    style={
                      opens === true
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <TextField
                      id="outlined-basic"
                      label="Muassasa qidirish"
                      variant="outlined"
                      onChange={onChange}
                      name="name"
                    />
                    {ariza2.data.muassasalar &&
                      ariza2.data.muassasalar.map((item, index) => (
                        <div className="muassasa_block_inner_scrool">
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={check.some((el) => el == item.id)}
                                  onChange={(e) => addColumn(e.target.value)}
                                  name="kimga"
                                  color="primary"
                                  value={item.id}
                                />
                              }
                              label={item.nomi}
                            />
                          </FormGroup>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </Box>
        </Modal>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={openss} autoHideDuration={6000} onClose={handleCloses}>
          <Alert onClose={handleCloses} severity="error" sx={{ width: "100%" }}>
            Chiqim Qilinmadi!
          </Alert>
        </Snackbar>
        <Snackbar open={openss2} autoHideDuration={6000} onClose={handleCloses}>
          <Alert
            onClose={handleCloses}
            severity="success"
            sx={{ width: "100%" }}
          >
            Chiqim Qilindi!
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}
