import { FormControl, MenuItem, Select } from "@mui/material";
import { Contextvalue } from "context/context";
import { get } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { request } from "../../api/request";
import calcal from "../../assets/icon/calacala.svg";
import call from "../../assets/icon/call.svg";
import "./site-header.scss";
function Header() {
  const { firstMessage, twoMessage } = useContext(Contextvalue);
  const token = window.localStorage.token;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [not1, setNot1] = useState();
  const [vil, setVil] = useState();
  const [not2, setNot2] = useState();
  const [freemessage, setFreemessage] = useState([]);
  const [childmessage, setChildmessage] = useState([]);
  const id = localStorage.getItem("id");
  const tid = localStorage.getItem("tid");
  const uzb = localStorage.getItem("uzb");
  const vsb = localStorage.getItem("vsb");
  const moh = localStorage.getItem(token);

  for (let name in vil) {
    if (vil[name].viloyat_id == vsb) {
      var findsName = name;
    }

    for (let dist in vil[name]) {
      if (vil[name][dist].tuman_id == tid) {
        var findDistrict = dist;
      }
      for (let institution in vil[name][dist]) {
        if (vil[name][dist][institution].id == id) {
          var findInst = institution;
        }
      }
    }
  }
  useEffect(() => {
    request.get("/hududlar/", config).then((data) => setVil(data.data));
  }, []);

  useEffect(() => {
    if (vsb) {
      request
        .get(`/bildirishnoma/viloyat/erkin/`, config)
        .then((data) => setNot1(data.data.data))
        .catch((err) => console.log(err));
      request
        .get(`/bildirishnoma/viloyatga/`, config)
        .then((data) => setNot2(data.data.data))
        .catch((err) => console.log(err));
    }
    if (moh) {
      request
        .get("bildirishnoma/erkin/MOHga/", config)
        .then((data) =>
          setFreemessage(
            data.data.data.filter((el) => el.status === "Yuborildi")
          )
        );
      request
        .get("bildirishnoma/respublikaga/", config)
        .then((data) =>
          setChildmessage(
            data.data.data.filter((el) => el.status === "Yuborildi")
          )
        );
      if (firstMessage.length > 0) setFreemessage(get(firstMessage, "length"));
      if (twoMessage.length > 0) setChildmessage(get(twoMessage, "length"));
    }
  }, [vsb, moh, firstMessage, twoMessage]);

  const langs = localStorage.getItem("i18nextLng");
  const [age, setAge] = useState(langs);

  const { t, i18n } = useTranslation();
  const changeLanguage = (event) => {
    setAge(event.target.value);
    i18n.changeLanguage(event.target.value);
  };
  const Erkinvsb = not1 && not1.filter((el) => el.status === "Yuborildi");
  const Bolvsb = not2 && not2.filter((el) => el.status === "Yuborildi");
  return (
    <>
      <header className="site-header">
        <div className="header_inner">
          <div className="header_inner_block">
            <img src={call} alt="" />
            <a className="site-link" href="tel:998712394795">
              +998 (71) 239-47-95
            </a>
            <a className="site-link" href="tel:1003">
              1003
            </a>
          </div>
          <div className="header_inner_block">
            <div className="language">
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={age}
                  onChange={changeLanguage}
                  label="Age"
                >
                  <MenuItem value={"uz"}>O'zb</MenuItem>
                  <MenuItem MenuItem value={"krl"}>
                    Ўзб
                  </MenuItem>
                  <MenuItem value={"ru"}>Рус</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="header_right">
              <h3 className="hospital_name">
                {uzb && "Uzmedimpeks"}
                {moh && t("Sog'liqni saqlash vazirligi")}
                {vsb && findsName}
                {tid && "TTB " + findDistrict}
                {id && findInst}
              </h3>
              <h3 className="tex_title">
                {t("header.title")}:{" "}
                <a className="tex_link" href="https://t.me/+jVPcWYVezpw3NWUy">
                  Link
                </a>
              </h3>
            </div>
            <Link
              to={
                id
                  ? "/Nlpu"
                  : tid
                  ? "/bildirishnoma"
                  : uzb
                  ? "/uzbildirish"
                  : vsb
                  ? "/bildirishnoma_viloyat"
                  : "/mohbil"
              }
            >
              <button className="bildirishnoma_div">
                <img className="cal_cal" src={calcal} alt="" />
                {id && <span className="bildirish_uvi"></span>}
                {tid && <span className="bildirish_uvi"></span>}
                {vsb && (
                  <span className="bildirish_uvi">
                    {Erkinvsb && Bolvsb && Erkinvsb.length + Bolvsb.length}
                  </span>
                )}
                {moh && (
                  <span className={"bildirish_uvi"}>
                    {/* {firstMessage.length > 0 ? + firstMessage+twoMessage : twoMessage.length > 0 } */}
                    {get(freemessage, "length") + get(childmessage, "length")}
                  </span>
                )}
              </button>
            </Link>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
