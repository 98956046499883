import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	Fade,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Paper,
	Select,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	TextField,
} from "@mui/material";
import Koriklar from "components/component/seanslar/koriklar";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { request } from "../../../../../api/request";
import l1 from "../../../../../assets/icon/l1.svg";
import l4 from "../../../../../assets/icon/l4.svg";

const classes = {
	table: {
		minWidth: 700,
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		backgroundColor: "white",
		border: "2px solid #000",
		// boxShadow: theme.shadows[5],
		padding: "50px",
		width: "80%",
		margin: "30px auto 0 auto",
	},
	formControl: {
		margin: "1px",
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: "5px",
	},
	button: {
		padding: "8px",
		borderRadius: "12px",
	},
	button2: {
		backgroundColor: "#F69641",
	},
};

const Mohvos = () => {
	const [data, setData] = useState([]);
	const [vilid, setVilid] = useState();
	const [filterDate, setFilterDate] = useState(null);
	const [ttb, setTtb] = useState();
	const [comid, setComid] = useState();
	const [cardid, setCardid] = useState();
	const [targetid, setTargetid] = useState();
	const [close, setClose] = useState(true);
	const [stock, setStock] = useState([]);
	const [loader, setLoeder] = useState(true);
	const [bemId, setBemId] = useState(null);
	const [sea, setSea] = useState({
		isFetched: false,
		data: {},
		error: null,
	});
	const [status, setStatus] = useState(0);
	const [seans, setSeans] = useState(false);
	const [regions, setRegions] = useState([]);
	const [districts, setDistrcits] = useState([]);
	const [institutions, setInsitutions] = useState([]);
	const token = localStorage.getItem("token");
	const { t } = useTranslation();

	function Seansbemor(e) {
		setBemId(e);
		setSeans(true);
	}
	const handleSeansClose = () => {
		setSeans(false);
	};

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	function fetchPlaces() {
		request.get("/hududlar/", config).then(data => {
			setData(data.data);
			setRegions(
				Object.entries(data.data).map(([name, value]) => {
					return {
						name,
						id: value.viloyat_id,
						children:
							Object.entries(value)
								.filter(([key, _]) => key !== "viloyat_id")
								.map(([key, districts]) => ({
									name: key,
									id: districts.tuman_id,
									children:
										Object.entries(districts)
											.filter(([key, _]) => key !== "tuman_id")
											.map(([name, item]) => ({
												name,
												id: item.id,
											})) || 0,
								})) || [],
					};
				})
			);
		});
  }
  const reset = () => {
    setDistrcits([])
    setInsitutions([])
    setTargetid("")
    setTtb("")
    setStock([]);
  }
	function fetchData(id) {
		request
			.get(
				`/omborxona/moh/vositalar/manitoringi?viloyat=${id}&${ttb ? `tuman=${ttb}` : ""}&${
					targetid ? `muassasa=${targetid}` : ""
				}`,
				config
			)
			.then(data => setStock(data.data.flat(1)));
	}
	useEffect(() => {
		fetchPlaces();
	}, []);

	useEffect(() => {
		if (vilid) {
			fetchData(vilid);
		}
	}, [vilid, ttb, targetid]);

  useEffect(() => {
    fetchPlaces();
		if (vilid && regions?.length) {
			const regionChildren = regions.find(el => el.id == vilid)?.children || [];
			setDistrcits(regionChildren);
		}
		if (ttb && vilid) {
			const districtChildren = districts.find(el => el.id == ttb)?.children || [];
			setInsitutions(districtChildren);
		} else {
			setInsitutions([]);
		}
	}, [vilid, ttb]);

	return (
		<div className='prihod'>
			<div className='prihod_top'>
				<div className='prihod_top_inner'>
					<h4 className='monitoring_top_inner_title'>{t("sidebar.monitor")}</h4>
				</div>
			</div>
			<div style={{ display: "flex", marginTop: "28px" }}>
				<FormControl style={{ width: "385px", backgroundColor: "#fff" }} fullWidth>
					<InputLabel id='demo-simple-select-label'>{t("VSSB")}</InputLabel>
					<Select
						labelId='demo-simple-select-label'
						id='demo-simple-select'
						label={t("VSSB")}
						defaultValue={""}
						onChange={e => {
							setVilid(e.target.value);
							fetchData(e.target.value);
							reset();
						}}
						disabled={!regions?.length}
					>
						{regions.length ? (
							regions.map(el => <MenuItem value={el.id}>{el.name}</MenuItem>)
						) : (
							<MenuItem value={""}>{t("input.mavjud")}</MenuItem>
						)}
					</Select>
				</FormControl>
				<FormControl
					style={{
						width: "385px",
						backgroundColor: "#fff",
						marginLeft: "36px",
					}}
					fullWidth
				>
					<InputLabel id='demo-simple-select-label'>{t("pdf.rmo")}</InputLabel>
					<Select
						labelId='demo-simple-select-label'
						id='demo-simple-select'
            label={t("pdf.rmo")}
            value={ttb || ""}
            defaultValue={""}
						disabled={!vilid || !districts?.length}
						onChange={e => setTtb(e.target.value)}
					>
						{!!vilid && !!districts?.length && districts.map(el => <MenuItem value={el.id}>{el.name}</MenuItem>)}
					</Select>
				</FormControl>
				<FormControl
					style={{
						width: "385px",
						backgroundColor: "#fff",
						marginLeft: "36px",
					}}
					fullWidth
				>
					<InputLabel id='demo-simple-select-label'>{t("pdf.oshp")}</InputLabel>
					<Select
						labelId='demo-simple-select-label'
						id='demo-simple-select'
            label={t("pdf.oshp")}
            value={targetid || ""}
						disabled={!ttb || !vilid || !institutions?.length}
						onChange={e => setTargetid(e.target.value)}
					>
						{!!ttb && !!vilid && !!institutions ? (
							institutions.map(el => <MenuItem value={el.id}>{el.name}</MenuItem>)
						) : (
							<MenuItem value={""}>{t("input.mavjud")}</MenuItem>
						)}
					</Select>
				</FormControl>
			</div>
			{!targetid && (
				<div className='prihod_block'>
					<div className='prihod_block_inner'>
						<div className='prihod_block_inner_top'>
							<h4 className='prihod_block_inner_title'>{t("input.sps")}</h4>

							<Box style={{ display: "flex", alignItems: "center" }}>
								<TextField
									type='date'
									id='outlined-basic'
									value={filterDate}
									variant='outlined'
									onChange={e => setFilterDate(e.target.value)}
								/>
								<IconButton>{!!filterDate && <Close onClick={() => setFilterDate("")} />}</IconButton>
							</Box>
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<Tabs
									style={{ backgroundColor: "#fff" }}
									value={status}
									onChange={(e, newVal) => setStatus(newVal)}
									aria-label='basic tabs example'
								>
									<Tab label={t("bildirishnoma.kirim")} />
									<Tab label={t("bildirishnoma.chiqim")} />
								</Tabs>
							</Box>
						</div>
						<div className='prihod_block_inner_middle'>
							<div style={{ height: "600px", overflowY: "scroll" }} className='card_blocks'>
								{!stock[0] && <p>{t("bildirishnoma.region")}</p>}
								{stock &&
									status == 0 &&
									stock
										.filter(item => get(item, "kirim_chiqim.status", "") == "kirim" && item.created_at.includes(filterDate || ""))
										.map(item => {
											return (
												<div className='kirim_card' key={item.id}>
													<div className='kirim_card_left'>
														<Button size='large' className={classes.button} startIcon={<CallReceivedIcon />}></Button>
														<p>
															{t("shifokor.jami")}: {item.vosita_miqdori}
														</p>
													</div>
													<div style={{ display: "block" }} className='kirim_card_center'>
														<div
															style={{
																width: "250px",
																marginBottom: "7px",
																border: "none",
															}}
														>
															<span>
																{t("bildirishnoma.single.nomi")}:{item.vosita_nomi && item.vosita_nomi.nomi}
															</span>
														</div>
														<div style={{ width: "250px", border: "none" }}>
															<span>
																{t("vosita.vositaturi")}: {item.vosita_turi && item.vosita_turi.nomi}
															</span>
														</div>
													</div>
													<div style={{ justifyContent: "flex-end" }} className='kirim_card_right'>
														<div className='kirim_card_right_left'>
															<p>{item.created_at.split("T")[0]}</p>
															<span>{item.created_at.split("T")[1].slice(0, 5)}</span>
														</div>
														<div className='kirim_card_right_left'>
															<Button
																onClick={() => {
																	setComid(item.id);
																	setClose(true);
																}}
															>
																<img src={l1} />
															</Button>
														</div>
													</div>
												</div>
											);
										})}
								{stock &&
									status == 1 &&
									stock
										.filter(
											item => get(item, "kirim_chiqim.status", "") == "chiqim" && item.created_at.includes(filterDate || "")
										)
										.map(item => {
											return (
												<div className='kirim_card chiqim_card'>
													<div className='kirim_card_left chiqim_card_left'>
														<Button
															// variant="contained"
															// color="primary"
															size='large'
															className={classes.button}
															startIcon={<CallMadeIcon />}
														></Button>
														<p>
															{t("shifokor.jami")}: {item.vosita_miqdori}{" "}
														</p>
													</div>
													<div className='chiqim_card_center'>
														<div className='kirim_card_center_top'>
															<div className='top_left'>
																<p>{t("bildirishnoma.send")}:</p>
																<h5>{item.kirim_chiqim && item.kirim_chiqim.chiqim_qilingan_tashkilot}</h5>
															</div>
															<div className='top_left'>
																<p>{t("input.barkod")}:</p>
																<h5>
																	{item.kirim_chiqim && item.kirim_chiqim.unique_raqam
																		? item.kirim_chiqim.unique_raqam
																		: t("bola.kir")}
																</h5>
															</div>
															<div className='top_right'>
																<div className='kirim_card_right_left'>
																	<p>{item.created_at.split("T")[0]}</p>
																	<span>{item.created_at.split("T")[1].slice(0, 5)}</span>
																</div>
																<div className='kirim_card_right_left'>
																	<Button
																		onClick={() => {
																			setCardid(item.id);
																			setClose(true);
																		}}
																	>
																		<img src={l1} />
																	</Button>
																</div>
															</div>
														</div>
														<div
															className=''
															style={{
																display: "flex",
																flexDirection: "column",
																paddingTop: "1rem",
															}}
														>
															<div>
																<span>
																	{t("bildirishnoma.single.nomi")}: {item.vosita_nomi && item.vosita_nomi.nomi}
																</span>
															</div>
															<div>
																<span>
																	{t("vosita.vositaturi")}: {item.vosita_turi && item.vosita_turi.nomi}
																</span>
															</div>
														</div>
													</div>
												</div>
											);
										})}
							</div>
						</div>
					</div>
					<div className='prihod_block_inner'>
						<div className='kirim_right_inner_top'>
							<h4>{status === 0 ? t("bildirishnoma.kirim") : t("bildirishnoma.chiqim")} </h4>
							<Button onClick={() => setClose(false)} variant='contained' startIcon={<CloseIcon />}>
								{t("input.yop")}
							</Button>
						</div>
						{close && (
							<div className='kirim_right_inner_bottom'>
								<div className='kirim_right_inner_bottom_bottom'>
									<TableContainer style={{ borderRadius: "12px" }} component={Paper}>
										<Table style={{ minWidth: 650 }} size='small' aria-label='a dense table'>
											<TableHead>
												<TableRow>
													<TableCell align='center'>{t("Vosita nomi")}</TableCell>
													<TableCell align='center'>{t("Vosita Turi")}</TableCell>
													<TableCell align='center'>{t("Vosita Seriyasi")}</TableCell>
													<TableCell align='center'>{t("bildirishnoma.single.miqdori")}</TableCell>
													<TableCell align='center'>{t("input.barkod")}</TableCell>
													<TableCell align='center'>{t("input.yuk")}</TableCell>
													<TableCell align='center'>{t("Rasm Yuklash")}</TableCell>
													<TableCell align='center'>{t("bildirishnoma.status")}</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{stock &&
													stock
														.filter(item => +item.id === +comid)
														.map(el => {
															return (
																<TableRow>
																	<TableCell align='center'>{el.vosita_nomi && el.vosita_nomi.nomi}</TableCell>
																	<TableCell align='center'>{el.vosita_turi && el.vosita_turi.nomi}</TableCell>
																	<TableCell align='center'>{el.vosita_seryasi}</TableCell>
																	<TableCell align='center'>{el.vosita_miqdori}</TableCell>
																	<TableCell align='center'>{t("input.mavjud")}</TableCell>
																	<TableCell align='center'>{t("input.mavjud")}</TableCell>
																	<TableCell align='center'>{t("input.mavjud")}</TableCell>
																	<TableCell align='center'>{t("bildirishnoma.kirim")}</TableCell>
																</TableRow>
															);
														})}
												{stock &&
													stock
														.filter(item => +item.id === +cardid)
														.map(el => {
															return (
																<TableRow>
																	<TableCell align='center'>{el.vosita_nomi && el.vosita_nomi.nomi}</TableCell>
																	<TableCell align='center'>{el.vosita_turi && el.vosita_turi.nomi}</TableCell>
																	<TableCell align='center'>{el.vosita_seryasi}</TableCell>
																	<TableCell align='center'>{el.vosita_miqdori}</TableCell>
																	<TableCell align='center'>{el.kirim_chiqim.unique_raqam}</TableCell>
																	<TableCell align='center'>{el.kirim_chiqim.comment}</TableCell>
																	<TableCell align='center'>
																		{el.fayl ? (
																			<a
																				style={{ border: "none" }}
																				className='div-1'
																				href={`https://admin-mpbt.ssv.uz/static${el.kirim_chiqim.image}`}
																				download
																			>
																				{el.fayl && el.fayl.fayl} {t("yuk")}
																			</a>
																		) : (
																			t("Fayl mavjud emas")
																		)}{" "}
																	</TableCell>
																	<TableCell align='center'>{t("bildirishnoma.chiqim")}</TableCell>
																</TableRow>
															);
														})}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			{targetid && (
				<TableContainer style={{ marginTop: "24px" }} component={Paper}>
					<Table className={classes.table} aria-label='customized table'>
						<TableHead>
							<TableRow style={{ backgroundColor: "white" }}>
								<TableCell>{t("shifokor.number")}</TableCell>
								<TableCell align='left'>{t("input.pfl")}</TableCell>
								<TableCell align='left'>
									{t("shifokor.alladd.name")} {t("shifokor.alladd.surname")} {t("shifokor.alladd.otch")}
								</TableCell>
								<TableCell align='left'>{t("shifokor.birthday")}</TableCell>
								<TableCell align='center'>{t("shifokor.batafsil")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{stock.map((row, index) => (
								<TableRow key={row.id}>
									<TableCell align='left'>{index + 1}</TableCell>
									<TableCell align='left'>{row.JSHSHIR}</TableCell>
									<TableCell align='left'>{`${row.familiya} ${row.ism} ${row.otasining_ismi}`}</TableCell>
									<TableCell align='left'>{row.tugilgan_sana}</TableCell>
									<TableCell align='right'>
										<div className='button_modal button_modal_1'>
											<Link Link to={`/mohsingle/${row.id}`} className='single_info'>
												<img className='delete_icon' src={l1} alt='batafsil' />
											</Link>
											<div className='seans_div'>
												<Button className='seanslar_btn_muassasa' onClick={() => Seansbemor(row.id)}>
													<img src={l4} alt='...' />
												</Button>
											</div>
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<div className='modal_seans'>
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					className={classes.modal_one}
					open={seans}
					onClose={handleSeansClose}
					closeAfterTransition
					BackdropProps={{
						timeout: 400,
					}}
					style={{
						marginTop: "0",
						width: "900px",
						marginLeft: "auto",
						marginRight: "auto",
					}}
				>
					<Fade in={seans}>
						<div style={classes.paper}>
							<Koriklar
								Seansbemor={Seansbemor}
								sea={sea}
								setSea={setSea}
								id={bemId}
								handleSeansClose={handleSeansClose}
								loader={loader}
								setLoeder={setLoeder}
							/>
						</div>
					</Fade>
				</Modal>
			</div>
		</div>
	);
};
export default Mohvos;
