import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  Button, FormControl,
  InputLabel,
  MenuItem, Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs
} from "@mui/material";
import { Box } from "@mui/system";
import Koriklar from "components/component/seanslar/koriklar";
import dayjs from "dayjs";
import { get, isArray } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { request } from "../../../../api/request";
import l1 from "../../../../assets/icon/l1.svg";
import l4 from "../../../../assets/icon/l4.svg";
import "./manitoring.scss";
const Monitoringobl = () => {
  const handleSeansClose = () => {
    setSeans(false);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState();
  const [stock, setStock] = useState([]);
  const [cardid, setCardid] = useState();
  const [comid, setComid] = useState();
  const [close, setClose] = useState(false);
  const [value, setValue] = useState(0);
  const [bemId, setBemId] = useState(null);
  const [query, setQuery] = useState({});
  const classes = {
    table: {
      minWidth: 700,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      padding: "50px",
      width: "80%",
      margin: "30px auto 0 auto",
    },
    formControl: {
      margin: "1px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    button: {
      padding: "8px",
      borderRadius: "12px",
    },
  };

  const vsb = localStorage.getItem("vsb");

  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };
  const [seans, setSeans] = useState(false);
  function Seansbemor(e) {
    setBemId(e);
    setSeans(true);
  }
  const { t } = useTranslation();
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const ttbChange = (e) => {
    setQuery((prev) => ({ ...prev, ttb: e.target.value }));
    setSearchParams({ ...query, ttb: e.target.value });
  };
  const oshpChange = (e) => {
    setQuery((prev) => ({ ...prev, targetid: e.target.value }));
    setSearchParams({ ...query, targetid: e.target.value });
  };
  const removeOshp = () => {
    setQuery({ ttb: get(query, "ttb") });
    setSearchParams({ ttb: get(query, "ttb") });
  };
  useEffect(() => {
    request.get("/hududlar/", config).then((data) => setData(data.data));
  }, []);
  const findName =
    data && Object.keys(data).find((el) => +data[el].viloyat_id === +vsb);
  const findRegion =
    findName &&
    Object.keys(data[findName])
      .filter((item) => item !== "viloyat_id")
      .find((el) => data[findName][el].tuman_id == get(query, "ttb"));

  useEffect(() => {
    request
      .get(
        `/omborxona/vssb/vositalar/manitoringi?${
          query?.ttb ? `tuman=${get(query, "ttb")}` : ""
        }&${query?.targetid ? `muassasa=${get(query, "targetid")}` : ""}`,
        config
      )
      .then((data) => setStock(data.data.map((el) => el)));
  }, [query]);

  useEffect(() => {
    if (searchParams.get("targetid"))
      setQuery((prev) => ({
        ...prev,
        targetid: +searchParams.get("targetid"),
      }));
    if (searchParams.get("ttb"))
      setQuery((prev) => ({ ...prev, ttb: +searchParams.get("ttb") }));
  }, []);
  const { kirim = [], chiqim = [] } = useMemo(() => {
    let data = stock.flat(1);
    return {
      kirim: data.filter((item) => item.kirim_chiqim?.status === "kirim"),
      chiqim: data.filter((item) => item.kirim_chiqim?.status === "chiqim"),
    };
  }, [stock]);

  const monitoringMapper = (data) =>
    data.map((item) => ({
      ...item,
      date: dayjs(item.created_at).format("YYYY-MM-DD"),
      time: dayjs(item.created_at).format("HH:mm"),
    }));

  return (
    <div className="monitoring">
      <div className="monitoring-header">
        <div>
          <FormControl
            style={{
              width: "385px",
              backgroundColor: "#fff",
              marginTop: "20px",
            }}
            fullWidth
          >
            <InputLabel id="demo-simple-select-label">
              {t("pdf.rmo")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t("bildirishnoma.single.nomiinput")}
              onChange={(e) => ttbChange(e)}
              value={get(query, "ttb") || ""}
            >
              {findName &&
                Object.keys(data[findName])
                  .filter((item) => item !== "viloyat_id")
                  .map((el, index) => (
                    <MenuItem key={index} value={data[findName][el].tuman_id}>
                      {el}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl
            style={{
              width: "385px",
              backgroundColor: "#fff",
              marginLeft: "36px",
              marginTop: "20px",
            }}
            fullWidth
          >
            <InputLabel id="demo-simple-select-label">
              {t("pdf.oshp")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t("bildirishnoma.single.nomiinput")}
              // disabled={!get(ttb,"ttb")}
              value={get(query, "targetid") || ""}
              onChange={(e) => oshpChange(e)}
              inputProps={{
                IconComponent: () => (
                  <Button onClick={removeOshp} startIcon={<ClearIcon />} />
                ),
              }}
            >
              {findRegion &&
                Object.keys(data[findName][findRegion])
                  .filter((item) => item !== "tuman_id")
                  .map((el, index) => (
                    <MenuItem
                      key={index}
                      value={data[findName][findRegion][el].id}
                    >
                      {el}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </div>
        <CSVLink
          data={monitoringMapper(value === 0 ? kirim : chiqim)}
          headers={[
            { label: "Sanasi", key: "date" },
            { label: "Vaqti", key: "time" },
            { label: "Partiya raqami", key: "kirim_chiqim.partiya_raqam" },
            { label: "Vosita nomi", key: "vosita_nomi.nomi" },
            { label: "Vosita turi", key: "vosita_turi.nomi" },
            { label: "Vosita seriyasi", key: "vosita_seryasi" },
            { label: "Barkod", key: "kirim_chiqim.unique_raqam" },
            { label: "Miqdori", key: "vosita_miqdori" },
            { label: "Yuk xati", key: "kirim_chiqim.comment" },
          ]}
          filename={value === 0 ? "Kirimlar" : "Chiqimlar"}
          className="excel_download"
          separator=";"
        >
          <Button
            style={{ backgroundColor: "#18CF6C", marginLeft: "20px" }}
            variant="contained"
            // color="primary"
            size="large"
            startIcon={<DescriptionIcon />}
          >
            {t("bola.excel")}
          </Button>
        </CSVLink>
      </div>
      {!get(query, "targetid") && (
        <>
          <div style={{ display: "flex", marginTop: "28px" }}></div>
          <div className="prihod_block">
            <div className="prihod_block_inner">
              <div className="prihod_block_inner_top">
                <h4 className="prihod_block_inner_title">{t("input.sps")}</h4>
                {/* <TextField type="date" id="outlined-basic" variant="outlined" /> */}
              </div>
              <div className="prihod_block_inner_middle">
                {stock[0] && (
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      style={{ backgroundColor: "#fff" }}
                      value={value}
                      onChange={handleChanges}
                      aria-label="basic tabs example"
                    >
                      <Tab label={t("bildirishnoma.kirim")} />
                      <Tab label={t("bildirishnoma.chiqim")} />
                    </Tabs>
                  </Box>
                )}
                <div
                  style={{ height: "600px", overflowY: "scroll" }}
                  className="card_blocks"
                >
                  {!stock[0] && <p>{t("bildirishnoma.region")}</p>}
                  {stock &&
                    value === 0 &&
                    kirim.map((item) => {
                      return (
                        <div className="kirim_card">
                          <div className="kirim_card_left">
                            <Button
                              // variant="contained"
                              // color="primary"
                              size="large"
                              className={classes.button}
                              startIcon={<CallReceivedIcon />}
                            ></Button>
                            <p>
                              {t("shifokor.jami")}: {item.vosita_miqdori}
                            </p>
                          </div>
                          <div className="kirimvositanomi">
                            <div className="kirimvositanomi__div">
                              <span>
                                {/* Vos""it Nomi:{" "} */}
                                {t("bildirishnoma.single.nomi")}:{""}
                                {item.vosita_nomi && item.vosita_nomi.nomi}
                              </span>
                            </div>
                            <div className="kirimvositanomi__div">
                              <span>
                                {t("vosita.vositaturi")}:{""}
                                {item.vosita_turi && item.vosita_turi.nomi}
                              </span>
                            </div>
                          </div>
                          <div
                            className="kirim_card_right"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <div className="kirim_card_right_left">
                              <p>{item.created_at.split("T")[0]}</p>
                              <span>
                                {item.created_at.split("T")[1].slice(0, 5)}
                              </span>
                            </div>
                            <div className="kirim_card_right_left">
                              <Button
                                onClick={() => {
                                  setComid(item.id);
                                  setClose(true);
                                }}
                              >
                                <img src={l1} alt="..." />
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {stock &&
                    value === 1 &&
                    chiqim.map((item) => {
                      return (
                        <div className="kirim_card chiqim_card">
                          <div className="kirim_card_left chiqim_card_left">
                            <Button
                              size="large"
                              className={classes.button}
                              startIcon={<CallMadeIcon />}
                            ></Button>
                            <p>
                              {t("shifokor.jami")}: {item.vosita_miqdori}
                            </p>
                          </div>
                          <div className="chiqim_card_center">
                            <div className="kirim_card_center_top">
                              <div className="top_left">
                                <p>{t("bildirishnoma.send")}:</p>
                                <h5>
                                  {item.kirim_chiqim &&
                                    item.kirim_chiqim.chiqim_qilingan_tashkilot}
                                </h5>
                              </div>
                              <div className="top_left">
                                <p>{t("input.barkod")}:</p>
                                <h5>
                                  {item.kirim_chiqim &&
                                  item.kirim_chiqim.unique_raqam
                                    ? item.kirim_chiqim.unique_raqam
                                    : "Kiritilmagan"}
                                </h5>
                              </div>
                              <div className="top_right">
                                <div className="kirim_card_right_left">
                                  <p>{item.created_at.split("T")[0]}</p>
                                  <span>
                                    {item.created_at.split("T")[1].slice(0, 5)}
                                  </span>
                                </div>
                                <div className="kirim_card_right_left">
                                  <Button
                                    onClick={() => {
                                      setCardid(item.id);
                                      setClose(true);
                                    }}
                                  >
                                    <img src={l1} alt="...." />
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div className="bottom">
                              <div>
                                <span>
                                  {t("bildirishnoma.single.nomi")}:
                                  {item.vosita_nomi && item.vosita_nomi.nomi}
                                </span>
                              </div>
                              <div>
                                <span>
                                  {t("vosita.vositaturi")}:{" "}
                                  {item.vosita_turi && item.vosita_turi.nomi}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="prihod_block_inner">
              <div className="kirim_right_inner_top">
                <h4>{t("bildirishnoma.single.ortga")} </h4>
                <Button
                  onClick={() => setClose(false)}
                  variant="contained"
                  startIcon={<CloseIcon />}
                >
                  {t("input.yop")}
                </Button>
              </div>
              {close && (
                <div className="kirim_right_inner_bottom">
                  <div className="kirim_right_inner_bottom_bottom">
                    <TableContainer
                      style={{ borderRadius: "12px" }}
                      component={Paper}
                    >
                      <Table
                        style={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">
                              {t("bildirishnoma.single.nomi")}
                            </TableCell>
                            <TableCell align="center">Vositani Turi</TableCell>
                            <TableCell align="center">
                              Vositani Seriyasi
                            </TableCell>
                            <TableCell align="center">
                              {t("bildirishnoma.single.miqdori")}
                            </TableCell>
                            <TableCell align="center">
                              {t("input.barkod")}
                            </TableCell>
                            <TableCell align="center">
                              {t("input.yuk")}
                            </TableCell>
                            <TableCell align="center">Rasm Yuklash</TableCell>
                            <TableCell align="center">
                              {t("bildirishnoma.status")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stock &&
                            kirim
                              ?.filter((item) => +item.id === +comid)
                              .map((el) => {
                                return (
                                  <TableRow>
                                    <TableCell align="center">
                                      {el.vosita_nomi && el.vosita_nomi.nomi}
                                    </TableCell>
                                    <TableCell align="center">
                                      {el.vosita_turi && el.vosita_turi.nomi}
                                    </TableCell>
                                    <TableCell align="center">
                                      {el.vosita_seryasi}
                                    </TableCell>
                                    <TableCell align="center">
                                      {el.vosita_miqdori}
                                    </TableCell>
                                    <TableCell align="center">
                                      Mavjud Emas
                                    </TableCell>
                                    <TableCell align="center">
                                      Mavjud Emas
                                    </TableCell>
                                    <TableCell align="center">
                                      Mavjud Emas
                                    </TableCell>
                                    <TableCell align="center">
                                      {t("bildirishnoma.kirim")}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          {stock &&
                            chiqim
                              .filter((item) => +item.id === +cardid)
                              .map((el) => {
                                return (
                                  <TableRow>
                                    <TableCell align="center">
                                      {el.vosita_nomi && el.vosita_nomi.nomi}
                                    </TableCell>
                                    <TableCell align="center">
                                      {el.vosita_turi && el.vosita_turi.nomi}
                                    </TableCell>
                                    <TableCell align="center">
                                      {el.vosita_seryasi}
                                    </TableCell>
                                    <TableCell align="center">
                                      {el.vosita_miqdori}
                                    </TableCell>
                                    <TableCell align="center">
                                      {el.kirim_chiqim.unique_raqam}
                                    </TableCell>
                                    <TableCell align="center">
                                      {el.kirim_chiqim.comment}
                                    </TableCell>
                                    <TableCell align="center">
                                      <a
                                        href={`https://admin-mpbt.ssv.uz/static${el.kirim_chiqim.image}`}
                                      >
                                        {t("yuk")}{" "}
                                      </a>{" "}
                                    </TableCell>
                                    <TableCell align="center">
                                      {t("bildirishnoma.chiqim")}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {get(query, "targetid") && (
        <>
          <TableContainer style={{ marginTop: "24px" }} component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow style={{ backgroundColor: "white" }}>
                  <TableCell>{t("shifokor.number")}</TableCell>
                  <TableCell align="left">{t("input.pfl")}</TableCell>
                  <TableCell align="left">
                    {t("shifokor.alladd.name")} {t("shifokor.alladd.surname")}{" "}
                    {t("shifokor.alladd.otch")}
                  </TableCell>
                  <TableCell align="left">{t("shifokor.birthday")}</TableCell>
                  <TableCell align="center">{t("shifokor.batafsil")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stock.map((el) => {
                  return isArray(el)
                    ? el?.map((row, index) => (
                        <TableRow>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">{row.JSHSHIR}</TableCell>
                          <TableCell align="left">{`${row.familiya} ${row.ism} ${row.otasining_ismi}`}</TableCell>
                          <TableCell align="left">
                            {row.tugilgan_sana}
                          </TableCell>
                          <TableCell align="right">
                            <div className="button_modal button_modal_1">
                              <Link
                                Link
                                to={`/vsbsingle/${row.id}`}
                                className="single_info"
                              >
                                <img
                                  className="delete_icon"
                                  src={l1}
                                  alt="batafsil"
                                />
                              </Link>
                              <div className="seans_div">
                                <Button
                                  className="seanslar_btn_muassasa"
                                  onClick={() => Seansbemor(row.id)}
                                >
                                  <img src={l4} alt="..." />
                                </Button>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    : "";
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="modal_seans">
            <Koriklar id={bemId} open={seans} handleClose={handleSeansClose} />
          </div>
        </>
      )}
    </div>
  );
};

export default Monitoringobl;
