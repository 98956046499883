import "./loading.scss";
function Loading () {
    
    return (
      <>
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </>
    );
}
export default Loading;