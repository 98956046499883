import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Fade,
  InputAdornment,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { forwardRef, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { request } from "../../../api/request";
import qs from "qs";
import l1 from "../../../assets/icon/l1.svg";
import "./ariza.scss";
import usePost from "hooks/usePost";
import Status from "./components/Status";
import dayjs from "dayjs";
import { get } from "lodash";
export default function Arizaobls() {
  const { t } = useTranslation();
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [noti, setNoti] = useState(false);
  const [notificationn, setNotificationn] = useState({
    state: "",
    text: "",
  });
  const token = window.localStorage.token;
  const [loader, setLoeder] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const locationValues = qs.parse(location.search.slice(1));
  const [tab, setTab] = useState(1);
  const [checkboxs, setCheckboxs] = useState(false);
  const [checkblock, setCheckblock] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { mutate } = usePost();

  const handleClick = () => {
    setNoti(true);
  };

  const handlenoti = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNoti(false);
  };

  const classes = {
    table: {
      minWidth: 700,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      padding: "10px",
      width: "80%",
      margin: "30px auto 0 auto",
      borderRadius: "12px",
    },
    formControl: {
      margin: "1px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    button: {
      padding: "8px",
      borderRadius: "12px",
    },
  };
  const [open2, setOpen2] = useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [newarr, setNewarr] = useState([]);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [erkins1, setErkins1] = useState({
    isFetched: false,
    data: [],
    error: null,
  });
  useEffect(() => {
    if (tab === 1) {
      request
        .get(`/ariza/vssb/`, config)
        .then(function (res) {
          if (searchValue.length > 2){
            setNewarr(res.data.data.filter(
              (item) =>
                item.kimdan
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                  dayjs(item.vaqti).format("YYYY-MM-DD")
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
            ))
          }
          else {
            setNewarr(res.data.data);
          }
        })
        .catch(function (err) {
          setLoeder(true);
        });
    } else if (tab === 2) {
      request
        .get(`/ariza/vssb/birlashtirish/`, config)
        .then(function (res) {
          if (searchValue.length > 2){
            setErkins1({
              isFetched: true,
              data: res.data.data.filter(el => el.date.split("T")[0].includes(searchValue)),
              error: false,
            });
          }
          else {
            setErkins1({
              isFetched: true,
              data: res.data.data,
              error: false,
            });
          }
          setNewarr(res.data.data);
        })
        .catch(function (err) {
          setErkins1({
            isFetched: false,
            data: [],
            error: err,
          });
          setLoeder(true);
        });
    }
  }, [loader, tab,searchValue]);

  const isViewed = (id) => {
    const data = new FormData();
    data.append("id", id);
    data.append("status", "O'qildi");
    mutate({
      url: "/ariza/vssb/",
      data: data,
    });
  };

  function addColumn(e) {
    if (checkblock && checkblock.length > 0) {
      let ss = checkblock.filter((elem) => elem == e);
      if (ss && ss.length > 0) {
        setCheckblock(checkblock.filter((item) => item != e));
      } else {
        setCheckblock([...checkblock, `${e}`]);
      }
    } else {
      setCheckblock([...checkblock, `${e}`]);
    }
  }

  function Send() {
    const formmdata = new FormData();
    formmdata.append("arizalar", checkblock);
    request
      .post(`ariza/vssb/birlashtirish/`, formmdata, config)
      .then((res) => {
        setNotificationn({
          state: "success",
          text: `Ariza birlashdi`,
        });
        setLoeder(false);
        setCheckboxs(!checkboxs);
        handleClick(true);
      })
      .catch(function (err) {
        setNotificationn({
          state: "error",
          text: `Ariza birlashmadi`,
        });
        alert(err.response.data.error);
        setLoeder(false);
        handleClick(true);
      });

    setLoeder(true);
  }
  const [data, setData] = useState([]);
  useEffect(() => {
    request
      .get("/ariza/vssb/mohgayuborish/", config)
      .then((data) => {
        if (searchValue.length > 2){
          setData(data.data.data.filter(el => el.date.split("T")[0].includes(searchValue)))
        }
        else{
          setData(data.data.data)
        }
      });
  }, [searchValue]);
  useEffect(() => {
    locationValues.value && setTab(JSON.parse(locationValues.value));
  }, []);
 
  return (
    <div className="ariza">
      <div className="ariza_top">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 className="ariza_top_title">
            {t("bildirishnoma.allariza")}:{" "}
            {tab === 1
              ? newarr?.length
              : tab === 2
                ? erkins1?.data?.length 
                : data?.length}
          </h4>
          <TextField
            className="search-ariza"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t("bildirishnoma.plac")}
            style={{ marginLeft: "40px" }}
            id="standard-basic"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ position: "absolute", right: "18px" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {tab === 1 && (
          <div className="create_ariza_btn">
            {checkboxs === true ? (
              <div>
                <Button
                  style={{ marginRight: "15px" }}
                  onClick={() => setCheckboxs(!checkboxs)}
                  variant="green"
                  startIcon={<CloseIcon />}
                >
                  {t("bildirishnoma.single.bekor")}
                </Button>
              </div>
            ) : null}
            {checkboxs === false ? (
              <Button
                onClick={() => setCheckboxs(!checkboxs)}
                variant="contained"
                startIcon={<AddIcon />}
              >
                {t("vosita.birlash")}
              </Button>
            ) : (
              <Button
                onClick={() => Send()}
                variant="contained"
                startIcon={<CheckIcon />}
              >
                {t("vosita.birlash")}
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="ariza_bottom">
        <div className="ariza_bottom_top">
          <Button
            onClick={() => {
              setTab(1);
              navigate(`${location.pathname}?${qs.stringify({ value: 1 })}`);
            }}
            style={
              tab === 1
                ? { color: "#1464C0", borderBottom: "2px solid #1464C0" }
                : { color: "black" }
            }
          >
            {t("input.qabul")}
          </Button>

          <Button
            onClick={() => {
              setTab(2);
              navigate(`${location.pathname}?${qs.stringify({ value: 2 })}`);
            }}
            style={
              tab === 2
                ? { color: "#1464C0", borderBottom: "2px solid #1464C0" }
                : { color: "black" }
            }
          >
            {t("vosita.bir")}
          </Button>
          <Button
            onClick={() => {
              setTab(3);
              navigate(`${location.pathname}?${qs.stringify({ value: 3 })}`);
            }}
            style={
              tab === 3
                ? { color: "#1464C0", borderBottom: "2px solid #1464C0" }
                : { color: "black" }
            }
          >
            {t("input.yubor")}
          </Button>
        </div>
        <div className="ariza_bottom_bottom">
          {tab === 1 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "white" }}>
                    <TableCell>{t("bildirishnoma.single.soni")}</TableCell>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">
                      {t("bildirishnoma.single.kimdan")}
                    </TableCell>
                    <TableCell align="center">
                      {t("bildirishnoma.sana")}
                    </TableCell>
                    <TableCell align="center">
                      {t("bildirishnoma.single.status")}
                    </TableCell>

                    <TableCell align="center">
                      {t("bildirishnoma.harakat")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newarr
                    .map((item, index) => {
                      return (
                        <Fragment key={item.id}>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "60px",
                                cursor: "pointer",
                              }}
                            >
                              <p style={{ marginRight: "12px" }}>{index + 1}</p>
                              {checkboxs === true ? (
                                <div className="check_inp_block">
                                  <input
                                    className="check_box_inp_inner"
                                    type="checkbox"
                                    name=""
                                    checked={checkblock?.arizalar?.some(
                                      (el) => el == item.id
                                    )}
                                    onClick={(e) =>
                                      addColumn(item.id, item.bildirishnoma_id)
                                    }
                                  />
                                </div>
                              ) : null}
                              <div className="ariza_bgc"></div>
                            </TableCell>
                            <TableCell align="center">{item.id}</TableCell>
                            <TableCell align="center">{item.kimdan}</TableCell>
                            <TableCell align="center">
                              {item.vaqti?.slice(0, 10)}
                            </TableCell>
                            <TableCell align="center!important">
                              {item.status !== "O'qildi" ? (
                                <Status
                                  status={"Yangi"}
                                  label={t("bildirishnoma.arstatus.yangi")}
                                />
                              ) : (
                                <Status
                                  status={"O'qildi"}
                                  label={t("vosita.oqil")}
                                />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <div className="button_modal button_modal_1">
                                <Link
                                  to={`/rolarizasobl/${item.id}`}
                                  className="single_info"
                                  onClick={() => isViewed(item.id)}
                                >
                                  <img
                                    className="delete_icon"
                                    src={l1}
                                    alt="batafsil"
                                  />
                                </Link>
                              </div>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {tab === 2 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "white" }}>
                    <TableCell>{t("bildirishnoma.single.soni")}</TableCell>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">
                      {t("bildirishnoma.sana")}
                    </TableCell>

                    <TableCell align="center">
                      {t("bildirishnoma.harakat")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    get(erkins1,"data").map((item, index) => {
                      return (
                        <Fragment key={item.id}>
                          <TableRow>
                            <TableCell align="left">
                              {index + 1}
                              <div className="ariza_bgc"></div>
                            </TableCell>
                            <TableCell align="center">{item.id}</TableCell>
                            <TableCell align="center">
                              {dayjs(item.date).format("YYYY-MM-DD")}
                            </TableCell>

                            <TableCell align="center">
                              <div className="button_modal button_modal_1">
                                <Link
                                  Link
                                  to={`/rolarizabirlashtirish/${item.id}`}
                                  className="single_info"
                                >
                                  <img
                                    className="delete_icon"
                                    src={l1}
                                    alt="batafsil"
                                  />
                                </Link>
                              </div>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {tab === 3 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "white" }}>
                    <TableCell>{t("bildirishnoma.single.soni")}</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>{t("bildirishnoma.single.kimdan")}</TableCell>
                    <TableCell>{t("bildirishnoma.sana")}</TableCell>
                    <TableCell align="center">
                      {t("bildirishnoma.status")}
                    </TableCell>
                    <TableCell>{t("bildirishnoma.harakat")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((el, index) => {
                    return (
                      <TableRow key={el.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{el.id}</TableCell>
                        <TableCell>{el.kimdan}</TableCell>
                        <TableCell>{el.date.split("T")[0]}</TableCell>
                        <TableCell>
                          {el.status === "O'qildi" ? (
                            <Status
                              status={"O'qildi"}
                              label={t("vosita.oqil")}
                            />
                          ) : (
                            <Status
                              status={"Yangi"}
                              label={t("vosita.yubor")}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <Link to={`/sendariza/${el.id}`}>
                            <img src={l1}  alt="eye"/>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="modal_one_99">
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal_one}
            open={open2}
            onClose={handleClose2}
            closeAfterTransition
            BackdropProps={{
              timeout: 400,
            }}
            style={{
              marginTop: "200px",
              width: "600px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Fade in={open2}>
              <div style={classes.paper}>
                <div className="zayavka_block">
                  <Button
                    style={{
                      color: "black",
                      textAlign: "right",
                      margin: "0 0 auto auto",
                      display: "flex",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => handleClose2()}
                  ></Button>
                  <h4 className="zayavka_title">{t("modalariza.arizaturi")}</h4>
                  <div className="delete_btn_group">
                    <Link to={"/sarflov"} className="jayavka_btn">
                      Sarflov vositalar
                    </Link>
                    <Link to={"/apelatsion"} className="jayavka_btn">
                      Jihozlar va ehtiyot qismlar
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
        <Snackbar
          Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={noti}
          autoHideDuration={6000}
          onClose={handlenoti}
        >
          <Alert
            Alert
            onClose={handlenoti}
            severity={notificationn.state}
            sx={{
              width: "100%",
            }}
          >
            {notificationn.text}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
