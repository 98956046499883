import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Contextvalue } from "../../../../../../context/context";
import { useTranslation } from 'react-i18next';
import { Box, Chip } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { name } from "dayjs/locale/uz";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Mohmenu({ names }) {
  // console.log(names, "names")
  const [personName, setPersonName] = React.useState([]);
  const { setMen } = React.useContext(Contextvalue);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMen(event.target.value.flat())
    // console.log(event.target.value)
    setPersonName(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const deleteHandler = (e) => { 
    console.log(e)
  }

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setMen(names.map((name) => name.id));
      setPersonName(names);
    } else {
      setMen([]);
      setPersonName([]);
    }
  };


  return (
    <div>
      <FormControl style={{ minWidth: 400, width: "100%", maxWidth: "100%", flexWrap: "wrap" }}>
        <InputLabel id="demo-multiple-checkbox-label">{t("pdf.vs")}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label={t("pdf.vs")} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex",flexWrap: "wrap", gap: 0.5, overflow: "auto" }}>
              {selected.flat().map((value) => (
                <Chip key={value.id} label={value.nomi ? value.nomi.split(" ")[0]:null} />
              ))}
              
            </Box>
          )}

          MenuProps={MenuProps}
        >
          <MenuItem  value={names}>
              <Checkbox checked={personName.flat().length === 14?-1:0}  />
              <ListItemText primary={"Hammasi"} />
            </MenuItem> 
          {names.map((name) => (
            <MenuItem key={name.id} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name.nomi} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
