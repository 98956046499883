import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { Button, Snackbar, TextareaAutosize, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { forwardRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { request } from "../../../../../../api/request";
import pdfDoc from "../../../../../../assets/icon/pdf_doc.svg";
import scrip from "../../../../../../assets/icon/scripka.svg";
import { Contextvalue } from "../../../../../../context/context";
import Mohmenu from "./mohmenu";
import moment from "moment";
import { toast } from "react-toastify";

const Mohcreaterkin = () => {
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [noti, setNoti] = useState(false);
  const [notificationn, setNotificationn] = useState({
    state: "",
    text: "",
  });

  const [vil, setVil] = useState([]);
  const [date, setDate] = useState([]);
  const [text, setText] = useState([]);
  const handleClick = () => {
    setNoti(true);
  };

  const handlenoti = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNoti(false); 
  };
  const { men } = useContext(Contextvalue);
  console.log(men,"men");
  const token = window.localStorage.token;
  const formData = new FormData();
  formData.append("token", token);
  const muassasaId = localStorage.getItem("id");
  console.log("muassasaId", muassasaId);

  const [down, setDown] = useState([]);

  const [pass, setPass] = useState([]);
  function addFile(e) {
    setDown([
      ...down,
      {
        filename: e.target.value,
      },
    ]);
    setPass(e.target.files[0]);
  }
console.log(down);
  function delFile(index) {
    let sss = [];
    sss.push(...down);
    sss.splice(index, 1);
    setDown(sss);
  }

  const [ttb, setTtb] = useState([]);
  const [oop, setOop] = useState([]);

  const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  useEffect(() => {
    request
      .get("/user/respublika/viloyatlar/", config)
      .then((data) => setVil(data.data.muassasalar));
  }, []);
  useEffect(() => {
    request
      .get("/user/respublika/tumanlar/", config)
      .then((data) => setTtb(data.data.muassasalar));
  }, []);
  useEffect(() => {
    request
      .get("/user/respublika/muassasalar/", config)
      .then((data) => setOop(data.data.muassasalar));
  }, []);
  const dateHandler = (e) => {
      let dateLimit = moment(Date.now()+60*60*24000).format().split("T")[0];
    if (dateLimit > e.target.value) {
      setDate(dateLimit);
      return toast.error(
        `Topshirish muddati ${dateLimit} dan oldin bo'lishi mumkin emas!`
      );
    }
    setDate(e.target.value);
  };

  function Send(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("kimga", men && men.map((el) => el.id));
    formData.append("qoshimcha", text);
    formData.append("text", "");
    formData.append("muddati", date);
    formData.append("fayl", pass);
    request
      .post(`/bildirishnoma/erkin/MOHdanVSSBga/`, formData, config)
      .then(function (res) {
        setNotificationn({
          state: "success",
          text: `Bildirishnoma  yuborildi`,
        });
        setTimeout(() => {
          navigate("/mohbil");
        }, 1500);
        handleClick(true);
      })
      .catch(function (err) {
        setNotificationn({
          state: "error",
          text: `Bildirishnoma  yuborilmadi`,
        });
        handleClick(true);
      });
  }

  const { t } = useTranslation();
  return (
    <div className="sarflov">
      <div className="sarflov_inner">
        <Link to={"/mohbil"}>
          <Button startIcon={<ArrowBackIcon />} variant="contained">
            {t("bildirishnoma.single.ortga")}
          </Button>
        </Link>
      </div>

      <form onSubmit={Send}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ padding: 30, width: "48%" }} className="sarflov_block">
            <h4 className="sarflov_block_title">
              {t("bildirishnoma.new.kimdankimga")}
            </h4>
            <div
              style={{ display: "flex", flexWrap: "wrap" }}
              className="sarflov_block_inner"
            >
              <div>
                <h5 className="sarflov_block_inner_div_title">
                  {t("bildirishnoma.new.kimdan")}
                </h5>
                <TextField
                  style={{ minWidth: "400px", width: "20vmax" }}
                  id="outlined-basic"
                  variant="outlined"
                  label={t("Sog'liqni saqlash vazirligi")}
                  disabled
                />
                
              </div>
              <div>
                <h5 className="sarflov_block_inner_div_title">
                  {t("bildirishnoma.vlssv")}
                  ({t("bildirishnoma.send")})
                </h5>
                <Mohmenu names={vil} />
              </div>
            </div>
          </div>
          <div className="sarflov_block" style={{ padding: 30, width: "48%" }}>
            <h4
              className="sarflov_block_title"
              style={{ marginBottom: "56px" }}
            >
              {t("Boshqa ma'lumotlar")}
            </h4>
            <div className="grid grid-cols-2 gap-15">
              <div>
                  <p className="mb-[10px]">     {t("bildirishnoma.turi")}</p>
                    <TextField
                    style={{width:'100%'}}
                      id="outlined-basic"
                      variant="outlined"
                      label={t("vosita.erkin")}
                      disabled
                    />
              </div>
              <div>
                  <p className="mb-[10px]">{t("bildirishnoma.new.vazifasi")}</p>
                  <TextField
                  style={{width:"100%"}}
                    onChange={dateHandler}
                    id="outlined-basic"
                    variant="outlined"
                    required
                    value={date}
                    type="date"
                  />
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="sarflov_comment">
          <div className="sarflov_block_comment">

            <h4 className="sarflov_block_title">{t("Qo’shimcha ma’lumot")}</h4>
            <div className="sarflov_block_inner_div1">
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="..."
                name="qoshimcha_matn"
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
          <div className="sarflov_block_comment">
            <div className="sarflov_block_comment_inner">
              <h4 className="sarflov_block_title">
                {t("bildirishnoma.new.fail")}
              </h4>
              <input
                onChange={down.length < 1 && addFile}
                disabled={down.length > 0}
                type="file"
                id="files"
                className="input_download"
              />
              <label htmlFor="files" className="all_download">
                <img className="scrip_file" src={scrip} alt="" />
                {t("vosita.qosh")}
              </label>
            </div>
            <div></div>
            <div className="sarflov_comment">
              <div
                style={{ height: "200px", overflow: "scroll",width:"100%" }}
                className="sarflov_block_inner_div"
              >
                {down.map((item, index) =>
                  down.length > 0 ? (
                    <div key={index} className="sarflov_block_download_file">
                      <label className="input_tyle_download">
                        <img src={pdfDoc} alt="" className="label_img" />
                        {item.filename}
                        <div className="close_file">
                          <Button
                            onClick={(e) => delFile(index)}
                            startIcon={<CloseIcon />}
                          ></Button>
                        </div>
                      </label>
                    </div>
                  ) : null
                )}
              </div>
            </div>

            <Snackbar
              Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={noti}
              autoHideDuration={6000}
              onClose={handlenoti}
            >
              <Alert
                Alert
                onClose={handlenoti}
                severity={notificationn.state}
                sx={{
                  width: "100%",
                }}
              >
                {notificationn.text}
              </Alert>
            </Snackbar>
          </div>
        </div>
        <footer className="site-footer">
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                borderRadius: "12px",
                backgrounColor: "#1464C0",
                width: "448px",
              }}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SendIcon />}
            >
              {t("input.otp")}
            </Button>
          </div>
        </footer>
      </form>
    </div>
  );
};
export default Mohcreaterkin;
