import axios from "axios";
import React, { useEffect } from "react";
import {
  useNavigate,
  useParams
} from "react-router-dom";

console.log('entering callback')
const Logins = () => {
  const navigate = useNavigate()
  // const { code } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
const code = urlParams.get('code')
console.log('code',code,urlParams)

 const postCode = async () => {
   const token_endpoint = "https://test-sso.ssv.uz/oauth/token";
localStorage.setItem("verifier_DEBUG_CHECK",localStorage.getItem("verifier"))
console.log("verifier before sending:",localStorage.getItem("verifier_debug"))
console.log('sending verifier:',localStorage.getItem("verifier"))
   const params = new URLSearchParams({
     grant_type: "authorization_code",
     code,
     code_verifier: '6xKQp8FRej8K4bRrFOBe9YpfYoLXkMIpLWxYJTtOZj0',
     redirect_uri: "http://localhost:3004/auth/callback",
     client_id: "98516ea0-6d5b-44cb-b99f-3501bd70c8c1"
    //  client_secret: "EhC3NeVolmuyNLB7QG4ad5Bx9CaOphlL0Sc8BQ10",
   });

 // 985170c4-46fd-4e0e-8fca-31ad8bbb89db, address lardagi portlarni 3004 ga o'zgartirib npm start niyam 3004 da run qib ko'ring, o'xshamasa erta ko'ramiz, tboulshunamayaman nima muammoligini, boshqa odamlargayam berilgan edi public lcient ishlatish bilan ularda muammo bo'magan
   const token = await fetch(token_endpoint, {
     method: "POST",
     body: params,
     headers: {
       "Content-Type": "application/x-www-form-urlencoded"
      },
    });
// internet wifi mi?aha saytga nega kirolmayaptikan hozir backendchi bola korvotti nmaga bunaqa bovotganini, hech bo'maganda localda ishlashi kerak, tushunmadim 
// boshidan deploy qvotti
// localda ishlashi kereku, front ni o'zi back keremas bo'tta token olish uchun sso dan, public client bilan
    const json = await token.json();
    localStorage.setItem('access_token',JSON.stringify(json))
    console.log('token response:',json);
  
//aka deploy qivolish kere ozgargandan kegin 
 // bu serverdami codebase? + shuni so'radimde boya , qivorsinmi? hozir
 // hozir 1 daqiqa   boldi aka, localda ishlash uchunam bitta public client beraman, o'shani localda ishlatsa bo'ladi, redirect_url: http://localhost:3000/auth/callback bo'ladi
// aytgancha shart ham emas
// run qilib ko'ramiz
};

  useEffect(() => {
  
      postCode();
    
  }, [code]);


 
  return (
    <div
      // style={{
      //   position: "absolute",
      //   top: 0,
      //   bottom: 0,
      //   right: 0,
      //   left: 0,
      //   margin: "auto",
      // }}
    >test shotta</div>
  );
};

export default Logins;