import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import l1 from "assets/icon/l1.svg";
// import './uzbildirish.scss';
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { request } from "api/request";
import Loading from "components/loading/loading";
import Uzbilmodal from "../../uzmedinpex/uzbildirish/uzbilmodal/uzbilmodal";
import { get } from "lodash";
import usePost from "hooks/usePost";
import { CloseOutlined } from "@mui/icons-material";
const Mohbil = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const [searchByDate, setSearchByDate] = useState("");
  const [query, setQuery] = useState({
    value: 0,
    value1: 0,
  });
  const [data, setData] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [data2, setData2] = useState([]);
  const handleChange = (event, value) => {
    setQuery((prev) => ({ ...prev, value }));
    setSearchParams({ ...query, value });
  };
  const { t } = useTranslation();
  const token = window.localStorage.token;
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  useEffect(() => {
    if (searchParams.get("value")) {
      setQuery((prev) => ({ ...prev, value: +searchParams.get("value") }));
    }
    if (searchParams.get("value1")) {
      setQuery((prev) => ({ ...prev, value1: +searchParams.get("value1") }));
    }
    setSearchParams(query);
  }, []);

  const { value, value1 } = query;
  useEffect(() => {
    if (value === 0) {
      if (value1 === 1) {
        request.get("/bildirishnoma/moh/erkin/", config).then((data) =>
          setData({
            loading: true,
            error: false,
            data: searchByDate?  data.data.data.filter((item) =>
            get(item, "sana", "").includes(searchByDate)
          ) :data.data.data,
          })
        );
      }
      if (value1 === 0) {
        request.get("/bildirishnoma/erkin/MOHga/", config).then((data) =>
        setData({
          loading: true,
          error: false,
          data: searchByDate?  data.data.data.filter((item) =>
          get(item, "sana", "").includes(searchByDate)
        ) :data.data.data,
        })
        );
      }
    }
    if (value === 2) {
      if (value1 === 0) {
        request
          .get("/bildirishnoma/respublikaga/", config)
          .then((data) => searchByDate? setData2(data.data.data.filter((item) =>
          get(item, "sana", "").includes(searchByDate))
        ) :setData2(data.data.data));
      }
      if (value1 === 1) {
        request
          .get("/bildirishnoma/respublika/", config)
          .then((data) => searchByDate? setData2(data.data.data.filter((item) =>
          get(item, "sana", "").includes(searchByDate))
        ) :setData2(data.data.data));
      }
    }
  }, [value, value1,searchByDate]);

  let statusCount2 = 0;
  data?.data?.forEach((item) => {
    if (item.status !== "O'qildi") {
      statusCount2++;
    }
  });
  let statusCount3 = 0;
  data2?.data?.forEach((item) => {
    if (item.status !== "Yuborildi") {
      statusCount3++;
    }
  });
  
  const { mutate } = usePost();
  const handleView = (id) => {
    const formdata = new FormData();
    formdata.append("id", id);
    formdata.append("status", "O'qildi");
    mutate({
      url: "/bildirishnoma/erkin/MOHga/",
      data: formdata,
      method: "put",
    });
  };

  const statusChange = (id) => {
    if (value1 === 0){
      const formdata = new FormData();
      formdata.append("id", id);
      formdata.append("status", "O'qildi");
      mutate({
        url: "/bildirishnoma/respublikaga/",
        data: formdata,
        method: "post",
      });
    };
    }
    console.log(data);
  if (!data.data) return <Loading />;
  return (
    <div style={{ marginTop: "28px", padding: "20px" }}>
      <div style={{ marginBottom: "24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>
            {t("input.bso")} :{" "}
            {value === 0 ? data.data && data.data.length : data2.length}
          </h2>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <TextField
              className="search-ariza"
              placeholder={t("input.qidir")}
              style={{ marginLeft: "40px" }}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              id="standard-basic"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    style={{ position: "absolute", right: "18px" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />{" "}
            {!!searchValue && (
              <IconButton onClick={() => setSearchValue("")}>
                <CloseOutlined />
              </IconButton>
            )}
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <TextField
              type="date"
              style={{ marginLeft: "40px" }}
              placeholder="Sana bo'yicha qidirish"
              variant="outlined"
              className="search-ariza"
              onChange={(e) => setSearchByDate(e.target.value)}
              value={searchByDate}
            />
            {!!searchByDate && (
              <IconButton onClick={() => setSearchByDate("")}>
                <CloseOutlined />
              </IconButton>
            )}
          </Box>

          <div className="green_block">
            <Uzbilmodal />
          </div>
        </div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            style={{ backgroundColor: "#fff", borderRadius: "12px" }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("vosita.erkin")} value={0} />
            {!!statusCount2 && (
              <span
                style={{
                  fontWeight: "bold",
                  background: "blue",
                  borderRadius: "50%",
                  width: "22px",
                  height: "22px",
                  color: "white",
                  textAlign: "center",
                  paddingTop: "4px",
                  fontSize: "14px",
                }}
              >
                {statusCount2}
              </span>
            )}

            <Tab label={t("vosita.bola")} value={2} />
            {!!statusCount3 && (
              <span
                style={{
                  fontWeight: "bold",
                  background: "blue",
                  borderRadius: "50%",
                  width: "22px",
                  height: "22px",
                  color: "white",
                  textAlign: "center",
                  paddingTop: "4px",
                  fontSize: "14px",
                }}
              >
                {statusCount3}
              </span>
            )}
          </Tabs>
        </Box>
        {value === 0 && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              style={{ backgroundColor: "#fff", borderRadius: "12px" }}
              value={value1}
              onChange={(e, value) => {
                setQuery((prev) => ({ ...prev, value1: value }));
                setSearchParams({ ...query, value1: value });
              }}
              aria-label="basic tabs example"
            >
              <Tab label={t("qabxar")} />
              <Tab label={t("yubxar")} />
            </Tabs>
          </Box>
        )}
        {value === 2 && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              style={{ backgroundColor: "#fff", borderRadius: "12px" }}
              value={value1}
              onChange={(e, value) => {
                setQuery((prev) => ({ ...prev, value1: value }));
                setSearchParams({ ...query, value1: value });
              }}
              aria-label="basic tabs example"
            >
              <Tab label={t("qabxar")} />
              <Tab label={t("yubxar")} />
            </Tabs>
          </Box>
        )}
        <TableContainer className="table-not" component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow style={{ backgroundColor: "white" }}>
                <TableCell>{t("bildirishnoma.single.soni")}</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>{value1 === 0 ? t("bildirishnoma.single.kimdan") : t("bildirishnoma.send")}</TableCell>
                <TableCell>{t("bildirishnoma.new.mud")}</TableCell>
                <TableCell>{t("bildirishnoma.sana")}</TableCell>
                <TableCell>{t("bildirishnoma.status")}</TableCell>
                <TableCell>{t("bildirishnoma.harakat")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {value === 0 &&
                data.data &&
                data.data
                  .filter((item) =>
                    item.kimdan
                      .toLowerCase()
                      .includes(searchValue.toLocaleLowerCase())
                  )
                 
                  .map((el, index) => {
                    return (
                      <TableRow key={el.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{el.id}</TableCell>
                        <TableCell>{value1 === 0 ? el.kimdan : el.kimga}</TableCell>
                        <TableCell>{el?.muddati}</TableCell>
                        <TableCell>{el.sana}</TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          <button
                            className={
                              el.status !== "O'qildi"
                                ? "status_btn"
                                : "status_btn--not"
                            }
                          >
                            {el.status !== "O'qildi"
                              ? value1 === 1
                                ? t("vosita.yubor")
                                : t("bildirishnoma.arstatus.yangi")
                              : t("vosita.oqil")}
                          </button>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/mohbilerkin/${el.id}/${el.qabul_qiluvchi}?value=${value}&value1=${value1}`}
                            onClick={() => handleView(el.id)}
                          >
                            <img src={l1} />
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {value === 2 &&
                data2
                  .filter((item) =>
                    get(item, "kimdan", "")
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  )
                  .map((el, index) => {
                    return (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{el.id}</TableCell>
                        <TableCell>{value1 === 0 ? el.kimdan : el.kimga}</TableCell>
                        <TableCell>{el.muddati}</TableCell>
                        <TableCell>{el.sana}</TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          <button
                            className={
                              el.status === "Yuborildi"
                                ? "status_btn"
                                : "status_btn--not"
                            }
                          >
                            {el.status !== "O'qildi"
                              ? value1 === 1
                                ? t("vosita.yubor")
                                : t("bildirishnoma.arstatus.yangi")
                              : t("vosita.oqil")}
                          </button>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/mohbilbol/${el.id}?value=${value}&value1=${value1}`}
                            onClick={() => statusChange(el.id)}
                          >
                            <img src={l1} alt="" />
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
export default Mohbil;
